<!-- Page Content Holder -->
<div id="content" class="StoltBGDefault" #cargoClass>
    <div class="StoltCommonContentArea">
        <div class="booking-heading">
            <div class="booking-heading-left"> 
                <snl-page-title>
                    <div title-content>
                        <span class="header-h1">ETB Updates</span>
                    </div>
                </snl-page-title>
                <div class="LegendsTooltp">  
                    <snl-tooltip [position]="'bottom'">
                        <span class="section-title">Legends</span>
                        <div tooltipContent class="snl-tooltip-custmzd LegendsInTp"> 
                          <div class="body-b"> 
                               <ul>
                                <li><span class="StraightLine EtaClr"></span>ETA</li>
                                <li><span class="StraightLine EtdClr"></span>ETD</li>
                                <li><span class="StraightLine todaClr"></span>Today's date & time</li>
                               </ul>
                          </div>
                        </div>
                      </snl-tooltip>
                </div>
            </div>
        </div>

        <!-- Page Content Card -->
        <div class="CommonCardContent SettingPageHolder">
            <div class="GlobalSearchWrap">

                <!--Filter-->
                <div class="inProgress-filter M_CommonHidden" *ngIf="!isFilterSkeleton">  
                    <div class="form-row">
                        <div class="form-group">
                            <label class="FiltersTitle">
                                <span class="textFiltr fw-700">Filters</span>
                                <span class="filter-reset fw-700" [ngClass]="{'anchor-disable' : ResetFildisable}">
                                    <a href="javascript:void(0);"
                                    (click)="ResetFilter()">
                                        Reset all
                                    </a>
                                </span>
                            </label>
                        </div>
                    </div>
                    <div class="FilterDropDownArea">
                        <!-- VESSEL FILTER -->
                        <div class="form-row">
                            <div class="form-group">
                                <app-etb-display-filters [filterDataInput]="vesselFilter"
                                    [savedfilterData]="savedVesselFilterData" [bindLabel]="'vslName'"
                                    [bindValue]="'vslCode'" [filterName]="'vslName'"
                                    [filterField]="'vessel'" [display]="'block'"
                                    [resetAllCalled]='resetAllCalled' [placeholder]="'Search vessel names'"
                                    [dynamicTextCss]="'_Vessel'"
                                    (submitFilter)=submitSelectFilter($event)></app-etb-display-filters>
                            </div>
                        </div>

                         <!-- VOYAGE NO FILTER -->
                         <div class="form-row">
                            <div class="form-group">
                                <app-etb-display-filters [filterDataInput]="voyageFilter"
                                    [savedfilterData]="savedVoyageFilterData" [bindLabel]="'voyageNumber'"
                                    [bindValue]="'_id'" [filterName]="'voyageNumber'"
                                    [filterField]="'voyage'" [display]="'block'"
                                    [resetAllCalled]='resetAllCalled' [placeholder]="'Search voyage number'"
                                    [dynamicTextCss]="'_Voyageno'"
                                    (submitFilter)=submitSelectFilter($event)></app-etb-display-filters>
                            </div>
                        </div>

                        <!-- PORT FILTER -->
                        <div class="form-row">
                            <div class="form-group">
                                <app-etb-display-filters [filterDataInput]="portFilter"
                                    [savedfilterData]="savedLoadPortsFilterData" [bindLabel]="'portName'"
                                    [bindValue]="'portNumber'" [filterName]="'portName'" [filterField]="'port'"
                                    [display]="'block'" [resetAllCalled]='resetAllCalled'
                                    [placeholder]="'Search port names'" [dynamicTextCss]="'_Port_Etb'"
                                    (submitFilter)=submitSelectFilter($event)></app-etb-display-filters>
                            </div>
                        </div>
                    </div>
                </div>
                <!--End filter-->

                <!-- Filter Skelton -->
                <div class="Stolt-Skeleton-Holder M_CommonHidden" *ngIf="isFilterSkeleton">
                    <div class="inProgress-sec">
                        <div class="inProgress-filter">
                            <div class="form-row">
                                <div class="form-group">
                                    <label class="FiltersTitle">
                                        <div class="Stolt-Skeleton"></div>
                                    </label>
                                </div>
                            </div>
                            <div class="FilterDropDownArea">
                                <div class="form-row  m-0">
                                    <div class="form-group">
                                        <div class="Stolt-Skeleton"></div>
                                    </div>
                                </div>

                                <div class="form-row m-0">
                                    <div class="form-group">
                                        <div class="Stolt-Skeleton"></div>
                                    </div>
                                </div>
                                <div class="form-row m-0">
                                    <div class="form-group">
                                        <div class="Stolt-Skeleton"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Filter Skelton End -->
               
                <div *ngIf="!isShow" class="Stolt-Skeleton Skeleton-Calendar"></div>
                <full-calendar [ngClass]="{'hide': !isShow}" *ngIf='calendarVisible' [options]='calendarOptions'>
                    <ng-template #eventContent let-arg>
                      <i>{{ arg.event.title }}
                        <span *ngIf="eventData && eventData == arg.event.title">

                            <snl-tooltip [position]="'top'" class="TttoltipCleInner">
                                <span class="section-title HideHeadingMn">Legends</span>
                                <div tooltipContent class="snl-tooltip-custmzd"> 
                                  <div class="body-b"> 
                                        <div class="HeaderCdata">
                                            <span class="caption">Stolt Integrity / 199</span>
                                            <span class="section-title">{{eventData}}</span>
                                        </div>

                                        <div class="tooltipInbdy">
                                              <div class="BodyInnerCl">
                                                    <div class="optionsInn">
                                                        <span class="InnerOptTitle">
                                                            <em>ETA/ATA</em>
                                                            <em>:</em>
                                                        </span>
                                                        <span class="section-title">11 Jul 2024, 0930</span>
                                                    </div>
                                                    <div class="optionsInn">
                                                        <span class="InnerOptTitle">
                                                            <em>ETA/ATA</em>
                                                            <em>:</em>
                                                        </span>
                                                        <span class="section-title">12 Jul 2024, 1400</span>
                                                    </div>
                                              </div>

                                              <div class="SeparatorMidLine"></div>

                                              <div class="BodyInnerCl">
                                                <div class="optionsInn">
                                                    <span class="InnerOptTitle">
                                                        <em>Charterer</em>
                                                        <em>:</em>
                                                    </span>
                                                    <span class="section-title">Dow International</span>
                                                </div>
                                                <div class="optionsInn">
                                                    <span class="InnerOptTitle">
                                                        <em>OBL</em>
                                                        <em>:</em>
                                                    </span>
                                                    <span class="section-title">223</span>
                                                </div>
                                                <div class="optionsInn">
                                                    <span class="InnerOptTitle">
                                                        <em>Cargo</em>
                                                        <em>:</em>
                                                    </span>
                                                    <span class="section-title">Benzene</span>
                                                    <span class="badge_LD">L</span>

                                                </div>
                                          </div>
                                        </div>
                                  </div>
                                </div>
                              </snl-tooltip>
                        </span>
                      </i>
                    </ng-template>
                  </full-calendar>
            </div>
        </div>
    </div>
</div>