import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { GlobalSearchService } from 'src/app/services/global-search.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { StorageService } from 'src/app/services/storage.service';
import { BookingService } from "../../../services/booking.service";
import { DatePipe } from '@angular/common';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import { MAT_DATE_FORMATS } from '@angular/material/core';
export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD-MM-YYYY',
  },
  display: {
    dateInput: 'DD MMM YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};
@Component({
  selector: 'app-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.scss'],
  providers: [DatePipe,{ provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }]
})
export class GlobalSearchComponent implements OnInit {
  navType = environment.navType;
  arrSearchTags: any = [{ "tagName": "Booking ID", "icon": "hash-icon.svg" }, { "tagName": "Cargo Type", "icon": "cargo-icon.svg" }, { "tagName": "Vessel Name", "icon": "anchor-icon.svg" }, { "tagName": "Load Port", "icon": "hash-icon.svg" }, { "tagName": "Discharge Port", "icon": "hash-icon.svg" }];
  searchTag: string = "";
  userHistory: any = [];
  userHistoryRev: any = [];
  todayDate = new Date();
  minDate = new Date();
  maxDate = new Date();
  searchListing: any = [];
  datePickerForm: UntypedFormGroup
  datePickerClass: boolean = true
  tagError: boolean = false
  searchString:any = null;
  reqObj: {};
  fromdate: string | null;
  todate: string | null;
  selectedTag: any;
  prevReq:any = {}
  isHistory:any=false;
  showNoResultMsg:boolean = false;
  isLoading:boolean = false;
  isDataStored:boolean = false;
  @ViewChild('cargoClass') cargoClass: ElementRef;
  @ViewChild('cargoModelClass') cargoModelClass: ElementRef;
  @ViewChild('documentClass') documentClass: ElementRef;
  @ViewChild('documentModelClass') documentModelClass: ElementRef;
  mobileCargoes: any;
  isSetMobileCargoesData=false
  isSetMobileDocumentData=false;
  mobileDocument: any;

  overFlowClass: boolean;
  docType: any;
  isDocumentationAccess: boolean = false;
  isSustainabilityAccess: boolean = false;
  userEmailArr:any = environment.USERSEMAIL
  userEmail: any;
  constructor(
    private navigationService: NavigationService,
    private _storageService: StorageService,
    public bookingService: BookingService,
    private _GlobalSearchService: GlobalSearchService, public datePipe: DatePipe, private fb: UntypedFormBuilder,private renderer: Renderer2) {
    this.navigationService.setShowNav(true);
    this.navigationService.setMobileNav(false);
    this.navigationService.setPageTitle('Global Search');
  }

  ngOnInit(): void {
    this.getUserInfo();
    this.dateFormBuilder()
    this.getAutoCompleteData();
    this._storageService.removeDataFromStorage("globalSearch");
    this.getUserSearchHistory();
    this.refreshListingAfter30Min();

  }

  getUserInfo(){
    this.navigationService.getUserInfo().subscribe((res: any) => {
      if(res.data){
        this.userEmail = res.data.email;
        this.isSustainabilityAccess = res.data.module.includes("Sustainability");
        this.isDocumentationAccess = res.data.module.includes("Documentation");    
      }
    })
  }
        
   tagArr:any[] = []
  selectTagType(tag: any) {
    this.tagArr.push(tag)
    this.searchTag = tag;
    this.tagError=false;
    this.searchString = null;
    this.showNoResultMsg = false;
    if(this.tagArr.length > 1){
      this.datePickerForm.reset()
      this.datePickerClass = true;
      this.searchListing=[];
      this.reqObj ={}
      this.showNoResultMsg = false
    }
    
  }

  dateFormBuilder() {
    this.datePickerForm = this.fb.group({
      fromDate: [],
      toDate: [],
    });
    const currentYear = new Date().getFullYear();
    this.minDate = new Date(currentYear - 23, 0, 1);
    this.maxDate = new Date(currentYear, this.todayDate.getMonth() + 6, 31);
  }

  getUserSearchFields(e: any) {
    this.prevReq = e;
    this._storageService.putDataInStorage("globalSearch", true);
    let getFromdate = this.datePickerForm.value.fromDate;
    let getTodate =  this.datePickerForm.value.toDate;
    this.fromdate = this.datePipe.transform(getFromdate, 'YYYY-MM-dd')
    this.todate = this.datePipe.transform(getTodate, 'YYYY-MM-dd')
    let request = {
      searchCode: e.searchCode.toString(),
      searchTag: e.searchTag.toString(),
      searchKeyword: e.searchKeyword.toString(),
      ...((this.fromdate && this.todate) && { dateRange: { "fromDate": this.fromdate, "toDate": this.todate } })
    }
    this.reqObj = request
    this.isLoading = true;
    this.showNoResultMsg = false;
    this._GlobalSearchService.getBookingGlobalSearchListing(request).subscribe((searchListing: any) => {
      this.searchListing = searchListing.data;
      this.refactorGetBookingGlobalSearchListing();
    })
    this.userHistory.unshift(request);
    let histoeyReq: any = { history: this.userHistory }
    if (this.userHistory.length > 5) {
      this.userHistory.pop();
      histoeyReq = { history: this.userHistory }
    }
    this.navigationService.saveUserSearchHistory(histoeyReq).subscribe((res: any) => {
      this.getUserSearchHistory();
    })
  }

  refactorGetBookingGlobalSearchListing(){
    if(this.searchListing.length){
      this.searchListing.forEach((searchList:any) => {        
        if(searchList.arVessel.length){
        searchList.arVessel.forEach((arVessel:any) => {
        if(arVessel.isTransShipmentVessel){
          if(arVessel.vesselCode=='TBN1'){
            arVessel.vesselName='TBD'
          }
          searchList["isTSbooking"]=true          
        }
      });}
    });
    }
    this.isLoading = false;
    if(this.searchListing.length == 0){
      this.showNoResultMsg = true;
    } 
  }

  getUserSearchHistory() {
    this.navigationService.getUserSearchHistory().subscribe((res: any) => {
      if (res.data) {
        this.userHistory = res.data.history
        this.userHistoryRev = this.userHistory;
      }
    })
  }

  clearSearchTag() {
    this.searchTag = '';
    this.searchListing=[];
    this.datePickerForm.reset()
    this.datePickerClass = true;
  }

  goToDetails(history: any) {
    this._storageService.putDataInStorage("globalSearch", true);
    this.searchString = history;
    this.searchTag = history.searchTag;
    this.selectedTag = this.searchString;
    this.isHistory = true;
    let fromdate = this.datePipe.transform(history.dateRange.fromDate, 'YYYY-MM-dd')
    let todate = this.datePipe.transform(history.dateRange.toDate, 'YYYY-MM-dd')
    this.datePickerClass = (fromdate && todate) ? false : true
    this.datePickerForm.patchValue({ fromDate: fromdate, toDate: todate })
    this.getUserSearchFields(history);
  }

  clearAllHistory() {
    let histoeyReq: any = { history: [] }
    this.navigationService.saveUserSearchHistory(histoeyReq).subscribe((res: any) => {
      this.getUserSearchHistory();
    })
  }

  getAutoCompleteData() {
    this._storageService.removeDataFromStorage("searchOptions");
    if (!this._storageService.getDataFromStorage('searchOptions')) {
      this.bookingService.getAutoCompleteData([]).subscribe((res: any) => {
        let searchOptions = res.data.searchOptions;
        this.isDataStored = true;
        this._storageService.putDataInStorage("searchOptions", JSON.stringify(searchOptions));
        this._GlobalSearchService.getGlobalSearchService(searchOptions);
        let nextApiCall = new Date(new Date().getTime() + (60 * 30 * 1000));
        this._storageService.putDataInStorage("autoCompleteReceivedDate", JSON.stringify(nextApiCall));
      })
    }
  }


  refreshListingAfter30Min() {
    setInterval(() => {
      let res = this.areDataOutdated()
      if (!res) {
        this._storageService.removeDataFromStorage("searchOptions");
        this.getAutoCompleteData();
      }
    }, 60000);
  }

  areDataOutdated = () => {
    const checkDate = this._storageService.getDataFromStorage('autoCompleteReceivedDate');
    if (checkDate) {
      let recivedDate = new Date(JSON.parse(checkDate));
      let currentDate = new Date();
      return recivedDate > currentDate;
    } else {
      return false;
    }
  };

  ngOnDestroy() {
    this._storageService.removeDataFromStorage("searchOptions");
    this._storageService.removeDataFromStorage("bookingListing");
    this._storageService.removeDataFromStorage("autoCompleteReceivedDate");
  }

  ChangeDate(event: any) {
    this.datePickerClass = false
  }

  getSearchValue(event: any) {
    this.tagError = this.searchTag ? false : true;
    if (!this.searchTag) {
      this.tagError = event.length > 1 ? true : false
    }
  }

  ClosePicker(){
    let getFromdate = this.datePickerForm.value.fromDate;
    let getTodate = this.datePickerForm.value.toDate;
    this.tagError = (!this.searchTag && getFromdate && getTodate) ? true : false;

    if(this.reqObj && getFromdate && getTodate){
      this.getUserSearchFields(this.reqObj)
    }
  }


  callBackTag(event:any){
    this.selectedTag = event
  }

  ResetDateFilter(){
    this.datePickerForm.reset()
    this.datePickerClass = true;
    this.getUserSearchFields(this.prevReq);

  }


  emitCargoData(eventmbl:any)
  {
    console.log("cargo::")
    let event = eventmbl.event;
    let dataMbl = eventmbl.data;
    event.stopPropagation();
    this.mobileCargoes = dataMbl ? dataMbl : []
    this.renderer.addClass(this.cargoClass.nativeElement, 'FilterOverlay');
    this.renderer.addClass(this.cargoModelClass.nativeElement, 'ModelShow');
  }


  mobileCargoesRemoveCall(isTimezoneFilter: boolean) {
    if (isTimezoneFilter) {
      this.isSetMobileCargoesData = false
    } else {
      this.overFlowClass = false
      this.renderer.removeClass(this.cargoClass.nativeElement, 'FilterOverlay');
      this.renderer.removeClass(this.cargoModelClass.nativeElement, 'ModelShow');
    }
  }

  emitDocumentoData(eventDoc:any){
    let event = eventDoc.event;
    let docDataMbl = eventDoc.data;
    this.docType= eventDoc.docType;
    event.stopPropagation();
    this.mobileDocument = docDataMbl ? docDataMbl : []
    this.renderer.addClass(this.cargoClass.nativeElement, 'FilterOverlay');
    this.renderer.addClass(this.documentModelClass.nativeElement, 'ModelShow');
  }

  mobileDocumentRemoveCall(isDocFilter: boolean) {
    if (isDocFilter) {
      this.isSetMobileDocumentData = false
    } else {

      this.renderer.removeClass(this.cargoClass.nativeElement, 'FilterOverlay');
      this.renderer.removeClass(this.documentModelClass.nativeElement, 'ModelShow');
    }
  }

  removeFilter(event: Event) {
    this.isSetMobileCargoesData = false;
    this.isSetMobileDocumentData = false
    this.mobileCargoesRemoveCall(false)
    this.mobileDocumentRemoveCall(false)
    event.stopPropagation();
  }

  emitOverflowClass(event:any)
  {
    this.overFlowClass = event ? true : false
  }
}
