import { Component, OnInit, HostListener } from '@angular/core';
import { FormArray, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { EtbInternalService } from 'src/app/services/etb-internal.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { OtpService } from 'src/app/services/otp.service';

@Component({
  selector: 'app-port-agent-etb-detail-update',
  templateUrl: './port-agent-etb-detail-update.component.html',
  styleUrls: ['./port-agent-etb-detail-update.component.scss'],
})
export class PortAgentEtbDetailUpdateComponent implements OnInit {
  settings: any = {
    isDisable: false,
    showHover: true,
    showCursorNotAllowed: false,
    isTitleClickable: true,
  };
  openModel: boolean = false;
  snlModalSettings = { showCloseIcon: true, backdropClick: false };
  isSessionExpired = false;
  isSessionInactive = false;
  isSessionInactivityModalCloseButtonClicked = false;
  etbList = [
    {
      _id: '66b466483c8ca9005ce2dbaf',
      arBerths: [
        {
          berthSeqNo: 1,
          berthName: 'TUAS #A',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: null,
          actualDateDeparture: null,
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: null,
          actualDateDepartureUTC: null,
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'Pending updates',
          isTSBerth: false,
          _id: '66fe7debf321a60049eb0998',
          arCargoes: [
            {
              cargoId: 33604,
              cargo: 'TALLOW',
              charterer: 'NESTE SHIPPING OY',
              companyFleet: '100002075__SNAPS',
              broker_fleet: [],
              oblNum: '400',
              func: 'D',
              _id: '66fe7debf321a60049eb0999',
            },
            {
              cargoId: 33605,
              cargo: 'TALLOW',
              charterer: 'NESTE SHIPPING OY',
              companyFleet: '100002075__SNAPS',
              broker_fleet: [],
              oblNum: '410',
              func: 'D',
              _id: '66fe7debf321a60049eb099a',
            },
            {
              cargoId: 33608,
              cargo: 'TALLOW',
              charterer: 'NESTE SHIPPING OY',
              companyFleet: '100002075__SNAPS',
              broker_fleet: [],
              oblNum: '430',
              func: 'D',
              _id: '66fe7debf321a60049eb099b',
            },
          ],
          sortDate: '+275760-09-13T00:00:00.000Z',
        },
      ],
      dtETA: '2024-09-25T14:30:00.000Z',
      status: 'Pending updates',
      vesVoy: 'SSAKU__124__SNAPS',
      vslCode: 'SSAKU',
      vslName: 'STOLT SAKURA',
      voyNo: 124,
      seqNo: 3500,
      portNo: 3182,
      portName: 'SINGAPORE',
      dtETD: '2024-10-11T23:00:00.000Z',
      country: 'SINGAPORE',
      hasPendingStatus: true,
    },
    {
      _id: '66b466443c8ca9005ce2d131',
      arBerths: [
        {
          berthSeqNo: 19,
          berthName: 'Dolphin 83',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: '2024-09-30T14:30:00.000Z',
          actualDateDeparture: '2024-10-01T07:17:00.000Z',
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: '2024-09-30T12:30:00.000Z',
          actualDateDepartureUTC: '2024-10-01T05:17:00.000Z',
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'No action required',
          isTSBerth: false,
          _id: '670708a2ef16b600498de161',
          arCargoes: [],
          sortDate: '2024-09-30T14:30:00.000Z',
        },
        {
          berthSeqNo: 4,
          berthName: 'VPK V626',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: '2024-10-01T10:28:00.000Z',
          actualDateDeparture: '2024-10-03T11:05:00.000Z',
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: '2024-10-01T08:28:00.000Z',
          actualDateDepartureUTC: '2024-10-03T09:05:00.000Z',
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'No action required',
          isTSBerth: false,
          _id: '670708a2ef16b600498de14f',
          arCargoes: [
            {
              cargoId: 192488,
              cargo: 'LAB',
              charterer: 'ISU CHEM CO., LTD.',
              companyFleet: '100000185__STJS',
              broker_fleet: ['TAWOO SHIPPING__STJS'],
              oblNum: '300',
              func: 'D',
              _id: '670708a2ef16b600498de150',
            },
            {
              cargoId: 192573,
              cargo: 'RSPO SG PMF IV 43-44',
              charterer: 'FUJI OIL EUROPE',
              companyFleet: '100000537__STJS',
              broker_fleet: ['J D SHIPPING LTD__STJS'],
              oblNum: '340',
              func: 'D',
              _id: '670708a2ef16b600498de151',
            },
            {
              cargoId: 192574,
              cargo: 'PMF 335/SG',
              charterer: 'FUJI OIL EUROPE',
              companyFleet: '100000537__STJS',
              broker_fleet: ['J D SHIPPING LTD__STJS'],
              oblNum: '345',
              func: 'D',
              _id: '670708a2ef16b600498de152',
            },
            {
              cargoId: 192988,
              cargo: 'NORMAL PARAFFIN C14-17',
              charterer: 'ISU CHEM CO., LTD.',
              companyFleet: '100000185__STJS',
              broker_fleet: ['TAWOO SHIPPING__STJS'],
              oblNum: '305',
              func: 'D',
              _id: '670708a2ef16b600498de153',
            },
            {
              cargoId: 193159,
              cargo: 'PG FACID MESTER 810EU',
              charterer: 'P&G INTERNATIONAL OPERATIONS SA',
              companyFleet: '100009152__STJS',
              broker_fleet: ['CLARKSON PLATOU ASIA PTE LTD__STJS'],
              oblNum: '350',
              func: 'D',
              _id: '670708a2ef16b600498de154',
            },
            {
              cargoId: 193160,
              cargo: 'P&G CE-1214',
              charterer: 'P&G INTERNATIONAL OPERATIONS SA',
              companyFleet: '100009152__STJS',
              broker_fleet: ['CLARKSON PLATOU ASIA PTE LTD__STJS'],
              oblNum: '355',
              func: 'D',
              _id: '670708a2ef16b600498de155',
            },
            {
              cargoId: 193161,
              cargo: 'P&G CE-1875',
              charterer: 'P&G INTERNATIONAL OPERATIONS SA',
              companyFleet: '100009152__STJS',
              broker_fleet: ['CLARKSON PLATOU ASIA PTE LTD__STJS'],
              oblNum: '360',
              func: 'D',
              _id: '670708a2ef16b600498de156',
            },
            {
              cargoId: 193162,
              cargo: 'P&G CE-1618BL',
              charterer: 'P&G INTERNATIONAL OPERATIONS SA',
              companyFleet: '100009152__STJS',
              broker_fleet: ['CLARKSON PLATOU ASIA PTE LTD__STJS'],
              oblNum: '365',
              func: 'D',
              _id: '670708a2ef16b600498de157',
            },
            {
              cargoId: 193163,
              cargo: 'P&G CO-1899',
              charterer: 'P&G INTERNATIONAL OPERATIONS SA',
              companyFleet: '100009152__STJS',
              broker_fleet: ['CLARKSON PLATOU ASIA PTE LTD__STJS'],
              oblNum: '370',
              func: 'D',
              _id: '670708a2ef16b600498de158',
            },
          ],
          sortDate: '2024-10-01T10:28:00.000Z',
        },
        {
          berthSeqNo: 20,
          berthName: 'Dolphin 83',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: '2024-10-03T14:15:00.000Z',
          actualDateDeparture: '2024-10-04T21:12:00.000Z',
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: '2024-10-03T12:15:00.000Z',
          actualDateDepartureUTC: '2024-10-04T19:12:00.000Z',
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'No action required',
          isTSBerth: false,
          _id: '670708a2ef16b600498de162',
          arCargoes: [],
          sortDate: '2024-10-03T14:15:00.000Z',
        },
        {
          berthSeqNo: 16,
          berthName: 'LBC NORTH',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: '2024-10-05T00:15:00.000Z',
          actualDateDeparture: '2024-10-06T05:00:00.000Z',
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: '2024-10-04T22:15:00.000Z',
          actualDateDepartureUTC: '2024-10-06T03:00:00.000Z',
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'No action required',
          isTSBerth: false,
          _id: '670708a2ef16b600498de15f',
          arCargoes: [
            {
              cargoId: 193501,
              cargo: 'ANILINE',
              charterer: 'INTEGRA PETROCHEMICALS',
              companyFleet: '100009735__STJS',
              broker_fleet: ['CLARKSON PLATOU ASIA PTE LTD__STJS'],
              oblNum: '335',
              func: 'D',
              _id: '670708a2ef16b600498de160',
            },
          ],
          sortDate: '2024-10-05T00:15:00.000Z',
        },
        {
          berthSeqNo: 15,
          berthName: 'Koole Pernis 1',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: '2024-10-06T06:05:00.000Z',
          actualDateDeparture: '2024-10-07T05:10:00.000Z',
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: '2024-10-06T04:05:00.000Z',
          actualDateDepartureUTC: '2024-10-07T03:10:00.000Z',
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'No action required',
          isTSBerth: false,
          _id: '670708a2ef16b600498de15c',
          arCargoes: [
            {
              cargoId: 193993,
              cargo: 'ECOROL 24',
              charterer: 'ECOGREEN OLEO. PTE LTD',
              companyFleet: '100000540__STJS',
              broker_fleet: [],
              oblNum: '385',
              func: 'D',
              _id: '670708a2ef16b600498de15d',
            },
            {
              cargoId: 193994,
              cargo: 'ECOROL 26',
              charterer: 'ECOGREEN OLEO. PTE LTD',
              companyFleet: '100000540__STJS',
              broker_fleet: [],
              oblNum: '390',
              func: 'D',
              _id: '670708a2ef16b600498de15e',
            },
          ],
          sortDate: '2024-10-06T06:05:00.000Z',
        },
        {
          berthSeqNo: 22,
          berthName: 'SMIS',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: '2024-10-07T06:25:00.000Z',
          actualDateDeparture: '2024-10-07T17:09:00.000Z',
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: '2024-10-07T04:25:00.000Z',
          actualDateDepartureUTC: '2024-10-07T15:09:00.000Z',
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'No action required',
          isTSBerth: false,
          _id: '670708a2ef16b600498de166',
          arCargoes: [],
          sortDate: '2024-10-07T06:25:00.000Z',
        },
        {
          berthSeqNo: 21,
          berthName: 'LIQUIN CHEM 1',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: '2024-10-07T17:55:00.000Z',
          actualDateDeparture: '2024-10-08T18:36:00.000Z',
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: '2024-10-07T15:55:00.000Z',
          actualDateDepartureUTC: '2024-10-08T16:36:00.000Z',
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'No action required',
          isTSBerth: false,
          _id: '670708a2ef16b600498de163',
          arCargoes: [
            {
              cargoId: 193039,
              cargo: 'BUTYL ACRYLATE',
              charterer: 'TAIXING SUNKE CHEMICALS CO LTD',
              companyFleet: '100009587__STJS',
              broker_fleet: ['QUINCANNON ASIA LIMITED__STJS'],
              oblNum: '310',
              func: 'D',
              _id: '670708a2ef16b600498de164',
            },
            {
              cargoId: 193700,
              cargo: 'HEPTANE STANDARD',
              charterer: 'SK GEO CENTRIC CO LTD',
              companyFleet: '100009635__STJS',
              broker_fleet: ['SEACHEM SHIPPING CO LTD__STJS'],
              oblNum: '380',
              func: 'D',
              _id: '670708a2ef16b600498de165',
            },
          ],
          sortDate: '2024-10-07T17:55:00.000Z',
        },
        {
          berthSeqNo: 9,
          berthName: 'LIQUIN TTR 1',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: '2024-10-08T19:35:00.000Z',
          actualDateDeparture: '2024-10-09T15:34:00.000Z',
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: '2024-10-08T17:35:00.000Z',
          actualDateDepartureUTC: '2024-10-09T13:34:00.000Z',
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'No action required',
          isTSBerth: false,
          _id: '670708a2ef16b600498de159',
          arCargoes: [
            {
              cargoId: 193072,
              cargo: 'BUTYL ACETATE',
              charterer: 'THANSOME CHEMICAL TRADING LIMITE',
              companyFleet: '100010363__STJS',
              broker_fleet: ['ROYAL UNION HONGKONG LIMITED__STJS'],
              oblNum: '315',
              func: 'D',
              _id: '670708a2ef16b600498de15a',
            },
            {
              cargoId: 193117,
              cargo: 'ALLYL ALCOHOL',
              charterer: 'RESONAC CORPORATION',
              companyFleet: '100009987__STJS',
              broker_fleet: [],
              oblNum: '320',
              func: 'D',
              _id: '670708a2ef16b600498de15b',
            },
          ],
          sortDate: '2024-10-08T19:35:00.000Z',
        },
      ],
      dtETA: '2024-09-30T10:00:00.000Z',
      status: null,
      vesVoy: 'SBISM__27__STJS',
      vslCode: 'SBISM',
      vslName: 'STOLT BISMUTH',
      voyNo: 27,
      seqNo: 1800,
      portNo: 792,
      portName: 'ROTTERDAM',
      dtETD: '2024-10-17T00:04:00.000Z',
      country: 'NETHERLANDS',
      hasPendingStatus: false,
    },
    {
      _id: '66f2c393a726c6bb1a02b847',
      arBerths: [
        {
          berthSeqNo: 2,
          berthName: 'ALAMOA',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: null,
          actualDateDeparture: null,
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: null,
          actualDateDepartureUTC: null,
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'Pending updates',
          isTSBerth: false,
          _id: '66fdaafbf321a60049d59a21',
          arCargoes: [
            {
              cargoId: 194957,
              cargo: 'ANHYDROUS ETHANOL',
              charterer: 'RAIZEN TRADING SA',
              companyFleet: '100009720__STJS',
              broker_fleet: ['TIDE MARITIME__STJS'],
              oblNum: '800',
              func: 'L',
              _id: '66fdaafbf321a60049d59a22',
            },
            {
              cargoId: 194958,
              cargo: 'ETHANOL, POTABLE',
              charterer: 'RAIZEN TRADING SA',
              companyFleet: '100009720__STJS',
              broker_fleet: ['TIDE MARITIME AFRETAMENTOS LTDA__STJS'],
              oblNum: '810',
              func: 'L',
              _id: '66fdaafbf321a60049d59a23',
            },
            {
              cargoId: 194959,
              cargo: 'ETHANOL, NEUTRAL',
              charterer: 'RAIZEN TRADING SA',
              companyFleet: '100009720__STJS',
              broker_fleet: ['TIDE MARITIME AFRETAMENTOS LTDA__STJS'],
              oblNum: '830',
              func: 'L',
              _id: '66fdaafbf321a60049d59a24',
            },
            {
              cargoId: 195518,
              cargo: 'ETHANOL, POTABLE',
              charterer: 'RAIZEN TRADING SA',
              companyFleet: '100009720__STJS',
              broker_fleet: ['TIDE MARITIME__STJS'],
              oblNum: '820',
              func: 'L',
              _id: '66fdaafbf321a60049d59a25',
            },
          ],
          sortDate: '+275760-09-13T00:00:00.000Z',
        },
      ],
      dtETA: '2024-10-02T03:50:00.000Z',
      status: 'Pending updates',
      vesVoy: 'SVISI__133__STJS',
      vslCode: 'SVISI',
      vslName: 'STOLT VISION',
      voyNo: 133,
      seqNo: 200,
      portNo: 414,
      portName: 'SANTOS',
      dtETD: '2024-10-10T22:30:00.000Z',
      country: 'BRAZIL',
      hasPendingStatus: true,
    },
    {
      _id: '66e892f8a4f896dd7e4ecb5b',
      arBerths: [
        {
          berthSeqNo: 2,
          berthName: 'VOPAK 2',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: '2024-10-04T02:54:00.000Z',
          actualDateDeparture: '2024-10-05T00:30:00.000Z',
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: '2024-10-04T07:54:00.000Z',
          actualDateDepartureUTC: '2024-10-05T05:30:00.000Z',
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'No action required',
          isTSBerth: false,
          _id: '67069822ef16b60049828bfb',
          arCargoes: [
            {
              cargoId: 23460,
              cargo: 'JAYFLEX DINP',
              charterer: 'SEARIVER MARITIME- ODIN MARINE',
              companyFleet: '100007358__SNICS',
              broker_fleet: ['ODIN MARINE__SNICS'],
              oblNum: '610',
              func: 'L',
              _id: '67069822ef16b60049828bfc',
            },
            {
              cargoId: 23462,
              cargo: 'MEK',
              charterer: 'SEARIVER MARITIME- ODIN MARINE',
              companyFleet: '100007358__SNICS',
              broker_fleet: ['ODIN MARINE__SNICS'],
              oblNum: '620',
              func: 'L',
              _id: '67069822ef16b60049828bfd',
            },
            {
              cargoId: 23465,
              cargo: 'IPA',
              charterer: 'SEARIVER MARITIME- ODIN MARINE',
              companyFleet: '100007358__SNICS',
              broker_fleet: ['ODIN MARINE__SNICS'],
              oblNum: '630',
              func: 'L',
              _id: '67069822ef16b60049828bfe',
            },
          ],
          sortDate: '2024-10-04T02:54:00.000Z',
        },
        {
          berthSeqNo: 1,
          berthName: 'EXXON 1',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: null,
          actualDateDeparture: null,
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: null,
          actualDateDepartureUTC: null,
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'Pending updates',
          isTSBerth: false,
          _id: '67069822ef16b60049828bf4',
          arCargoes: [
            {
              cargoId: 23458,
              cargo: 'EXXSOL D 80 FLUID',
              charterer: 'SEARIVER MARITIME- ODIN MARINE',
              companyFleet: '100007358__SNICS',
              broker_fleet: ['ODIN MARINE__SNICS'],
              oblNum: '600',
              func: 'L',
              _id: '67069822ef16b60049828bf5',
            },
            {
              cargoId: 23459,
              cargo: 'SOLVENT NAPHTHA M',
              charterer: 'SEARIVER MARITIME- ODIN MARINE',
              companyFleet: '100007358__SNICS',
              broker_fleet: ['ODIN MARINE__SNICS'],
              oblNum: '605',
              func: 'L',
              _id: '67069822ef16b60049828bf6',
            },
            {
              cargoId: 23461,
              cargo: 'VARSOL 1 FLUID',
              charterer: 'SEARIVER MARITIME- ODIN MARINE',
              companyFleet: '100007358__SNICS',
              broker_fleet: ['ODIN MARINE__SNICS'],
              oblNum: '615',
              func: 'L',
              _id: '67069822ef16b60049828bf7',
            },
            {
              cargoId: 23463,
              cargo: 'SOLVENT NAPHTHA M',
              charterer: 'SEARIVER MARITIME- ODIN MARINE',
              companyFleet: '100007358__SNICS',
              broker_fleet: ['ODIN MARINE__SNICS'],
              oblNum: '606',
              func: 'L',
              _id: '67069822ef16b60049828bf8',
            },
            {
              cargoId: 23464,
              cargo: 'SOLVENT NAPHTHA L',
              charterer: 'SEARIVER MARITIME- ODIN MARINE',
              companyFleet: '100007358__SNICS',
              broker_fleet: ['ODIN MARINE__SNICS'],
              oblNum: '625',
              func: 'L',
              _id: '67069822ef16b60049828bf9',
            },
            {
              cargoId: 23466,
              cargo: 'VARSOL 1 FLUID',
              charterer: 'SEARIVER MARITIME- ODIN MARINE',
              companyFleet: '100007358__SNICS',
              broker_fleet: ['ODIN MARINE__SNICS'],
              oblNum: '616',
              func: 'L',
              _id: '67069822ef16b60049828bfa',
            },
          ],
          sortDate: '+275760-09-13T00:00:00.000Z',
        },
      ],
      dtETA: '2024-10-02T19:00:00.000Z',
      status: 'Pending updates',
      vesVoy: 'STEAL__275__SNICS',
      vslCode: 'STEAL',
      vslName: 'STOLT TEAL',
      voyNo: 275,
      seqNo: 200,
      portNo: 187,
      portName: 'HOUSTON',
      dtETD: '2024-10-14T07:00:00.000Z',
      country: 'UNITED STATES',
      hasPendingStatus: true,
    },
    {
      _id: '66cfa99f087e4496b4830f79',
      arBerths: [
        {
          berthSeqNo: 5,
          berthName: 'OTK(S)',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: '2024-10-06T07:42:00.000Z',
          actualDateDeparture: '2024-10-06T20:32:00.000Z',
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: '2024-10-05T22:42:00.000Z',
          actualDateDepartureUTC: '2024-10-06T11:32:00.000Z',
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'No action required',
          isTSBerth: false,
          _id: '6706911aef16b6004981c414',
          arCargoes: [
            {
              cargoId: 194589,
              cargo: 'ALLYL ALCOHOL',
              charterer: 'RESONAC CORPORATION',
              companyFleet: '100009987__STJS',
              broker_fleet: [],
              oblNum: '870',
              func: 'L',
              _id: '6706911aef16b6004981c415',
            },
          ],
          sortDate: '2024-10-06T07:42:00.000Z',
        },
        {
          berthSeqNo: 4,
          berthName: 'SBTS 1',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: null,
          actualDateDeparture: null,
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: null,
          actualDateDepartureUTC: null,
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'Pending updates',
          isTSBerth: false,
          _id: '6706911aef16b6004981c412',
          arCargoes: [
            {
              cargoId: 194428,
              cargo: 'ISOPRENE',
              charterer: 'TRIBUTE ENERGY INC',
              companyFleet: '100007876__STJS',
              broker_fleet: ['NETCO__STJS'],
              oblNum: '845',
              func: 'L',
              _id: '6706911aef16b6004981c413',
            },
          ],
          sortDate: '+275760-09-13T00:00:00.000Z',
        },
      ],
      dtETA: '2024-10-03T14:00:00.000Z',
      status: 'Pending updates',
      vesVoy: 'SINOV__181__STJS',
      vslCode: 'SINOV',
      vslName: 'STOLT INNOVATION',
      voyNo: 181,
      seqNo: 2400,
      portNo: 8043,
      portName: 'ULSAN',
      dtETD: '2024-10-13T23:00:00.000Z',
      country: 'KOREA, REPUBLIC',
      hasPendingStatus: true,
    },
    {
      _id: '66e443123a335c1520e3edf7',
      arBerths: [
        {
          berthSeqNo: 1,
          berthName: 'ITC1',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: '2024-10-07T22:55:00.000Z',
          actualDateDeparture: null,
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: '2024-10-08T03:55:00.000Z',
          actualDateDepartureUTC: null,
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'Pending updates',
          isTSBerth: false,
          _id: '67062ead42754f0048275f9a',
          arCargoes: [
            {
              cargoId: 194914,
              cargo: 'HEXENE-1',
              charterer: 'CHEVRON PHILLIP - NEW ENGLAND',
              companyFleet: '100002364__STJS',
              broker_fleet: ['NETCO__STJS'],
              oblNum: '10',
              func: 'L',
              _id: '67062ead42754f0048275f9b',
            },
            {
              cargoId: 194915,
              cargo: 'ALPHA OLEFIN C-10',
              charterer: 'CHEVRON PHILLIP - NEW ENGLAND',
              companyFleet: '100002364__STJS',
              broker_fleet: ['NETCO__STJS'],
              oblNum: '20',
              func: 'L',
              _id: '67062ead42754f0048275f9c',
            },
            {
              cargoId: 195559,
              cargo: 'VAM',
              charterer: 'GANTRADE - ODIN MARINE',
              companyFleet: '100001931__STJS',
              broker_fleet: ['ODIN MARINE__STJS'],
              oblNum: '60',
              func: 'L',
              _id: '67062ead42754f0048275f9d',
            },
            {
              cargoId: 195560,
              cargo: 'BUTYL ACRYLATE',
              charterer: 'GANTRADE - ODIN MARINE',
              companyFleet: '100001931__STJS',
              broker_fleet: ['ODIN MARINE__STJS'],
              oblNum: '70',
              func: 'L',
              _id: '67062ead42754f0048275f9e',
            },
            {
              cargoId: 195708,
              cargo: 'CRUDE BENZENE',
              charterer: 'COUNT ENERGY TRADING AMERICAS LL',
              companyFleet: '100010101__STJS',
              broker_fleet: ['OKTO MARINE INC__STJS'],
              oblNum: '90',
              func: 'L',
              _id: '67062ead42754f0048275f9f',
            },
          ],
          sortDate: '2024-10-07T22:55:00.000Z',
        },
        {
          berthSeqNo: 2,
          berthName: 'Stolt 3',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: null,
          actualDateDeparture: null,
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: null,
          actualDateDepartureUTC: null,
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'Pending updates',
          isTSBerth: false,
          _id: '67062ead42754f0048275fa0',
          arCargoes: [
            {
              cargoId: 194919,
              cargo: 'ALPHAPLUS C12/14 BLEND',
              charterer: 'CHEVRON PHILLIP - NEW ENGLAND',
              companyFleet: '100002364__STJS',
              broker_fleet: ['NETCO__STJS'],
              oblNum: '30',
              func: 'L',
              _id: '67062ead42754f0048275fa1',
            },
          ],
          sortDate: '+275760-09-13T00:00:00.000Z',
        },
        {
          berthSeqNo: 6,
          berthName: 'SHELL',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: null,
          actualDateDeparture: null,
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: null,
          actualDateDepartureUTC: null,
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'Pending updates',
          isTSBerth: false,
          _id: '67062ead42754f0048275fa2',
          arCargoes: [
            {
              cargoId: 195384,
              cargo: 'PHENOL',
              charterer: 'CEPSA QUIMICA SA',
              companyFleet: '100005236__STJS',
              broker_fleet: ['ODIN MARINE__STJS'],
              oblNum: '50',
              func: 'L',
              _id: '67062ead42754f0048275fa3',
            },
          ],
          sortDate: '+275760-09-13T00:00:00.000Z',
        },
        {
          berthSeqNo: 7,
          berthName: 'Stolt 2',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: null,
          actualDateDeparture: null,
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: null,
          actualDateDepartureUTC: null,
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'Pending updates',
          isTSBerth: false,
          _id: '67062ead42754f0048275fa4',
          arCargoes: [],
          sortDate: '+275760-09-13T00:00:00.000Z',
        },
      ],
      dtETA: '2024-10-03T16:00:00.000Z',
      status: 'Pending updates',
      vesVoy: 'SORCA__16__STJS',
      vslCode: 'SORCA',
      vslName: 'STOLT ORCA',
      voyNo: 16,
      seqNo: 200,
      portNo: 187,
      portName: 'HOUSTON',
      dtETD: '2024-10-11T02:10:00.000Z',
      country: 'UNITED STATES',
      hasPendingStatus: true,
    },
    {
      _id: '66f302dac4cc8b9e7e786a5b',
      arBerths: [
        {
          berthSeqNo: 1,
          berthName: 'Stolt 2',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: '2024-10-04T19:00:00.000Z',
          actualDateDeparture: '2024-10-05T15:40:00.000Z',
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: '2024-10-05T00:00:00.000Z',
          actualDateDepartureUTC: '2024-10-05T20:40:00.000Z',
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'No action required',
          isTSBerth: false,
          _id: '67069f2def16b6004983748a',
          arCargoes: [
            {
              cargoId: 23500,
              cargo: 'ACETIC ACID',
              charterer: 'MARITIME BULK FREIGHTER LLC',
              companyFleet: '100008340__SNICS',
              broker_fleet: ['NETCO__SNICS'],
              oblNum: '820',
              func: 'L',
              _id: '67069f2def16b6004983748b',
            },
            {
              cargoId: 23511,
              cargo: 'IPA',
              charterer: 'QUIMICA DELTA SA -',
              companyFleet: '100007715__SNICS',
              broker_fleet: ['NETCO__SNICS'],
              oblNum: '830',
              func: 'L',
              _id: '67069f2def16b6004983748c',
            },
          ],
          sortDate: '2024-10-04T19:00:00.000Z',
        },
        {
          berthSeqNo: 2,
          berthName: 'Stolt 11',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: '2024-10-05T16:15:00.000Z',
          actualDateDeparture: '2024-10-06T05:10:00.000Z',
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: '2024-10-05T21:15:00.000Z',
          actualDateDepartureUTC: '2024-10-06T10:10:00.000Z',
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'No action required',
          isTSBerth: false,
          _id: '67069f2def16b6004983748d',
          arCargoes: [
            {
              cargoId: 23479,
              cargo: 'LAB',
              charterer: 'FARABI MARKETING COMPANY',
              companyFleet: '100008973__SNICS',
              broker_fleet: ['NETCO__SNICS'],
              oblNum: '805',
              func: 'L',
              _id: '67069f2def16b6004983748e',
            },
          ],
          sortDate: '2024-10-05T16:15:00.000Z',
        },
        {
          berthSeqNo: 4,
          berthName: 'VOPAK 4',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: null,
          actualDateDeparture: null,
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: null,
          actualDateDepartureUTC: null,
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'Pending updates',
          isTSBerth: false,
          _id: '67069f2def16b6004983748f',
          arCargoes: [
            {
              cargoId: 23499,
              cargo: 'STYRENE MONOMER',
              charterer: 'RESIRENE S.A.',
              companyFleet: '100001295__SNICS',
              broker_fleet: [],
              oblNum: '840',
              func: 'L',
              _id: '67069f2def16b60049837490',
            },
            {
              cargoId: 23552,
              cargo: 'STYRENE MONOMER',
              charterer: 'STYROPEK MEXICO SA DE CV',
              companyFleet: '100005198__SNICS',
              broker_fleet: ['QUINCANNON__SNICS'],
              oblNum: '850',
              func: 'L',
              _id: '67069f2def16b60049837491',
            },
          ],
          sortDate: '+275760-09-13T00:00:00.000Z',
        },
      ],
      dtETA: '2024-10-04T13:00:00.000Z',
      status: 'Pending updates',
      vesVoy: 'SILEX__168__SNICS',
      vslCode: 'SILEX',
      vslName: 'STOLT ILEX',
      voyNo: 168,
      seqNo: 200,
      portNo: 187,
      portName: 'HOUSTON',
      dtETD: '2024-10-11T19:00:00.000Z',
      country: 'UNITED STATES',
      hasPendingStatus: true,
    },
    {
      _id: '66b466483c8ca9005ce2dcfa',
      arBerths: [
        {
          berthSeqNo: 2,
          berthName: 'STOLT 3',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: '2024-10-05T05:15:00.000Z',
          actualDateDeparture: '2024-10-06T10:03:00.000Z',
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: '2024-10-05T10:15:00.000Z',
          actualDateDepartureUTC: '2024-10-06T15:03:00.000Z',
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'No action required',
          isTSBerth: false,
          _id: '6706fa92ef16b600498c79ed',
          arCargoes: [
            {
              cargoId: 192493,
              cargo: 'MASCID 1850',
              charterer: 'INTERCONT. O AND F PTE LTD',
              companyFleet: '100004871__STJS',
              broker_fleet: [],
              oblNum: '747',
              func: 'D',
              _id: '6706fa92ef16b600498c79ee',
            },
            {
              cargoId: 192494,
              cargo: 'TP STEARIC ACID',
              charterer: 'INTERCONT. O AND F PTE LTD',
              companyFleet: '100004871__STJS',
              broker_fleet: [],
              oblNum: '757',
              func: 'D',
              _id: '6706fa92ef16b600498c79ef',
            },
            {
              cargoId: 193287,
              cargo: 'PETRONAS ETRO 4+',
              charterer: 'PETRONAS LUBRICANTS INT',
              companyFleet: '100008749__STJS',
              broker_fleet: ['RG CHARTERING PTE LTD__STJS'],
              oblNum: '817',
              func: 'D',
              _id: '6706fa92ef16b600498c79f0',
            },
          ],
          sortDate: '2024-10-05T05:15:00.000Z',
        },
        {
          berthSeqNo: 3,
          berthName: 'KM #2',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: '2024-10-08T05:30:00.000Z',
          actualDateDeparture: '2024-10-09T12:37:00.000Z',
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: '2024-10-08T10:30:00.000Z',
          actualDateDepartureUTC: '2024-10-09T17:37:00.000Z',
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'No action required',
          isTSBerth: false,
          _id: '6706fa92ef16b600498c79f1',
          arCargoes: [
            {
              cargoId: 192073,
              cargo: 'FATTY ALCOHOL C-12/16',
              charterer: 'WILMAR - RAFFLES',
              companyFleet: '100002000__STJS',
              broker_fleet: ['RAFFLES CHARTER__STJS'],
              oblNum: '577',
              func: 'D',
              _id: '6706fa92ef16b600498c79f2',
            },
          ],
          sortDate: '2024-10-08T05:30:00.000Z',
        },
        {
          berthSeqNo: 4,
          berthName: '12 Mile Anchorage',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: null,
          actualDateDeparture: null,
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: null,
          actualDateDepartureUTC: null,
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'Pending updates',
          isTSBerth: false,
          _id: '6706fa92ef16b600498c79f3',
          arCargoes: [],
          sortDate: '+275760-09-13T00:00:00.000Z',
        },
      ],
      dtETA: '2024-10-04T18:35:00.000Z',
      status: 'Pending updates',
      vesVoy: 'SINTG__36__STJS',
      vslCode: 'SINTG',
      vslName: 'STOLT INTEGRITY',
      voyNo: 36,
      seqNo: 1600,
      portNo: 276,
      portName: 'NEW ORLEANS',
      dtETD: '2024-10-12T10:00:00.000Z',
      country: 'UNITED STATES',
      hasPendingStatus: true,
    },
    {
      _id: '6704508cc346aa8db0f5a5ba',
      arBerths: [
        {
          berthSeqNo: 2,
          berthName: 'BERTH 1',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: null,
          actualDateDeparture: null,
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: null,
          actualDateDepartureUTC: null,
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'Pending updates',
          isTSBerth: false,
          _id: '6705a21142754f0048181ad3',
          arCargoes: [
            {
              cargoId: 195726,
              cargo: 'METHANOL',
              charterer: 'MITSUBISHI GAS CHEMICAL',
              companyFleet: '100008649__STJS',
              broker_fleet: ['NETCO__STJS'],
              oblNum: '500',
              func: 'L',
              _id: '6705a21142754f0048181ad4',
            },
          ],
          sortDate: '+275760-09-13T00:00:00.000Z',
        },
      ],
      dtETA: '2024-10-04T22:00:00.000Z',
      status: 'Pending updates',
      vesVoy: 'SVANG__124__STJS',
      vslCode: 'SVANG',
      vslName: 'STOLT VANGUARD',
      voyNo: 124,
      seqNo: 1600,
      portNo: 28387,
      portName: 'LA BREA',
      dtETD: '2024-10-12T22:00:00.000Z',
      country: 'TRINIDAD AND TOBAGO',
      hasPendingStatus: true,
    },
    {
      _id: '66f1bf6e306e01c11736f49b',
      arBerths: [
        {
          berthSeqNo: 1,
          berthName: 'LBC 1',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: null,
          actualDateDeparture: null,
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: null,
          actualDateDepartureUTC: null,
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'Pending updates',
          isTSBerth: false,
          _id: '6706a632ef16b60049844336',
          arCargoes: [
            {
              cargoId: 194874,
              cargo: 'AD BASE 4 CST',
              charterer: 'CHEVRON INTER TANK - SPI HOUSTON',
              companyFleet: '100008770__STJS',
              broker_fleet: ['SPI MARINE USA INC__STJS'],
              oblNum: '103',
              func: 'L',
              _id: '6706a632ef16b60049844337',
            },
            {
              cargoId: 194875,
              cargo: 'NEXBASE 3043',
              charterer: 'CHEVRON INTER TANK - SPI HOUSTON',
              companyFleet: '100008770__STJS',
              broker_fleet: ['SPI MARINE USA INC__STJS'],
              oblNum: '113',
              func: 'L',
              _id: '6706a632ef16b60049844338',
            },
            {
              cargoId: 194876,
              cargo: 'CHEVRON NEUTR OIL 100R',
              charterer: 'CHEVRON INTER TANK - SPI HOUSTON',
              companyFleet: '100008770__STJS',
              broker_fleet: ['SPI MARINE USA INC__STJS'],
              oblNum: '153',
              func: 'L',
              _id: '6706a632ef16b60049844339',
            },
            {
              cargoId: 194877,
              cargo: 'CHEVRON NEUTRAL 220R',
              charterer: 'CHEVRON INTER TANK - SPI HOUSTON',
              companyFleet: '100008770__STJS',
              broker_fleet: ['SPI MARINE USA INC__STJS'],
              oblNum: '163',
              func: 'L',
              _id: '6706a632ef16b6004984433a',
            },
            {
              cargoId: 194878,
              cargo: 'NEXBASE 3060',
              charterer: 'CHEVRON INTER TANK - SPI HOUSTON',
              companyFleet: '100008770__STJS',
              broker_fleet: ['SPI MARINE USA INC__STJS'],
              oblNum: '133',
              func: 'L',
              _id: '6706a632ef16b6004984433b',
            },
            {
              cargoId: 194879,
              cargo: 'CHEVRON NEUTRAL 600R',
              charterer: 'CHEVRON INTER TANK - SPI HOUSTON',
              companyFleet: '100008770__STJS',
              broker_fleet: ['SPI MARINE USA INC__STJS'],
              oblNum: '173',
              func: 'L',
              _id: '6706a632ef16b6004984433c',
            },
            {
              cargoId: 194880,
              cargo: 'Chevron Neutral 60R',
              charterer: 'CHEVRON INTER TANK - SPI HOUSTON',
              companyFleet: '100008770__STJS',
              broker_fleet: ['SPI MARINE USA INC__STJS'],
              oblNum: '183',
              func: 'L',
              _id: '6706a632ef16b6004984433d',
            },
            {
              cargoId: 194881,
              cargo: 'NEXBASE 3060',
              charterer: 'CHEVRON INTER TANK - SPI HOUSTON',
              companyFleet: '100008770__STJS',
              broker_fleet: ['SPI MARINE USA INC__STJS'],
              oblNum: '143',
              func: 'L',
              _id: '6706a632ef16b6004984433e',
            },
            {
              cargoId: 194882,
              cargo: 'NEXBASE 3043',
              charterer: 'CHEVRON INTER TANK - SPI HOUSTON',
              companyFleet: '100008770__STJS',
              broker_fleet: ['SPI MARINE USA INC__STJS'],
              oblNum: '123',
              func: 'L',
              _id: '6706a632ef16b6004984433f',
            },
            {
              cargoId: 195033,
              cargo: 'NEXBASE 3080',
              charterer: 'CHEVRON INTER TANK - SPI HOUSTON',
              companyFleet: '100008770__STJS',
              broker_fleet: ['SPI MARINE USA INC__STJS'],
              oblNum: '193',
              func: 'L',
              _id: '6706a632ef16b60049844340',
            },
          ],
          sortDate: '+275760-09-13T00:00:00.000Z',
        },
        {
          berthSeqNo: 4,
          berthName: 'VOPAK 2',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: null,
          actualDateDeparture: null,
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: null,
          actualDateDepartureUTC: null,
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'Pending updates',
          isTSBerth: false,
          _id: '6706a632ef16b60049844341',
          arCargoes: [
            {
              cargoId: 195383,
              cargo: 'ACETIC ACID',
              charterer: 'ALPEK POLYESTER PERNAMBUCO SA',
              companyFleet: '100009978__STJS',
              broker_fleet: ['NETCO__STJS'],
              oblNum: '200',
              func: 'L',
              _id: '6706a632ef16b60049844342',
            },
          ],
          sortDate: '+275760-09-13T00:00:00.000Z',
        },
        {
          berthSeqNo: 5,
          berthName: 'LBC 2',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: null,
          actualDateDeparture: null,
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: null,
          actualDateDepartureUTC: null,
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'Pending updates',
          isTSBerth: false,
          _id: '6706a632ef16b60049844343',
          arCargoes: [
            {
              cargoId: 195509,
              cargo: 'METHANOL',
              charterer: 'OQ TRADING LIMITED',
              companyFleet: '100009429__STJS',
              broker_fleet: ['EASTPORT MARITIME USA LLC__STJS'],
              oblNum: '210',
              func: 'L',
              _id: '6706a632ef16b60049844344',
            },
          ],
          sortDate: '+275760-09-13T00:00:00.000Z',
        },
      ],
      dtETA: '2024-10-04T22:48:00.000Z',
      status: 'Pending updates',
      vesVoy: 'BCASA__5__STJS',
      vslCode: 'BCASA',
      vslName: 'BOCHEM CASABLANCA',
      voyNo: 5,
      seqNo: 200,
      portNo: 187,
      portName: 'HOUSTON',
      dtETD: '2024-10-16T03:00:00.000Z',
      country: 'UNITED STATES',
      hasPendingStatus: true,
    },
    {
      _id: '66bcc4222b97115ad0926451',
      arBerths: [
        {
          berthSeqNo: 1,
          berthName: 'PGL1 - B',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: null,
          actualDateDeparture: null,
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: null,
          actualDateDepartureUTC: null,
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'Pending updates',
          isTSBerth: false,
          _id: '6706c95bef16b60049875c35',
          arCargoes: [
            {
              cargoId: 193476,
              cargo: 'PARAXYLENE',
              charterer: 'ARAMCO TRADING COMPANY',
              companyFleet: '100009469__STJS',
              broker_fleet: ['RESHAMWALA SHIPBROKERS DMCC__STJS'],
              oblNum: '430',
              func: 'D',
              _id: '6706c95bef16b60049875c36',
            },
            {
              cargoId: 194788,
              cargo: 'PARAXYLENE',
              charterer: 'ARAMCO TRADING COMPANY',
              companyFleet: '100009469__STJS',
              broker_fleet: ['RESHAMWALA SHIPBROKERS DMCC__STJS'],
              oblNum: '431',
              func: 'D',
              _id: '6706c95bef16b60049875c37',
            },
          ],
          sortDate: '+275760-09-13T00:00:00.000Z',
        },
      ],
      dtETA: '2024-10-05T04:42:00.000Z',
      status: 'Pending updates',
      vesVoy: 'SLIND__47__STJS',
      vslCode: 'SLIND',
      vslName: 'STOLT LIND',
      voyNo: 47,
      seqNo: 10200,
      portNo: 21091,
      portName: 'SUAPE',
      dtETD: '2024-10-15T23:59:00.000Z',
      country: 'BRAZIL',
      hasPendingStatus: true,
    },
    {
      _id: '66b466413c8ca9005ce2c964',
      arBerths: [
        {
          berthSeqNo: 4,
          berthName: 'TEPSA 1',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: '2024-10-07T10:45:00.000Z',
          actualDateDeparture: '2024-10-08T21:18:00.000Z',
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: '2024-10-07T08:45:00.000Z',
          actualDateDepartureUTC: '2024-10-08T19:18:00.000Z',
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'No action required',
          isTSBerth: false,
          _id: '67079543b2d73f00485a88ec',
          arCargoes: [
            {
              cargoId: 194800,
              cargo: 'ETHANOL',
              charterer: 'ALCOTRA S.A.',
              companyFleet: '100000150__STJS',
              broker_fleet: ['INTERNATIONAL TANKER CHARTERING__STJS'],
              oblNum: '155',
              func: 'D',
              _id: '67079543b2d73f00485a88ed',
            },
            {
              cargoId: 194883,
              cargo: 'ETHANOL',
              charterer: 'ALCOTRA S.A.',
              companyFleet: '100000150__STJS',
              broker_fleet: ['INTERNATIONAL TANKER CHARTERING__STJS'],
              oblNum: '156',
              func: 'D',
              _id: '67079543b2d73f00485a88ee',
            },
          ],
          sortDate: '2024-10-07T10:45:00.000Z',
        },
        {
          berthSeqNo: 3,
          berthName: 'TEPSA 2',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: '2024-10-08T22:00:00.000Z',
          actualDateDeparture: '2024-10-10T07:50:00.000Z',
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: '2024-10-08T20:00:00.000Z',
          actualDateDepartureUTC: '2024-10-10T05:50:00.000Z',
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'No action required',
          isTSBerth: false,
          _id: '67079543b2d73f00485a88ea',
          arCargoes: [
            {
              cargoId: 194686,
              cargo: 'HMD',
              charterer: 'INV NYLON CHEMICALS AMERICAS LLC',
              companyFleet: '100009356__STJS',
              broker_fleet: ['NETCO__STJS'],
              oblNum: '150',
              func: 'D',
              _id: '67079543b2d73f00485a88eb',
            },
          ],
          sortDate: '2024-10-08T22:00:00.000Z',
        },
        {
          berthSeqNo: 7,
          berthName: 'SMIS',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: null,
          actualDateDeparture: null,
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: null,
          actualDateDepartureUTC: null,
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'Pending updates',
          isTSBerth: false,
          _id: '67079543b2d73f00485a88ef',
          arCargoes: [],
          sortDate: '+275760-09-13T00:00:00.000Z',
        },
      ],
      dtETA: '2024-10-05T08:00:00.000Z',
      status: 'Pending updates',
      vesVoy: 'SAPAL__53__STJS',
      vslCode: 'SAPAL',
      vslName: 'STOLT APAL',
      voyNo: 53,
      seqNo: 600,
      portNo: 792,
      portName: 'ROTTERDAM',
      dtETD: '2024-10-10T17:00:00.000Z',
      country: 'NETHERLANDS',
      hasPendingStatus: true,
    },
    {
      _id: '66b4664b3c8ca9005ce2e40b',
      arBerths: [
        {
          berthSeqNo: 5,
          berthName: 'VPK V624',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: '2024-10-05T18:35:00.000Z',
          actualDateDeparture: null,
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: '2024-10-05T16:35:00.000Z',
          actualDateDepartureUTC: null,
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'Pending updates',
          isTSBerth: false,
          _id: '67079548b2d73f00485a8a02',
          arCargoes: [
            {
              cargoId: 193164,
              cargo: 'PG FACID MESTER 810EU',
              charterer: 'P&G INTERNATIONAL OPERATIONS SA',
              companyFleet: '100009152__STJS',
              broker_fleet: ['CLARKSON PLATOU ASIA PTE LTD__STJS'],
              oblNum: '200',
              func: 'D',
              _id: '67079548b2d73f00485a8a03',
            },
            {
              cargoId: 193165,
              cargo: 'P&G CE-1214',
              charterer: 'P&G INTERNATIONAL OPERATIONS SA',
              companyFleet: '100009152__STJS',
              broker_fleet: ['CLARKSON PLATOU ASIA PTE LTD__STJS'],
              oblNum: '205',
              func: 'D',
              _id: '67079548b2d73f00485a8a04',
            },
            {
              cargoId: 193278,
              cargo: 'CEPSA C810',
              charterer: 'SINARMAS CEPSA PTE LTD',
              companyFleet: '100008354__STJS',
              broker_fleet: ['ODIN MRN SPCLTY TNKR SG__STJS'],
              oblNum: '220',
              func: 'D',
              _id: '67079548b2d73f00485a8a05',
            },
            {
              cargoId: 193279,
              cargo: 'CEPSINOL 1216',
              charterer: 'SINARMAS CEPSA PTE LTD',
              companyFleet: '100008354__STJS',
              broker_fleet: ['ODIN MRN SPCLTY TNKR SG__STJS'],
              oblNum: '225',
              func: 'D',
              _id: '67079548b2d73f00485a8a06',
            },
            {
              cargoId: 193280,
              cargo: 'CEPSINOL 1618H',
              charterer: 'SINARMAS CEPSA PTE LTD',
              companyFleet: '100008354__STJS',
              broker_fleet: ['ODIN MRN SPCLTY TNKR SG__STJS'],
              oblNum: '230',
              func: 'D',
              _id: '67079548b2d73f00485a8a07',
            },
            {
              cargoId: 193333,
              cargo: 'CEPSINOL 1214',
              charterer: 'SINARMAS CEPSA PTE LTD',
              companyFleet: '100008354__STJS',
              broker_fleet: ['ODIN MRN SPCLTY TNKR SG__STJS'],
              oblNum: '235',
              func: 'D',
              _id: '67079548b2d73f00485a8a08',
            },
            {
              cargoId: 193643,
              cargo: 'PALM OIL MILL EFFLUENT',
              charterer: 'REG INTERNATIONAL TRADING',
              companyFleet: '100009127__STJS',
              broker_fleet: ['NETCO SINGAPORE__STJS'],
              oblNum: '210',
              func: 'D',
              _id: '67079548b2d73f00485a8a09',
            },
          ],
          sortDate: '2024-10-05T18:35:00.000Z',
        },
        {
          berthSeqNo: 2,
          berthName: 'Koole Pernis 2',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: null,
          actualDateDeparture: null,
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: null,
          actualDateDepartureUTC: null,
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'Pending updates',
          isTSBerth: false,
          _id: '67079548b2d73f00485a8a00',
          arCargoes: [
            {
              cargoId: 193202,
              cargo: 'YUBASE 4 PLUS',
              charterer: 'SK ENMOVE CO',
              companyFleet: '100009959__STJS',
              broker_fleet: ['PLUS SHIPPING__STJS'],
              oblNum: '215',
              func: 'D',
              _id: '67079548b2d73f00485a8a01',
            },
          ],
          sortDate: '+275760-09-13T00:00:00.000Z',
        },
      ],
      dtETA: '2024-10-05T14:00:00.000Z',
      status: 'Pending updates',
      vesVoy: 'SPALM__43__STJS',
      vslCode: 'SPALM',
      vslName: 'STOLT PALM',
      voyNo: 43,
      seqNo: 1900,
      portNo: 792,
      portName: 'ROTTERDAM',
      dtETD: '2024-10-10T21:00:00.000Z',
      country: 'NETHERLANDS',
      hasPendingStatus: true,
    },
    {
      _id: '66b466453c8ca9005ce2d5b4',
      arBerths: [
        {
          berthSeqNo: 1,
          berthName: 'TUAS #A',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: null,
          actualDateDeparture: null,
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: null,
          actualDateDepartureUTC: null,
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'Pending updates',
          isTSBerth: false,
          _id: '670635b442754f0048282890',
          arCargoes: [
            {
              cargoId: 33700,
              cargo: 'TALLOW',
              charterer: 'NESTE SHIPPING OY',
              companyFleet: '100002075__SNAPS',
              broker_fleet: [],
              oblNum: '10',
              func: 'D',
              _id: '670635b442754f0048282891',
            },
            {
              cargoId: 33701,
              cargo: 'TALLOW',
              charterer: 'NESTE SHIPPING OY',
              companyFleet: '100002075__SNAPS',
              broker_fleet: [],
              oblNum: '50',
              func: 'D',
              _id: '670635b442754f0048282892',
            },
            {
              cargoId: 33702,
              cargo: 'UCO TRIGLYCERIDES',
              charterer: 'NESTE SHIPPING OY',
              companyFleet: '100002075__SNAPS',
              broker_fleet: [],
              oblNum: '30',
              func: 'D',
              _id: '670635b442754f0048282893',
            },
            {
              cargoId: 33703,
              cargo: 'TALLOW',
              charterer: 'NESTE SHIPPING OY',
              companyFleet: '100002075__SNAPS',
              broker_fleet: [],
              oblNum: '20',
              func: 'D',
              _id: '670635b442754f0048282894',
            },
            {
              cargoId: 33767,
              cargo: 'TALLOW',
              charterer: 'NESTE SHIPPING OY',
              companyFleet: '100002075__SNAPS',
              broker_fleet: [],
              oblNum: '21',
              func: 'D',
              _id: '670635b442754f0048282895',
            },
            {
              cargoId: 33775,
              cargo: 'TALLOW',
              charterer: 'NESTE SHIPPING OY',
              companyFleet: '100002075__SNAPS',
              broker_fleet: [],
              oblNum: '40',
              func: 'D',
              _id: '670635b442754f0048282896',
            },
          ],
          sortDate: '+275760-09-13T00:00:00.000Z',
        },
      ],
      dtETA: '2024-10-06T12:30:00.000Z',
      status: 'Pending updates',
      vesVoy: 'SRENG__67__SNAPS',
      vslCode: 'SRENG',
      vslName: 'STOLT RENGE',
      voyNo: 67,
      seqNo: 100,
      portNo: 3182,
      portName: 'SINGAPORE',
      dtETD: '2024-11-04T20:00:00.000Z',
      country: 'SINGAPORE',
      hasPendingStatus: true,
    },
    {
      _id: '66b466463c8ca9005ce2d879',
      arBerths: [
        {
          berthSeqNo: 2,
          berthName: 'INTERACID',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: '2024-10-06T22:30:00.000Z',
          actualDateDeparture: '2024-10-10T00:10:00.000Z',
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: '2024-10-07T01:30:00.000Z',
          actualDateDepartureUTC: '2024-10-10T03:10:00.000Z',
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'No action required',
          isTSBerth: false,
          _id: '67079543b2d73f00485a88e5',
          arCargoes: [
            {
              cargoId: 193408,
              cargo: 'SULPHURIC ACID 98%',
              charterer: 'TRICON ENERGY LTD',
              companyFleet: '100007043__STJS',
              broker_fleet: ['Lighthouse Chartering LLC__STJS'],
              oblNum: '100',
              func: 'D',
              _id: '67079543b2d73f00485a88e6',
            },
          ],
          sortDate: '2024-10-06T22:30:00.000Z',
        },
        {
          berthSeqNo: 5,
          berthName: 'TPM BERTH#2',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: null,
          actualDateDeparture: null,
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: null,
          actualDateDepartureUTC: null,
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'Pending updates',
          isTSBerth: false,
          _id: '67079543b2d73f00485a88e7',
          arCargoes: [
            {
              cargoId: 193408,
              cargo: 'SULPHURIC ACID 98%',
              charterer: 'TRICON ENERGY LTD',
              companyFleet: '100007043__STJS',
              broker_fleet: ['Lighthouse Chartering LLC__STJS'],
              oblNum: '100',
              func: 'D',
              _id: '67079543b2d73f00485a88e8',
            },
          ],
          sortDate: '+275760-09-13T00:00:00.000Z',
        },
      ],
      dtETA: '2024-10-06T14:30:00.000Z',
      status: 'Pending updates',
      vesVoy: 'SCEDA__99__STJS',
      vslCode: 'SCEDA',
      vslName: 'STOLT CEDAR',
      voyNo: 99,
      seqNo: 300,
      portNo: 8074,
      portName: 'MEJILLONES',
      dtETD: '2024-10-13T15:00:00.000Z',
      country: 'CHILE',
      hasPendingStatus: true,
    },
    {
      _id: '66f2ae906d2163f6e66d9c07',
      arBerths: [
        {
          berthSeqNo: 2,
          berthName: 'ESSO 1',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: '2024-10-07T14:54:00.000Z',
          actualDateDeparture: '2024-10-08T15:36:00.000Z',
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: '2024-10-07T12:54:00.000Z',
          actualDateDepartureUTC: '2024-10-08T13:36:00.000Z',
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'No action required',
          isTSBerth: false,
          _id: '67071dbaef16b600498ff107',
          arCargoes: [
            {
              cargoId: 195135,
              cargo: 'EHC 50',
              charterer: 'SEARIVER MARITIME LLC - UK',
              companyFleet: '100008660__STJS',
              broker_fleet: ['ODIN MARINE EUROPE BV__STJS'],
              oblNum: '575',
              func: 'L',
              _id: '67071dbaef16b600498ff108',
            },
          ],
          sortDate: '2024-10-07T14:54:00.000Z',
        },
        {
          berthSeqNo: 5,
          berthName: 'LIQUIN BOTLEK 6',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: '2024-10-08T16:30:00.000Z',
          actualDateDeparture: '2024-10-09T22:35:00.000Z',
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: '2024-10-08T14:30:00.000Z',
          actualDateDepartureUTC: '2024-10-09T20:35:00.000Z',
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'No action required',
          isTSBerth: false,
          _id: '67071dbaef16b600498ff10e',
          arCargoes: [
            {
              cargoId: 193888,
              cargo: 'NONANOL-ISO',
              charterer: 'EVONIK OXENO GMBH & CO KG',
              companyFleet: '100010115__STJS',
              broker_fleet: ['CLARKSONS DEUTSCHLAND GMBH__STJS'],
              oblNum: '545',
              func: 'L',
              _id: '67071dbaef16b600498ff10f',
            },
            {
              cargoId: 193889,
              cargo: 'PROPYLHEPTANOL',
              charterer: 'EVONIK OXENO GMBH & CO KG',
              companyFleet: '100010115__STJS',
              broker_fleet: ['CLARKSONS DEUTSCHLAND GMBH__STJS'],
              oblNum: '550',
              func: 'L',
              _id: '67071dbaef16b600498ff110',
            },
            {
              cargoId: 195133,
              cargo: 'EXXAL 8',
              charterer: 'SEARIVER MARITIME LLC - UK',
              companyFleet: '100008660__STJS',
              broker_fleet: ['ODIN MARINE EUROPE BV__STJS'],
              oblNum: '565',
              func: 'L',
              _id: '67071dbaef16b600498ff111',
            },
            {
              cargoId: 195134,
              cargo: 'EXXAL 10 ALCOHOL',
              charterer: 'SEARIVER MARITIME LLC - UK',
              companyFleet: '100008660__STJS',
              broker_fleet: ['ODIN MARINE EUROPE BV__STJS'],
              oblNum: '570',
              func: 'L',
              _id: '67071dbaef16b600498ff112',
            },
          ],
          sortDate: '2024-10-08T16:30:00.000Z',
        },
        {
          berthSeqNo: 3,
          berthName: 'VPK V626',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: null,
          actualDateDeparture: null,
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: null,
          actualDateDepartureUTC: null,
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'Pending updates',
          isTSBerth: false,
          _id: '67071dbaef16b600498ff109',
          arCargoes: [
            {
              cargoId: 195136,
              cargo: 'EXXON AP/E CORE 150',
              charterer: 'SEARIVER MARITIME LLC - UK',
              companyFleet: '100008660__STJS',
              broker_fleet: ['ODIN MARINE EUROPE BV__STJS'],
              oblNum: '580',
              func: 'L',
              _id: '67071dbaef16b600498ff10a',
            },
            {
              cargoId: 195650,
              cargo: 'DIPROPYLENE GLYCOL',
              charterer: 'LYONDELL CHEMIE NEDERLAND - BV',
              companyFleet: '100006670__STJS',
              broker_fleet: ['EASTPORT MARITIME EUROPE BV__STJS'],
              oblNum: '595',
              func: 'L',
              _id: '67071dbaef16b600498ff10b',
            },
          ],
          sortDate: '+275760-09-13T00:00:00.000Z',
        },
        {
          berthSeqNo: 4,
          berthName: 'LBC ZUID',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: null,
          actualDateDeparture: null,
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: null,
          actualDateDepartureUTC: null,
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'Pending updates',
          isTSBerth: false,
          _id: '67071dbaef16b600498ff10c',
          arCargoes: [
            {
              cargoId: 194658,
              cargo: 'DICHLOROPROPENE-1,3',
              charterer: 'INOVYN TRADE SERVICES SA',
              companyFleet: '100006738__STJS',
              broker_fleet: ['BRAEMAR  SEASCOPE__STJS'],
              oblNum: '560',
              func: 'L',
              _id: '67071dbaef16b600498ff10d',
            },
          ],
          sortDate: '+275760-09-13T00:00:00.000Z',
        },
        {
          berthSeqNo: 7,
          berthName: 'LBC NORTH',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: null,
          actualDateDeparture: null,
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: null,
          actualDateDepartureUTC: null,
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'Pending updates',
          isTSBerth: false,
          _id: '67071dbaef16b600498ff113',
          arCargoes: [
            {
              cargoId: 195649,
              cargo: 'GLYCOL ETHER PM SOLV',
              charterer: 'LYONDELL CHEMIE NEDERLAND - BV',
              companyFleet: '100006670__STJS',
              broker_fleet: ['EASTPORT MARITIME EUROPE BV__STJS'],
              oblNum: '590',
              func: 'L',
              _id: '67071dbaef16b600498ff114',
            },
          ],
          sortDate: '+275760-09-13T00:00:00.000Z',
        },
      ],
      dtETA: '2024-10-06T19:30:00.000Z',
      status: 'Pending updates',
      vesVoy: 'SMERC__26__STJS',
      vslCode: 'SMERC',
      vslName: 'STOLT MERCURY',
      voyNo: 26,
      seqNo: 10900,
      portNo: 792,
      portName: 'ROTTERDAM',
      dtETD: '2024-10-13T17:00:00.000Z',
      country: 'NETHERLANDS',
      hasPendingStatus: true,
    },
    {
      _id: '66b466423c8ca9005ce2cd97',
      arBerths: [
        {
          berthSeqNo: 1,
          berthName: 'LIQUID PIER',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: null,
          actualDateDeparture: null,
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: null,
          actualDateDepartureUTC: null,
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'Pending updates',
          isTSBerth: false,
          _id: '6705a20f42754f0048181a5f',
          arCargoes: [
            {
              cargoId: 194040,
              cargo: 'RBD PALM OIL',
              charterer: 'PASTERNAK BAUM - RIVERSIDE',
              companyFleet: '100009367__STJS',
              broker_fleet: ['RIVERSIDE TANKER CHARTERING LTD__STJS'],
              oblNum: '855',
              func: 'D',
              _id: '6705a20f42754f0048181a60',
            },
            {
              cargoId: 194042,
              cargo: 'RBD PALM OIL',
              charterer: 'PASTERNAK BAUM - RIVERSIDE',
              companyFleet: '100009367__STJS',
              broker_fleet: ['RIVERSIDE TANKER CHARTERING LTD__STJS'],
              oblNum: '845',
              func: 'D',
              _id: '6705a20f42754f0048181a61',
            },
          ],
          sortDate: '+275760-09-13T00:00:00.000Z',
        },
      ],
      dtETA: '2024-10-07T07:18:00.000Z',
      status: 'Pending updates',
      vesVoy: 'SCREA__176__STJS',
      vslCode: 'SCREA',
      vslName: 'STOLT CREATIVITY',
      voyNo: 176,
      seqNo: 9800,
      portNo: 8163,
      portName: 'PUERTO CORTES',
      dtETD: '2024-10-10T18:10:00.000Z',
      country: 'HONDURAS',
      hasPendingStatus: true,
    },
    {
      _id: '66f3fffa4696a36e834f9174',
      arBerths: [
        {
          berthSeqNo: 3,
          berthName: 'JSTT2',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: '2024-10-07T13:18:00.000Z',
          actualDateDeparture: '2024-10-08T09:40:00.000Z',
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: '2024-10-07T04:18:00.000Z',
          actualDateDepartureUTC: '2024-10-08T00:40:00.000Z',
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'No action required',
          isTSBerth: false,
          _id: '67079549b2d73f00485a8a11',
          arCargoes: [
            {
              cargoId: 195082,
              cargo: 'VAM',
              charterer: 'HELM US CHEM CORP',
              companyFleet: '100000181__STJS',
              broker_fleet: ['PANASIA MARINE (TANKERS) PTE LTD__STJS'],
              oblNum: '30',
              func: 'L',
              _id: '67079549b2d73f00485a8a12',
            },
          ],
          sortDate: '2024-10-07T13:18:00.000Z',
        },
        {
          berthSeqNo: 5,
          berthName: 'OTK(S)',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: '2024-10-09T14:00:00.000Z',
          actualDateDeparture: null,
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: '2024-10-09T05:00:00.000Z',
          actualDateDepartureUTC: null,
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'Pending updates',
          isTSBerth: false,
          _id: '67079549b2d73f00485a8a13',
          arCargoes: [],
          sortDate: '2024-10-09T14:00:00.000Z',
        },
        {
          berthSeqNo: 2,
          berthName: 'S-OIL G-2',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: null,
          actualDateDeparture: null,
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: null,
          actualDateDepartureUTC: null,
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'Pending updates',
          isTSBerth: false,
          _id: '67079549b2d73f00485a8a0b',
          arCargoes: [
            {
              cargoId: 195501,
              cargo: 'aramcoULTRA 3',
              charterer: 'S-OIL CORPORATION',
              companyFleet: '100000497__STJS',
              broker_fleet: ['__STJS'],
              oblNum: '20',
              func: 'L',
              _id: '67079549b2d73f00485a8a0c',
            },
            {
              cargoId: 195502,
              cargo: 'aramcoULTRA 3',
              charterer: 'S-OIL CORPORATION',
              companyFleet: '100000497__STJS',
              broker_fleet: [],
              oblNum: '21',
              func: 'L',
              _id: '67079549b2d73f00485a8a0d',
            },
            {
              cargoId: 195503,
              cargo: 'aramcoULTRA 4',
              charterer: 'S-OIL CORPORATION',
              companyFleet: '100000497__STJS',
              broker_fleet: [],
              oblNum: '22',
              func: 'L',
              _id: '67079549b2d73f00485a8a0e',
            },
            {
              cargoId: 195504,
              cargo: 'aramcoULTRA 4',
              charterer: 'S-OIL CORPORATION',
              companyFleet: '100000497__STJS',
              broker_fleet: [],
              oblNum: '23',
              func: 'L',
              _id: '67079549b2d73f00485a8a0f',
            },
            {
              cargoId: 195505,
              cargo: 'aramcoULTRA 8',
              charterer: 'S-OIL CORPORATION',
              companyFleet: '100000497__STJS',
              broker_fleet: [],
              oblNum: '25',
              func: 'L',
              _id: '67079549b2d73f00485a8a10',
            },
          ],
          sortDate: '+275760-09-13T00:00:00.000Z',
        },
      ],
      dtETA: '2024-10-07T10:12:00.000Z',
      status: 'Pending updates',
      vesVoy: 'SLTUS__79__STJS',
      vslCode: 'SLTUS',
      vslName: 'STOLT LOTUS',
      voyNo: 79,
      seqNo: 4700,
      portNo: 8043,
      portName: 'ULSAN',
      dtETD: '2024-10-13T13:00:00.000Z',
      country: 'KOREA, REPUBLIC',
      hasPendingStatus: true,
    },
    {
      _id: '66f671c478824c8dfbef7d1c',
      arBerths: [
        {
          berthSeqNo: 2,
          berthName: 'ITC1',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: '2024-10-09T07:36:00.000Z',
          actualDateDeparture: null,
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: '2024-10-09T12:36:00.000Z',
          actualDateDepartureUTC: null,
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'Pending updates',
          isTSBerth: false,
          _id: '670732d1ef16b60049915631',
          arCargoes: [
            {
              cargoId: 23510,
              cargo: 'XYLENE-M',
              charterer: 'QUIMICA DELTA SA -',
              companyFleet: '100007715__SNICS',
              broker_fleet: ['NETCO__SNICS'],
              oblNum: '335',
              func: 'L',
              _id: '670732d1ef16b60049915632',
            },
          ],
          sortDate: '2024-10-09T07:36:00.000Z',
        },
        {
          berthSeqNo: 1,
          berthName: 'VOPAK 2',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: null,
          actualDateDeparture: null,
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: null,
          actualDateDepartureUTC: null,
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'Pending updates',
          isTSBerth: false,
          _id: '670732d1ef16b60049915629',
          arCargoes: [
            {
              cargoId: 23503,
              cargo: 'ACETONE',
              charterer: 'BRENNTAG- SOUND',
              companyFleet: '100001622__SNICS',
              broker_fleet: ['SPI MARINE USA INC__SNICS'],
              oblNum: '300',
              func: 'L',
              _id: '670732d1ef16b6004991562a',
            },
            {
              cargoId: 23504,
              cargo: 'EHC 45',
              charterer: 'QUIMICA DELTA SA -',
              companyFleet: '100007715__SNICS',
              broker_fleet: ['NETCO__SNICS'],
              oblNum: '305',
              func: 'L',
              _id: '670732d1ef16b6004991562b',
            },
            {
              cargoId: 23505,
              cargo: 'EHC 65',
              charterer: 'QUIMICA DELTA SA -',
              companyFleet: '100007715__SNICS',
              broker_fleet: ['NETCO__SNICS'],
              oblNum: '310',
              func: 'L',
              _id: '670732d1ef16b6004991562c',
            },
            {
              cargoId: 23506,
              cargo: 'AMERICAS CORE 600',
              charterer: 'QUIMICA DELTA SA -',
              companyFleet: '100007715__SNICS',
              broker_fleet: ['NETCO__SNICS'],
              oblNum: '315',
              func: 'L',
              _id: '670732d1ef16b6004991562d',
            },
            {
              cargoId: 23507,
              cargo: 'SN 330',
              charterer: 'QUIMICA DELTA SA -',
              companyFleet: '100007715__SNICS',
              broker_fleet: ['NETCO__SNICS'],
              oblNum: '320',
              func: 'L',
              _id: '670732d1ef16b6004991562e',
            },
            {
              cargoId: 23508,
              cargo: 'AMERICAS CORE 100',
              charterer: 'QUIMICA DELTA SA -',
              companyFleet: '100007715__SNICS',
              broker_fleet: ['NETCO__SNICS'],
              oblNum: '325',
              func: 'L',
              _id: '670732d1ef16b6004991562f',
            },
            {
              cargoId: 23509,
              cargo: 'AMERICAS CORE 150',
              charterer: 'QUIMICA DELTA SA -',
              companyFleet: '100007715__SNICS',
              broker_fleet: ['NETCO__SNICS'],
              oblNum: '330',
              func: 'L',
              _id: '670732d1ef16b60049915630',
            },
          ],
          sortDate: '+275760-09-13T00:00:00.000Z',
        },
      ],
      dtETA: '2024-10-07T11:54:00.000Z',
      status: 'Pending updates',
      vesVoy: 'SFLAM__393__SNICS',
      vslCode: 'SFLAM',
      vslName: 'STOLT FLAMENCO',
      voyNo: 393,
      seqNo: 200,
      portNo: 187,
      portName: 'HOUSTON',
      dtETD: '2024-10-11T10:28:00.000Z',
      country: 'UNITED STATES',
      hasPendingStatus: true,
    },
    {
      _id: '66b466443c8ca9005ce2cfae',
      arBerths: [
        {
          berthSeqNo: 2,
          berthName: 'MBAO',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: '2024-10-07T14:10:00.000Z',
          actualDateDeparture: null,
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: '2024-10-07T14:10:00.000Z',
          actualDateDepartureUTC: null,
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'Pending updates',
          isTSBerth: false,
          _id: '6704f2469812220049aa10fd',
          arCargoes: [
            {
              cargoId: 194818,
              cargo: 'PHOSPHORIC ACID',
              charterer: 'INDUSTRIES CHIMIQUES DUE SENEGAL',
              companyFleet: '100001453__STJS',
              broker_fleet: [],
              oblNum: '200',
              func: 'L',
              _id: '6704f2469812220049aa10fe',
            },
          ],
          sortDate: '2024-10-07T14:10:00.000Z',
        },
      ],
      dtETA: '2024-10-07T12:18:00.000Z',
      status: 'Pending updates',
      vesVoy: 'SSNEL__86__STJS',
      vslCode: 'SSNEL',
      vslName: 'STOLT SNELAND',
      voyNo: 86,
      seqNo: 200,
      portNo: 117,
      portName: 'DAKAR',
      dtETD: '2024-10-10T14:10:00.000Z',
      country: 'SENEGAL',
      hasPendingStatus: true,
    },
    {
      _id: '66b466483c8ca9005ce2dd99',
      arBerths: [
        {
          berthSeqNo: 3,
          berthName: 'B34',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: '2024-10-08T16:48:00.000Z',
          actualDateDeparture: null,
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: '2024-10-08T13:48:00.000Z',
          actualDateDepartureUTC: null,
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'Pending updates',
          isTSBerth: false,
          _id: '67077924ef16b60049986574',
          arCargoes: [
            {
              cargoId: 194559,
              cargo: 'SPECFLEX NC 632',
              charterer: 'DOW SAUDI ARABIA PRODUCT MARKETI',
              companyFleet: '100007583__STJS',
              broker_fleet: ['CLARKSON DMCC__STJS'],
              oblNum: '300',
              func: 'L',
              _id: '67077924ef16b60049986575',
            },
            {
              cargoId: 194561,
              cargo: 'DEA',
              charterer: 'DOW SAUDI ARABIA PRODUCT MARKETI',
              companyFleet: '100007583__STJS',
              broker_fleet: ['CLARKSON DMCC__STJS'],
              oblNum: '310',
              func: 'L',
              _id: '67077924ef16b60049986576',
            },
            {
              cargoId: 194562,
              cargo: 'MEA',
              charterer: 'DOW SAUDI ARABIA PRODUCT MARKETI',
              companyFleet: '100007583__STJS',
              broker_fleet: ['CLARKSON DMCC__STJS'],
              oblNum: '315',
              func: 'L',
              _id: '67077924ef16b60049986577',
            },
            {
              cargoId: 194563,
              cargo: 'TEA 99%',
              charterer: 'DOW SAUDI ARABIA PRODUCT MARKETI',
              companyFleet: '100007583__STJS',
              broker_fleet: ['CLARKSON DMCC__STJS'],
              oblNum: '320',
              func: 'L',
              _id: '67077924ef16b60049986578',
            },
            {
              cargoId: 194564,
              cargo: 'TEA 99%',
              charterer: 'DOW SAUDI ARABIA PRODUCT MARKETI',
              companyFleet: '100007583__STJS',
              broker_fleet: ['CLARKSON DMCC__STJS'],
              oblNum: '321',
              func: 'L',
              _id: '67077924ef16b60049986579',
            },
            {
              cargoId: 194566,
              cargo: 'BUTYL CELLOSOLVE',
              charterer: 'DOW SAUDI ARABIA PRODUCT MARKETI',
              companyFleet: '100007583__STJS',
              broker_fleet: ['CLARKSON DMCC__STJS'],
              oblNum: '330',
              func: 'L',
              _id: '67077924ef16b6004998657a',
            },
            {
              cargoId: 194567,
              cargo: 'VORANATE T-80 E TYPE I',
              charterer: 'DOW SAUDI ARABIA PRODUCT MARKETI',
              companyFleet: '100007583__STJS',
              broker_fleet: ['CLARKSON DMCC__STJS'],
              oblNum: '335',
              func: 'L',
              _id: '67077924ef16b6004998657b',
            },
            {
              cargoId: 194568,
              cargo: 'VORANATE T 80',
              charterer: 'DOW SAUDI ARABIA PRODUCT MARKETI',
              companyFleet: '100007583__STJS',
              broker_fleet: ['CLARKSON DMCC__STJS'],
              oblNum: '340',
              func: 'L',
              _id: '67077924ef16b6004998657c',
            },
          ],
          sortDate: '2024-10-08T16:48:00.000Z',
        },
        {
          berthSeqNo: 2,
          berthName: 'B32',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: null,
          actualDateDeparture: null,
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: null,
          actualDateDepartureUTC: null,
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'Pending updates',
          isTSBerth: false,
          _id: '67077924ef16b60049986572',
          arCargoes: [
            {
              cargoId: 195008,
              cargo: 'MMA',
              charterer: 'DSV AIR & SEA LTD',
              companyFleet: '100009769__STJS',
              broker_fleet: ['CLARKSON SPECIALISED PRODUCTS__STJS'],
              oblNum: '355',
              func: 'L',
              _id: '67077924ef16b60049986573',
            },
          ],
          sortDate: '+275760-09-13T00:00:00.000Z',
        },
      ],
      dtETA: '2024-10-08T07:00:00.000Z',
      status: 'Pending updates',
      vesVoy: 'SCALL__53__STJS',
      vslCode: 'SCALL',
      vslName: 'STOLT CALLUNA',
      voyNo: 53,
      seqNo: 300,
      portNo: 1000081,
      portName: 'JUBAIL INDUSTRIAL',
      dtETD: '2024-10-11T15:48:00.000Z',
      country: 'SAUDI ARABIA',
      hasPendingStatus: true,
    },
    {
      _id: '66f5a5dbb3d1b075d41b065d',
      arBerths: [
        {
          berthSeqNo: 2,
          berthName: 'LIQUID PIER',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: null,
          actualDateDeparture: null,
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: null,
          actualDateDepartureUTC: null,
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'Pending updates',
          isTSBerth: false,
          _id: '6706bb4def16b6004985d739',
          arCargoes: [
            {
              cargoId: 23487,
              cargo: 'MOLASSES',
              charterer: 'PETER CREMER GMBH',
              companyFleet: '100008222__SNICS',
              broker_fleet: ['RIVERSIDE TANKER CHARTERING LTD__SNICS'],
              oblNum: '600',
              func: 'L',
              _id: '6706bb4def16b6004985d73a',
            },
          ],
          sortDate: '+275760-09-13T00:00:00.000Z',
        },
      ],
      dtETA: '2024-10-08T07:48:00.000Z',
      status: 'Pending updates',
      vesVoy: 'STUCA__25__SNICS',
      vslCode: 'STUCA',
      vslName: 'STOLT TUCAN',
      voyNo: 25,
      seqNo: 200,
      portNo: 8163,
      portName: 'PUERTO CORTES',
      dtETD: '2024-10-14T08:36:00.000Z',
      country: 'HONDURAS',
      hasPendingStatus: true,
    },
    {
      _id: '66b4664c3c8ca9005ce2e670',
      arBerths: [
        {
          berthSeqNo: 2,
          berthName: 'CHJANG 2',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: null,
          actualDateDeparture: null,
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: null,
          actualDateDepartureUTC: null,
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'Pending updates',
          isTSBerth: false,
          _id: '6704f2459812220049aa10b2',
          arCargoes: [
            {
              cargoId: 193627,
              cargo: 'MEG',
              charterer: 'MITSUBISHI CORPORATION',
              companyFleet: '100006374__STJS',
              broker_fleet: ['SPI MARINE HOUSTON INC__STJS'],
              oblNum: '500',
              func: 'D',
              _id: '6704f2459812220049aa10b3',
            },
          ],
          sortDate: '+275760-09-13T00:00:00.000Z',
        },
      ],
      dtETA: '2024-10-08T12:00:00.000Z',
      status: 'Pending updates',
      vesVoy: 'BOGNT__57__STJS',
      vslCode: 'BOGNT',
      vslName: 'BOCHEM GHENT',
      voyNo: 57,
      seqNo: 900,
      portNo: 20285,
      portName: 'ZHANGJIAGANG',
      dtETD: '2024-10-13T17:00:00.000Z',
      country: 'CHINA',
      hasPendingStatus: true,
    },
    {
      _id: '66ebc9a1f5b7bb5a309678fb',
      arBerths: [
        {
          berthSeqNo: 4,
          berthName: 'QUAY',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: null,
          actualDateDeparture: null,
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: null,
          actualDateDepartureUTC: null,
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'Pending updates',
          isTSBerth: false,
          _id: '670747eaef16b60049937a84',
          arCargoes: [
            {
              cargoId: 33821,
              cargo: 'PHOSPHORIC ACID',
              charterer: 'SUN INTERNATIONAL FZE',
              companyFleet: '100004147__SNAPS',
              broker_fleet: ['STEEM1960 STEENSLAND SHIPBRKS AS__SNAPS'],
              oblNum: '10',
              func: 'L',
              _id: '670747eaef16b60049937a85',
            },
          ],
          sortDate: '+275760-09-13T00:00:00.000Z',
        },
      ],
      dtETA: '2024-10-08T14:00:00.000Z',
      status: 'Pending updates',
      vesVoy: 'SSUIS__239__SNAPS',
      vslCode: 'SSUIS',
      vslName: 'STOLT SUISEN',
      voyNo: 239,
      seqNo: 600,
      portNo: 20691,
      portName: 'QINZHOU',
      dtETD: '2024-10-10T11:30:00.000Z',
      country: 'CHINA',
      hasPendingStatus: true,
    },
    {
      _id: '66c4bb30033c1ea5283fce9c',
      arBerths: [
        {
          berthSeqNo: 1,
          berthName: 'DOVER',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: '2024-10-08T23:45:00.000Z',
          actualDateDeparture: '2024-10-09T21:51:00.000Z',
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: '2024-10-08T16:45:00.000Z',
          actualDateDepartureUTC: '2024-10-09T14:51:00.000Z',
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'No action required',
          isTSBerth: false,
          _id: '670740e3ef16b6004992bfb8',
          arCargoes: [
            {
              cargoId: 194196,
              cargo: 'LECITHIN (SOYABEAN)',
              charterer: 'AMSTERDAM SHIPPING SERVICES BV',
              companyFleet: '100008949__STJS',
              broker_fleet: ['RVB SHIPBROKERS BV__STJS'],
              oblNum: '540',
              func: 'D',
              _id: '670740e3ef16b6004992bfb9',
            },
          ],
          sortDate: '2024-10-08T23:45:00.000Z',
        },
      ],
      dtETA: '2024-10-08T16:30:00.000Z',
      status: null,
      vesVoy: 'SBAS__118__STJS',
      vslCode: 'SBAS',
      vslName: 'STOLT BASUTO',
      voyNo: 118,
      seqNo: 7200,
      portNo: 30021,
      portName: 'MERAK',
      dtETD: '2024-10-10T19:15:00.000Z',
      country: 'INDONESIA',
      hasPendingStatus: false,
    },
    {
      _id: '670538934ce5c79b48e34eec',
      arBerths: [
        {
          berthSeqNo: 1,
          berthName: 'KM GLT',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: null,
          actualDateDeparture: null,
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: null,
          actualDateDepartureUTC: null,
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'Pending updates',
          isTSBerth: false,
          _id: '6706f38bef16b600498bbb39',
          arCargoes: [
            {
              cargoId: 195752,
              cargo: 'MDI',
              charterer: 'HUNTSMAN HOLLAND',
              companyFleet: '100004367__STJS',
              broker_fleet: ['SPI MARINE USA INC__STJS'],
              oblNum: '595',
              func: 'L',
              _id: '6706f38bef16b600498bbb3a',
            },
          ],
          sortDate: '+275760-09-13T00:00:00.000Z',
        },
      ],
      dtETA: '2024-10-09T05:37:00.000Z',
      status: 'Pending updates',
      vesVoy: 'SINVE__135__STJS',
      vslCode: 'SINVE',
      vslName: 'STOLT INVENTION',
      voyNo: 135,
      seqNo: 1400,
      portNo: 36,
      portName: 'BATON ROUGE',
      dtETD: '2024-10-10T17:37:00.000Z',
      country: 'UNITED STATES',
      hasPendingStatus: true,
    },
    {
      _id: '66de7072978c30adaa01259e',
      arBerths: [
        {
          berthSeqNo: 1,
          berthName: 'QUAY',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: null,
          actualDateDeparture: null,
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: null,
          actualDateDepartureUTC: null,
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'Pending updates',
          isTSBerth: false,
          _id: '67074ef5ef16b60049943fc4',
          arCargoes: [
            {
              cargoId: 33743,
              cargo: 'HEXENE-1',
              charterer: 'INTEGRA PETROCHEMICALS',
              companyFleet: '100007144__SNAPS',
              broker_fleet: ['CLARKSON PLATOU ASIA PTE LTD__SNAPS'],
              oblNum: '160',
              func: 'D',
              _id: '67074ef5ef16b60049943fc5',
            },
          ],
          sortDate: '+275760-09-13T00:00:00.000Z',
        },
      ],
      dtETA: '2024-10-09T11:00:00.000Z',
      status: 'Pending updates',
      vesVoy: 'SHAGI__79__SNAPS',
      vslCode: 'SHAGI',
      vslName: 'STOLT HAGI',
      voyNo: 79,
      seqNo: 28500,
      portNo: 29397,
      portName: 'TANJUNG BIN',
      dtETD: '2024-10-10T13:00:00.000Z',
      country: 'MALAYSIA',
      hasPendingStatus: true,
    },
    {
      _id: '66e345ef4f378a96683b7889',
      arBerths: [
        {
          berthSeqNo: 2,
          berthName: 'VOPAK',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: '2024-10-09T13:15:00.000Z',
          actualDateDeparture: null,
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: '2024-10-09T19:15:00.000Z',
          actualDateDepartureUTC: null,
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'Pending updates',
          isTSBerth: false,
          _id: '670747e9ef16b60049937a6d',
          arCargoes: [
            {
              cargoId: 23426,
              cargo: 'STYRENE MONOMER',
              charterer: 'RESIRENE S.A.',
              companyFleet: '100001295__SNICS',
              broker_fleet: [],
              oblNum: '625',
              func: 'D',
              _id: '670747e9ef16b60049937a6e',
            },
          ],
          sortDate: '2024-10-09T13:15:00.000Z',
        },
        {
          berthSeqNo: 1,
          berthName: 'STYROLUTION',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: null,
          actualDateDeparture: null,
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: null,
          actualDateDepartureUTC: null,
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'Pending updates',
          isTSBerth: false,
          _id: '670747e9ef16b60049937a68',
          arCargoes: [
            {
              cargoId: 23409,
              cargo: 'STYRENE MONOMER',
              charterer: 'STYROLUTION AMERICA - QUIN',
              companyFleet: '100002415__SNICS',
              broker_fleet: ['QUINCANNON__SNICS'],
              oblNum: '600',
              func: 'D',
              _id: '670747e9ef16b60049937a69',
            },
            {
              cargoId: 23410,
              cargo: 'ACRYLONITRILE',
              charterer: 'STYROLUTION AMERICA - QUIN',
              companyFleet: '100002415__SNICS',
              broker_fleet: ['QUINCANNON__SNICS'],
              oblNum: '605',
              func: 'D',
              _id: '670747e9ef16b60049937a6a',
            },
            {
              cargoId: 23411,
              cargo: 'STYRENE MONOMER',
              charterer: 'STYROPEK MEXICO SA DE CV',
              companyFleet: '100005198__SNICS',
              broker_fleet: ['QUINCANNON__SNICS'],
              oblNum: '610',
              func: 'D',
              _id: '670747e9ef16b60049937a6b',
            },
            {
              cargoId: 23446,
              cargo: 'BUTYL ACRYLATE',
              charterer: 'STYROLUTION AMERICA - QUIN',
              companyFleet: '100002415__SNICS',
              broker_fleet: ['QUINCANNON__SNICS'],
              oblNum: '615',
              func: 'D',
              _id: '670747e9ef16b60049937a6c',
            },
          ],
          sortDate: '+275760-09-13T00:00:00.000Z',
        },
        {
          berthSeqNo: 3,
          berthName: 'OTM',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: null,
          actualDateDeparture: null,
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: null,
          actualDateDepartureUTC: null,
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'Pending updates',
          isTSBerth: false,
          _id: '670747e9ef16b60049937a6f',
          arCargoes: [
            {
              cargoId: 23473,
              cargo: 'ETHYLENE GLYCOL',
              charterer: 'TEREFTALATOS - ALPEK',
              companyFleet: '100008320__SNICS',
              broker_fleet: ['SSY CHEMICALS__SNICS'],
              oblNum: '620',
              func: 'D',
              _id: '670747e9ef16b60049937a70',
            },
          ],
          sortDate: '+275760-09-13T00:00:00.000Z',
        },
      ],
      dtETA: '2024-10-09T11:40:00.000Z',
      status: 'Pending updates',
      vesVoy: 'SSEA__189__SNICS',
      vslCode: 'SSEA',
      vslName: 'STOLT SEA',
      voyNo: 189,
      seqNo: 400,
      portNo: 905,
      portName: 'ALTAMIRA',
      dtETD: '2024-10-12T13:15:00.000Z',
      country: 'MEXICO',
      hasPendingStatus: true,
    },
    {
      _id: '66b466483c8ca9005ce2db35',
      arBerths: [
        {
          berthSeqNo: 1,
          berthName: 'No.1 Mari',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: null,
          actualDateDeparture: null,
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: null,
          actualDateDepartureUTC: null,
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'Pending updates',
          isTSBerth: false,
          _id: '67069822ef16b60049828c0d',
          arCargoes: [
            {
              cargoId: 33721,
              cargo: 'UCO TRIGLYCERIDES',
              charterer: 'NESTE SHIPPING OY',
              companyFleet: '100002075__SNAPS',
              broker_fleet: [],
              oblNum: '150',
              func: 'L',
              _id: '67069822ef16b60049828c0e',
            },
            {
              cargoId: 33722,
              cargo: 'TALLOW',
              charterer: 'NESTE SHIPPING OY',
              companyFleet: '100002075__SNAPS',
              broker_fleet: [],
              oblNum: '160',
              func: 'L',
              _id: '67069822ef16b60049828c0f',
            },
            {
              cargoId: 33859,
              cargo: 'TALLOW',
              charterer: 'NESTE SHIPPING OY',
              companyFleet: '100002075__SNAPS',
              broker_fleet: [],
              oblNum: '180',
              func: 'L',
              _id: '67069822ef16b60049828c10',
            },
          ],
          sortDate: '+275760-09-13T00:00:00.000Z',
        },
      ],
      dtETA: '2024-10-09T15:00:00.000Z',
      status: 'Pending updates',
      vesVoy: 'SMOMI__137__SNAPS',
      vslCode: 'SMOMI',
      vslName: 'STOLT MOMIJI',
      voyNo: 137,
      seqNo: 3200,
      portNo: 3381,
      portName: 'MELBOURNE',
      dtETD: '2024-10-11T21:44:32.000Z',
      country: 'AUSTRALIA',
      hasPendingStatus: true,
    },
    {
      _id: '66c4f3716ffb2956a4aae606',
      arBerths: [
        {
          berthSeqNo: 2,
          berthName: 'MINERA ALUMBRERA',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: null,
          actualDateDeparture: null,
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: null,
          actualDateDepartureUTC: null,
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'Pending updates',
          isTSBerth: false,
          _id: '670716b2ef16b600498f43dd',
          arCargoes: [
            {
              cargoId: 194158,
              cargo: 'SOYABEAN OIL',
              charterer: 'BUNGE NORTH AMERICA INC',
              companyFleet: '100010169__STJS',
              broker_fleet: ['BRAEMAR ACM SHIPBROKING (USA)__STJS'],
              oblNum: '350',
              func: 'L',
              _id: '670716b2ef16b600498f43de',
            },
          ],
          sortDate: '+275760-09-13T00:00:00.000Z',
        },
        {
          berthSeqNo: 5,
          berthName: 'NOURYON',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: null,
          actualDateDeparture: null,
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: null,
          actualDateDepartureUTC: null,
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'Pending updates',
          isTSBerth: false,
          _id: '670716b2ef16b600498f43df',
          arCargoes: [
            {
              cargoId: 194159,
              cargo: 'SOYABEAN OIL',
              charterer: 'BUNGE NORTH AMERICA INC',
              companyFleet: '100010169__STJS',
              broker_fleet: ['BRAEMAR ACM SHIPBROKING (USA)__STJS'],
              oblNum: '360',
              func: 'L',
              _id: '670716b2ef16b600498f43e0',
            },
            {
              cargoId: 194401,
              cargo: 'SUNFLOWER OIL',
              charterer: 'BUNGE NORTH AMERICA INC',
              companyFleet: '100010169__STJS',
              broker_fleet: ['BRAEMAR ACM SHIPBROKING (USA)__STJS'],
              oblNum: '320',
              func: 'L',
              _id: '670716b2ef16b600498f43e1',
            },
            {
              cargoId: 194960,
              cargo: 'CANOLA OIL',
              charterer: 'BUNGE NORTH AMERICA INC',
              companyFleet: '100010169__STJS',
              broker_fleet: ['BRAEMAR ACM SHIPBROKING (USA)__STJS'],
              oblNum: '340',
              func: 'L',
              _id: '670716b2ef16b600498f43e2',
            },
          ],
          sortDate: '+275760-09-13T00:00:00.000Z',
        },
        {
          berthSeqNo: 7,
          berthName: 'COFCO',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: null,
          actualDateDeparture: null,
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: null,
          actualDateDepartureUTC: null,
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'Pending updates',
          isTSBerth: false,
          _id: '670716b2ef16b600498f43e3',
          arCargoes: [
            {
              cargoId: 195346,
              cargo: 'SUNFLOWER OIL',
              charterer: 'BUNGE NORTH AMERICA INC',
              companyFleet: '100010169__STJS',
              broker_fleet: [],
              oblNum: '321',
              func: 'L',
              _id: '670716b2ef16b600498f43e4',
            },
          ],
          sortDate: '+275760-09-13T00:00:00.000Z',
        },
        {
          berthSeqNo: 9,
          berthName: 'COFCO',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: null,
          actualDateDeparture: null,
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: null,
          actualDateDepartureUTC: null,
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'Pending updates',
          isTSBerth: false,
          _id: '670716b2ef16b600498f43e5',
          arCargoes: [
            {
              cargoId: 194961,
              cargo: 'SUNFLOWER OIL',
              charterer: 'BUNGE NORTH AMERICA INC',
              companyFleet: '100010169__STJS',
              broker_fleet: ['BRAEMAR ACM SHIPBROKING (USA)__STJS'],
              oblNum: '310',
              func: 'L',
              _id: '670716b2ef16b600498f43e6',
            },
          ],
          sortDate: '+275760-09-13T00:00:00.000Z',
        },
      ],
      dtETA: '2024-10-09T15:00:00.000Z',
      status: 'Pending updates',
      vesVoy: 'SOCEL__110__STJS',
      vslCode: 'SOCEL',
      vslName: 'STOLT OCELOT',
      voyNo: 110,
      seqNo: 200,
      portNo: 404,
      portName: 'SAN LORENZO-SAN MARTIN',
      dtETD: '2024-10-12T16:00:00.000Z',
      country: 'ARGENTINA',
      hasPendingStatus: true,
    },
    {
      _id: '66b466453c8ca9005ce2d34d',
      arBerths: [
        {
          berthSeqNo: 1,
          berthName: 'Poliport Jetty #1',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: null,
          actualDateDeparture: null,
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: null,
          actualDateDepartureUTC: null,
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'Pending updates',
          isTSBerth: false,
          _id: '6706bb4eef16b6004985d768',
          arCargoes: [
            {
              cargoId: 193859,
              cargo: 'MEG',
              charterer: 'EQUATE PETROCHEMICAL CO KSCC',
              companyFleet: '100010274__STJS',
              broker_fleet: ['CLARKSON U.S.A.__STJS'],
              oblNum: '6',
              func: 'D',
              _id: '6706bb4eef16b6004985d769',
            },
            {
              cargoId: 193860,
              cargo: 'DEG',
              charterer: 'EQUATE PETROCHEMICAL CO KSCC',
              companyFleet: '100010274__STJS',
              broker_fleet: ['CLARKSON U.S.A.__STJS'],
              oblNum: '11',
              func: 'D',
              _id: '6706bb4eef16b6004985d76a',
            },
          ],
          sortDate: '+275760-09-13T00:00:00.000Z',
        },
      ],
      dtETA: '2024-10-09T16:46:09.000Z',
      status: 'Pending updates',
      vesVoy: 'SARGO__26__STJS',
      vslCode: 'SARGO',
      vslName: 'STOLT ARGON',
      voyNo: 26,
      seqNo: 900,
      portNo: 28315,
      portName: 'GEBZE',
      dtETD: '2024-10-11T04:46:09.000Z',
      country: 'TURKEY',
      hasPendingStatus: true,
    },
    {
      _id: '66e4825863a22761240f19b3',
      arBerths: [
        {
          berthSeqNo: 1,
          berthName: 'RELISA 32E',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: null,
          actualDateDeparture: null,
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: null,
          actualDateDepartureUTC: null,
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'Pending updates',
          isTSBerth: false,
          _id: '670747ebef16b60049937a93',
          arCargoes: [
            {
              cargoId: 195064,
              cargo: 'CAUSTIC SODA 50%',
              charterer: 'MITSUI & CO LTD, TOKYO - NETCO',
              companyFleet: '100003429__STJS',
              broker_fleet: ['NETCO__STJS'],
              oblNum: '105',
              func: 'D',
              _id: '670747ebef16b60049937a94',
            },
          ],
          sortDate: '+275760-09-13T00:00:00.000Z',
        },
      ],
      dtETA: '2024-10-09T16:54:00.000Z',
      status: 'Pending updates',
      vesVoy: 'SSURF__135__STJS',
      vslCode: 'SSURF',
      vslName: 'STOLT SURF',
      voyNo: 135,
      seqNo: 500,
      portNo: 2024,
      portName: 'BARCELONA',
      dtETD: '2024-10-10T19:54:00.000Z',
      country: 'SPAIN',
      hasPendingStatus: true,
    },
    {
      _id: '66e899ff13ed66d71eaf9ffb',
      arBerths: [
        {
          berthSeqNo: 2,
          berthName: 'VOPAK',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: null,
          actualDateDeparture: null,
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: null,
          actualDateDepartureUTC: null,
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'Pending updates',
          isTSBerth: false,
          _id: '670747e9ef16b60049937a72',
          arCargoes: [
            {
              cargoId: 23480,
              cargo: 'METHANOL',
              charterer: 'WATERFRONT SHIPPING COMPANY LTD',
              companyFleet: '100005149__SNICS',
              broker_fleet: ['NETCO__SNICS'],
              oblNum: '400',
              func: 'D',
              _id: '670747e9ef16b60049937a73',
            },
            {
              cargoId: 23482,
              cargo: 'METHANOL',
              charterer: 'QUIMICA DELTA SA -',
              companyFleet: '100007715__SNICS',
              broker_fleet: ['NETCO__SNICS'],
              oblNum: '405',
              func: 'D',
              _id: '670747e9ef16b60049937a74',
            },
            {
              cargoId: 23483,
              cargo: 'XYLENE-M',
              charterer: 'QUIMICA DELTA SA -',
              companyFleet: '100007715__SNICS',
              broker_fleet: ['NETCO__SNICS'],
              oblNum: '410',
              func: 'D',
              _id: '670747e9ef16b60049937a75',
            },
          ],
          sortDate: '+275760-09-13T00:00:00.000Z',
        },
        {
          berthSeqNo: 3,
          berthName: 'OTM',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: null,
          actualDateDeparture: null,
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: null,
          actualDateDepartureUTC: null,
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'Pending updates',
          isTSBerth: false,
          _id: '670747e9ef16b60049937a76',
          arCargoes: [
            {
              cargoId: 23484,
              cargo: 'TOLUENE',
              charterer: 'QUIMICA DELTA SA -',
              companyFleet: '100007715__SNICS',
              broker_fleet: ['NETCO__SNICS'],
              oblNum: '415',
              func: 'D',
              _id: '670747e9ef16b60049937a77',
            },
          ],
          sortDate: '+275760-09-13T00:00:00.000Z',
        },
        {
          berthSeqNo: 4,
          berthName: 'STYROLUTION',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: null,
          actualDateDeparture: null,
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: null,
          actualDateDepartureUTC: null,
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'Pending updates',
          isTSBerth: false,
          _id: '670747e9ef16b60049937a78',
          arCargoes: [
            {
              cargoId: 23501,
              cargo: 'STYRENE MONOMER',
              charterer: 'STYROPEK MEXICO SA DE CV',
              companyFleet: '100005198__SNICS',
              broker_fleet: ['QUINCANNON__SNICS'],
              oblNum: '420',
              func: 'D',
              _id: '670747e9ef16b60049937a79',
            },
          ],
          sortDate: '+275760-09-13T00:00:00.000Z',
        },
      ],
      dtETA: '2024-10-09T18:42:00.000Z',
      status: 'Pending updates',
      vesVoy: 'SKIRI__215__SNICS',
      vslCode: 'SKIRI',
      vslName: 'STOLT KIRI',
      voyNo: 215,
      seqNo: 200,
      portNo: 905,
      portName: 'ALTAMIRA',
      dtETD: '2024-10-13T18:42:00.000Z',
      country: 'MEXICO',
      hasPendingStatus: true,
    },
    {
      _id: '66b466483c8ca9005ce2ddcf',
      arBerths: [
        {
          berthSeqNo: 1,
          berthName: 'TC-66',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: null,
          actualDateDeparture: null,
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: null,
          actualDateDepartureUTC: null,
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'Pending updates',
          isTSBerth: false,
          _id: '670593fa3e08c900498d84c7',
          arCargoes: [
            {
              cargoId: 194492,
              cargo: 'TRIETHANOLAMINE 99%',
              charterer: "DOW INT'L",
              companyFleet: '100001898__STJS',
              broker_fleet: ['QUINCANNON__STJS'],
              oblNum: '115',
              func: 'L',
              _id: '670593fa3e08c900498d84c8',
            },
            {
              cargoId: 194493,
              cargo: 'TRIETHANOLAMINE 99%',
              charterer: "DOW INT'L",
              companyFleet: '100001898__STJS',
              broker_fleet: ['QUINCANNON__STJS'],
              oblNum: '116',
              func: 'L',
              _id: '670593fa3e08c900498d84c9',
            },
            {
              cargoId: 194494,
              cargo: 'BUTYL CELLOSOLVE',
              charterer: "DOW INT'L",
              companyFleet: '100001898__STJS',
              broker_fleet: ['QUINCANNON__STJS'],
              oblNum: '120',
              func: 'L',
              _id: '670593fa3e08c900498d84ca',
            },
            {
              cargoId: 194495,
              cargo: 'BUTYL CELLOSOLVE',
              charterer: "DOW INT'L",
              companyFleet: '100001898__STJS',
              broker_fleet: ['QUINCANNON__STJS'],
              oblNum: '121',
              func: 'L',
              _id: '670593fa3e08c900498d84cb',
            },
            {
              cargoId: 194499,
              cargo: 'PROPANOL',
              charterer: "DOW INT'L",
              companyFleet: '100001898__STJS',
              broker_fleet: ['QUINCANNON__STJS'],
              oblNum: '135',
              func: 'L',
              _id: '670593fa3e08c900498d84cc',
            },
            {
              cargoId: 194799,
              cargo: 'ETHANOLAMINE-M',
              charterer: "DOW INT'L",
              companyFleet: '100001898__STJS',
              broker_fleet: ['QUINCANNON__STJS'],
              oblNum: '185',
              func: 'L',
              _id: '670593fa3e08c900498d84cd',
            },
          ],
          sortDate: '+275760-09-13T00:00:00.000Z',
        },
      ],
      dtETA: '2024-10-09T19:00:00.000Z',
      status: 'Pending updates',
      vesVoy: 'SCOMM__78__STJS',
      vslCode: 'SCOMM',
      vslName: 'STOLT COMMITMENT',
      voyNo: 78,
      seqNo: 300,
      portNo: 450,
      portName: 'TEXAS CITY',
      dtETD: '2024-10-12T07:00:00.000Z',
      country: 'UNITED STATES',
      hasPendingStatus: true,
    },
    {
      _id: '66b466423c8ca9005ce2cc57',
      arBerths: [
        {
          berthSeqNo: 1,
          berthName: 'WINA A2-A3',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: null,
          actualDateDeparture: null,
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: null,
          actualDateDepartureUTC: null,
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'Pending updates',
          isTSBerth: false,
          _id: '6704dd2d9812220049a799b3',
          arCargoes: [
            {
              cargoId: 193838,
              cargo: 'WILMAR STEAR ACID 1892',
              charterer: 'WILMAR - RAFFLES',
              companyFleet: '100002000__STJS',
              broker_fleet: ['RAFFLES CHARTER__STJS'],
              oblNum: '807',
              func: 'L',
              _id: '6704dd2d9812220049a799b4',
            },
            {
              cargoId: 193839,
              cargo: 'FATTY ALCOHOL C-12/16',
              charterer: 'WILMAR - RAFFLES',
              companyFleet: '100002000__STJS',
              broker_fleet: ['RAFFLES CHARTER__STJS'],
              oblNum: '817',
              func: 'L',
              _id: '6704dd2d9812220049a799b5',
            },
            {
              cargoId: 193840,
              cargo: 'GENAMIN LA302D',
              charterer: 'WILMAR - RAFFLES',
              companyFleet: '100002000__STJS',
              broker_fleet: ['RAFFLES CHARTER__STJS'],
              oblNum: '827',
              func: 'L',
              _id: '6704dd2d9812220049a799b6',
            },
            {
              cargoId: 193841,
              cargo: 'WILMAR PA 1698',
              charterer: 'WILMAR - RAFFLES',
              companyFleet: '100002000__STJS',
              broker_fleet: ['RAFFLES CHARTER__STJS'],
              oblNum: '837',
              func: 'L',
              _id: '6704dd2d9812220049a799b7',
            },
            {
              cargoId: 193842,
              cargo: 'WILMAR FAL 0810',
              charterer: 'WILMAR - RAFFLES',
              companyFleet: '100002000__STJS',
              broker_fleet: ['RAFFLES CHARTER__STJS'],
              oblNum: '847',
              func: 'L',
              _id: '6704dd2d9812220049a799b8',
            },
          ],
          sortDate: '+275760-09-13T00:00:00.000Z',
        },
      ],
      dtETA: '2024-10-09T20:00:00.000Z',
      status: 'Pending updates',
      vesVoy: 'SCONC__117__STJS',
      vslCode: 'SCONC',
      vslName: 'STOLT CONCEPT',
      voyNo: 117,
      seqNo: 1900,
      portNo: 3072,
      portName: 'GRESIK',
      dtETD: '2024-10-12T20:00:00.000Z',
      country: 'INDONESIA',
      hasPendingStatus: true,
    },
    {
      _id: '66b466413c8ca9005ce2ca3a',
      arBerths: [
        {
          berthSeqNo: 2,
          berthName: 'TM Terminal E-1 Berth',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: null,
          actualDateDeparture: null,
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: null,
          actualDateDepartureUTC: null,
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'Pending updates',
          isTSBerth: false,
          _id: '670643c442754f004829a49f',
          arCargoes: [
            {
              cargoId: 193103,
              cargo: 'ETHANOL',
              charterer: 'MITSUBISHI CORPORATION',
              companyFleet: '100006374__STJS',
              broker_fleet: ['IFCHOR GALBRAITHS (SWTZRLAND) SA__STJS'],
              oblNum: '150',
              func: 'D',
              _id: '670643c442754f004829a4a0',
            },
          ],
          sortDate: '+275760-09-13T00:00:00.000Z',
        },
      ],
      dtETA: '2024-10-09T22:00:00.000Z',
      status: 'Pending updates',
      vesVoy: 'SSYPR__88__STJS',
      vslCode: 'SSYPR',
      vslName: 'STOLT SYPRESS',
      voyNo: 88,
      seqNo: 700,
      portNo: 3346,
      portName: 'KOBE',
      dtETD: '2024-10-12T22:00:00.000Z',
      country: 'JAPAN',
      hasPendingStatus: true,
    },
    {
      _id: '66da0468df8327b782449848',
      arBerths: [
        {
          berthSeqNo: 2,
          berthName: 'TIT 1',
          estimatedDateArival: '2024-10-12T10:00:00.000Z',
          estimatedDateDeparture: '2024-10-14T18:00:00.000Z',
          actualDateArival: null,
          actualDateDeparture: null,
          estimatedDateArivalUTC: '2024-10-12T04:00:00.000Z',
          estimatedDateDepartureUTC: '2024-10-14T12:00:00.000Z',
          actualDateArivalUTC: null,
          actualDateDepartureUTC: null,
          updatedCount: 1,
          lastUpdatedByPortOps: '2024-10-08T11:15:56.102Z',
          isDeleted: false,
          status: 'Updated',
          isTSBerth: false,
          _id: '67079546b2d73f00485a896c',
          arCargoes: [
            {
              cargoId: 194777,
              cargo: 'ETHANOL, DENATURED',
              charterer: 'KC&A CORPORATION',
              companyFleet: '100008887__STJS',
              broker_fleet: ['BRAEMAR ACM SHIPBROKING (USA)__STJS'],
              oblNum: '300',
              func: 'L',
              _id: '67079546b2d73f00485a896d',
            },
            {
              cargoId: 195026,
              cargo: 'ETHANOL',
              charterer: 'KC&A CORPORATION',
              companyFleet: '100008887__STJS',
              broker_fleet: ['BRAEMAR ACM SHIPBROKING (USA)__STJS'],
              oblNum: '305',
              func: 'L',
              _id: '67079546b2d73f00485a896e',
            },
          ],
          sortDate: '2024-10-12T10:00:00.000Z',
        },
      ],
      dtETA: '2024-10-10T07:00:00.000Z',
      status: 'Updated',
      vesVoy: 'SALM__62__STJS',
      vslCode: 'SALM',
      vslName: 'STOLT ALM',
      voyNo: 62,
      seqNo: 200,
      portNo: 154,
      portName: 'GALVESTON',
      dtETD: '2024-10-14T23:48:00.000Z',
      country: 'UNITED STATES',
      hasPendingStatus: true,
    },
    {
      _id: '66b466423c8ca9005ce2cc9c',
      arBerths: [
        {
          berthSeqNo: 2,
          berthName: 'OJ-II',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: null,
          actualDateDeparture: null,
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: null,
          actualDateDepartureUTC: null,
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'Pending updates',
          isTSBerth: false,
          _id: '67074ef2ef16b60049943f5b',
          arCargoes: [
            {
              cargoId: 192858,
              cargo: 'ARCOL POLYOL 5613',
              charterer: 'COVESTRO LLC',
              companyFleet: '100006777__STJS',
              broker_fleet: [],
              oblNum: '110',
              func: 'D',
              _id: '67074ef2ef16b60049943f5c',
            },
            {
              cargoId: 193112,
              cargo: 'HEXANE',
              charterer: 'TAUBER PETROCHEMICAL CO',
              companyFleet: '100008681__STJS',
              broker_fleet: ['CLARKSON U.S.A.__STJS'],
              oblNum: '200',
              func: 'D',
              _id: '67074ef2ef16b60049943f5d',
            },
            {
              cargoId: 193474,
              cargo: 'EB SOLVENT',
              charterer: 'OXYDE CHEMICAL',
              companyFleet: '100004264__STJS',
              broker_fleet: ['NETCO__STJS'],
              oblNum: '220',
              func: 'D',
              _id: '67074ef2ef16b60049943f5e',
            },
            {
              cargoId: 193802,
              cargo: 'PHENOL',
              charterer: 'TRICON  ENERGY',
              companyFleet: '100001413__STJS',
              broker_fleet: ['NETCO__STJS'],
              oblNum: '215',
              func: 'D',
              _id: '67074ef2ef16b60049943f5f',
            },
          ],
          sortDate: '+275760-09-13T00:00:00.000Z',
        },
      ],
      dtETA: '2024-10-10T08:00:00.000Z',
      status: 'Pending updates',
      vesVoy: 'SEXCL__35__STJS',
      vslCode: 'SEXCL',
      vslName: 'STOLT EXCELLENCE',
      voyNo: 35,
      seqNo: 1100,
      portNo: 3085,
      portName: 'DEENDAYAL',
      dtETD: '2024-10-15T18:52:48.000Z',
      country: 'INDIA',
      hasPendingStatus: true,
    },
    {
      _id: '66b466413c8ca9005ce2cad3',
      arBerths: [
        {
          berthSeqNo: 1,
          berthName: 'ITC RUBIS 1668',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: null,
          actualDateDeparture: null,
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: null,
          actualDateDepartureUTC: null,
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'Pending updates',
          isTSBerth: false,
          _id: '67054dae3e08c9004985e7f0',
          arCargoes: [
            {
              cargoId: 192125,
              cargo: 'ALPHA OLEFIN C-10',
              charterer: 'MUNTAJAT',
              companyFleet: '100006450__STJS',
              broker_fleet: ['EASTPORT MARITIME EUROPE BV__STJS'],
              oblNum: '505',
              func: 'D',
              _id: '67054dae3e08c9004985e7f1',
            },
          ],
          sortDate: '+275760-09-13T00:00:00.000Z',
        },
        {
          berthSeqNo: 2,
          berthName: 'NOORDNATIE 251',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: null,
          actualDateDeparture: null,
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: null,
          actualDateDepartureUTC: null,
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'Pending updates',
          isTSBerth: false,
          _id: '67054dae3e08c9004985e7f2',
          arCargoes: [
            {
              cargoId: 192127,
              cargo: 'Alpha Olefin C12',
              charterer: 'MUNTAJAT',
              companyFleet: '100006450__STJS',
              broker_fleet: ['EASTPORT MARITIME EUROPE BV__STJS'],
              oblNum: '515',
              func: 'D',
              _id: '67054dae3e08c9004985e7f3',
            },
            {
              cargoId: 192128,
              cargo: 'ALPHA OLEFIN C-16',
              charterer: 'MUNTAJAT',
              companyFleet: '100006450__STJS',
              broker_fleet: ['EASTPORT MARITIME EUROPE BV__STJS'],
              oblNum: '520',
              func: 'D',
              _id: '67054dae3e08c9004985e7f4',
            },
            {
              cargoId: 192129,
              cargo: 'ALPHA OLEFIN C-18',
              charterer: 'MUNTAJAT',
              companyFleet: '100006450__STJS',
              broker_fleet: ['EASTPORT MARITIME EUROPE BV__STJS'],
              oblNum: '525',
              func: 'D',
              _id: '67054dae3e08c9004985e7f5',
            },
            {
              cargoId: 192130,
              cargo: 'ALPHA OLEFIN C30+',
              charterer: 'MUNTAJAT',
              companyFleet: '100006450__STJS',
              broker_fleet: ['EASTPORT MARITIME EUROPE BV__STJS'],
              oblNum: '530',
              func: 'D',
              _id: '67054dae3e08c9004985e7f6',
            },
            {
              cargoId: 192131,
              cargo: 'ALPHA OLEFIN C6',
              charterer: 'MUNTAJAT',
              companyFleet: '100006450__STJS',
              broker_fleet: ['EASTPORT MARITIME EUROPE BV__STJS'],
              oblNum: '535',
              func: 'D',
              _id: '67054dae3e08c9004985e7f7',
            },
            {
              cargoId: 192538,
              cargo: "SODIUM SULFIDE SOL'N",
              charterer: 'MERICHEM COMPAN',
              companyFleet: '100001197__STJS',
              broker_fleet: ['PINNACLE MARITIME LLC__STJS'],
              oblNum: '590',
              func: 'D',
              _id: '67054dae3e08c9004985e7f8',
            },
            {
              cargoId: 192900,
              cargo: 'ALPHA OLEFIN C-14/16',
              charterer: 'MUNTAJAT',
              companyFleet: '100006450__STJS',
              broker_fleet: ['EASTPORT MARITIME EUROPE BV__STJS'],
              oblNum: '620',
              func: 'D',
              _id: '67054dae3e08c9004985e7f9',
            },
            {
              cargoId: 193499,
              cargo: 'Alpha Olefin C8',
              charterer: 'MUNTAJAT',
              companyFleet: '100006450__STJS',
              broker_fleet: ['EASTPORT MARITIME EUROPE BV__STJS'],
              oblNum: '635',
              func: 'D',
              _id: '67054dae3e08c9004985e7fa',
            },
          ],
          sortDate: '+275760-09-13T00:00:00.000Z',
        },
        {
          berthSeqNo: 3,
          berthName: 'VTE 395',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: null,
          actualDateDeparture: null,
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: null,
          actualDateDepartureUTC: null,
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'Pending updates',
          isTSBerth: false,
          _id: '67054dae3e08c9004985e7fb',
          arCargoes: [
            {
              cargoId: 192205,
              cargo: 'VORANOL CP 6001',
              charterer: 'DOW SAUDI ARABIA PRODUCT MARKETI',
              companyFleet: '100007583__STJS',
              broker_fleet: ['CLARKSON DMCC__STJS'],
              oblNum: '550',
              func: 'D',
              _id: '67054dae3e08c9004985e7fc',
            },
            {
              cargoId: 192207,
              cargo: 'VORANATE T-80 E TYPE I',
              charterer: 'DOW SAUDI ARABIA PRODUCT MARKETI',
              companyFleet: '100007583__STJS',
              broker_fleet: ['CLARKSON DMCC__STJS'],
              oblNum: '560',
              func: 'D',
              _id: '67054dae3e08c9004985e7fd',
            },
            {
              cargoId: 192209,
              cargo: 'BUTYL CELLOSOLVE',
              charterer: 'DOW SAUDI ARABIA PRODUCT MARKETI',
              companyFleet: '100007583__STJS',
              broker_fleet: ['CLARKSON DMCC__STJS'],
              oblNum: '630',
              func: 'D',
              _id: '67054dae3e08c9004985e7fe',
            },
            {
              cargoId: 192210,
              cargo: 'DEA',
              charterer: 'DOW SAUDI ARABIA PRODUCT MARKETI',
              companyFleet: '100007583__STJS',
              broker_fleet: ['CLARKSON DMCC__STJS'],
              oblNum: '570',
              func: 'D',
              _id: '67054dae3e08c9004985e7ff',
            },
            {
              cargoId: 192211,
              cargo: 'MEA',
              charterer: 'DOW SAUDI ARABIA PRODUCT MARKETI',
              companyFleet: '100007583__STJS',
              broker_fleet: ['CLARKSON DMCC__STJS'],
              oblNum: '575',
              func: 'D',
              _id: '67054dae3e08c9004985e800',
            },
            {
              cargoId: 192212,
              cargo: 'TEA 99%',
              charterer: 'DOW SAUDI ARABIA PRODUCT MARKETI',
              companyFleet: '100007583__STJS',
              broker_fleet: ['CLARKSON DMCC__STJS'],
              oblNum: '580',
              func: 'D',
              _id: '67054dae3e08c9004985e801',
            },
            {
              cargoId: 193783,
              cargo: 'ALPHA OLEFIN C-10',
              charterer: 'ARAMCO TRADING COMPANY',
              companyFleet: '100009469__STJS',
              broker_fleet: ['RESHAMWALA SHIPBROKERS DMCC__STJS'],
              oblNum: '680',
              func: 'D',
              _id: '67054dae3e08c9004985e802',
            },
          ],
          sortDate: '+275760-09-13T00:00:00.000Z',
        },
        {
          berthSeqNo: 4,
          berthName: 'VTL 1317',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: null,
          actualDateDeparture: null,
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: null,
          actualDateDepartureUTC: null,
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'Pending updates',
          isTSBerth: false,
          _id: '67054dae3e08c9004985e803',
          arCargoes: [
            {
              cargoId: 193784,
              cargo: 'ALPHA OLEFIN C6',
              charterer: 'ARAMCO TRADING COMPANY',
              companyFleet: '100009469__STJS',
              broker_fleet: ['RESHAMWALA SHIPBROKERS DMCC__STJS'],
              oblNum: '685',
              func: 'D',
              _id: '67054dae3e08c9004985e804',
            },
            {
              cargoId: 193785,
              cargo: 'Alpha Olefin C8',
              charterer: 'ARAMCO TRADING COMPANY',
              companyFleet: '100009469__STJS',
              broker_fleet: ['RESHAMWALA SHIPBROKERS DMCC__STJS'],
              oblNum: '690',
              func: 'D',
              _id: '67054dae3e08c9004985e805',
            },
          ],
          sortDate: '+275760-09-13T00:00:00.000Z',
        },
      ],
      dtETA: '2024-10-10T11:00:00.000Z',
      status: 'Pending updates',
      vesVoy: 'SMAPL__52__STJS',
      vslCode: 'SMAPL',
      vslName: 'STOLT MAPLE',
      voyNo: 52,
      seqNo: 1400,
      portNo: 650,
      portName: 'ANTWERP',
      dtETD: '2024-10-17T11:00:00.000Z',
      country: 'BELGIUM',
      hasPendingStatus: true,
    },
    {
      _id: '66b466453c8ca9005ce2d334',
      arBerths: [
        {
          berthSeqNo: 1,
          berthName: 'POB 1',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: null,
          actualDateDeparture: null,
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: null,
          actualDateDepartureUTC: null,
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'Pending updates',
          isTSBerth: false,
          _id: '670666eca0d75900480362fc',
          arCargoes: [
            {
              cargoId: 194547,
              cargo: 'PG FACID MESTER 810EU',
              charterer: 'P&G INTERNATIONAL OPERATIONS SA',
              companyFleet: '100009152__STJS',
              broker_fleet: ['CLARKSON PLATOU ASIA PTE LTD__STJS'],
              oblNum: '240',
              func: 'L',
              _id: '670666eca0d75900480362fd',
            },
            {
              cargoId: 194548,
              cargo: 'P&G CE-1214',
              charterer: 'P&G INTERNATIONAL OPERATIONS SA',
              companyFleet: '100009152__STJS',
              broker_fleet: ['CLARKSON PLATOU ASIA PTE LTD__STJS'],
              oblNum: '245',
              func: 'L',
              _id: '670666eca0d75900480362fe',
            },
            {
              cargoId: 194550,
              cargo: 'P&G CE-1618BL',
              charterer: 'P&G INTERNATIONAL OPERATIONS SA',
              companyFleet: '100009152__STJS',
              broker_fleet: ['CLARKSON PLATOU ASIA PTE LTD__STJS'],
              oblNum: '255',
              func: 'L',
              _id: '670666eca0d75900480362ff',
            },
          ],
          sortDate: '+275760-09-13T00:00:00.000Z',
        },
      ],
      dtETA: '2024-10-10T13:00:00.000Z',
      status: 'Pending updates',
      vesVoy: 'SZUL__121__STJS',
      vslCode: 'SZUL',
      vslName: 'STOLT ZULU',
      voyNo: 121,
      seqNo: 900,
      portNo: 21985,
      portName: 'KUANTAN',
      dtETD: '2024-10-12T13:00:00.000Z',
      country: 'MALAYSIA',
      hasPendingStatus: true,
    },
    {
      _id: '67042d638d1e8b0bcc043013',
      arBerths: [
        {
          berthSeqNo: 1,
          berthName: 'BASF',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: null,
          actualDateDeparture: null,
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: null,
          actualDateDepartureUTC: null,
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'Pending updates',
          isTSBerth: false,
          _id: '6705e85a42754f00481fd96b',
          arCargoes: [
            {
              cargoId: 23553,
              cargo: 'DOTP PLASTICIZER',
              charterer: 'BASF NJ- QUIN',
              companyFleet: '100001617__SNICS',
              broker_fleet: ['QUINCANNON__SNICS'],
              oblNum: '400',
              func: 'L',
              _id: '6705e85a42754f00481fd96c',
            },
            {
              cargoId: 23554,
              cargo: '2-ETHYLHEXANOL',
              charterer: 'BASF NJ- QUIN',
              companyFleet: '100001617__SNICS',
              broker_fleet: ['QUINCANNON__SNICS'],
              oblNum: '405',
              func: 'L',
              _id: '6705e85a42754f00481fd96d',
            },
          ],
          sortDate: '+275760-09-13T00:00:00.000Z',
        },
        {
          berthSeqNo: 2,
          berthName: 'ITC1',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: null,
          actualDateDeparture: null,
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: null,
          actualDateDepartureUTC: null,
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'Pending updates',
          isTSBerth: false,
          _id: '6705e85a42754f00481fd96e',
          arCargoes: [
            {
              cargoId: 23556,
              cargo: 'XYLENE-M',
              charterer: 'QUIMICA DELTA SA -',
              companyFleet: '100007715__SNICS',
              broker_fleet: ['NETCO__SNICS'],
              oblNum: '415',
              func: 'L',
              _id: '6705e85a42754f00481fd96f',
            },
            {
              cargoId: 23557,
              cargo: 'HEXANE',
              charterer: 'QUIMICA DELTA SA -',
              companyFleet: '100007715__SNICS',
              broker_fleet: ['NETCO__SNICS'],
              oblNum: '420',
              func: 'L',
              _id: '6705e85a42754f00481fd970',
            },
          ],
          sortDate: '+275760-09-13T00:00:00.000Z',
        },
        {
          berthSeqNo: 3,
          berthName: 'OBFB',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: null,
          actualDateDeparture: null,
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: null,
          actualDateDepartureUTC: null,
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'Pending updates',
          isTSBerth: false,
          _id: '6705e85a42754f00481fd971',
          arCargoes: [
            {
              cargoId: 23558,
              cargo: 'LAB',
              charterer: 'TEREFTALATOS - ALPEK',
              companyFleet: '100008320__SNICS',
              broker_fleet: ['SSY CHEMICALS__SNICS'],
              oblNum: '425',
              func: 'L',
              _id: '6705e85a42754f00481fd972',
            },
          ],
          sortDate: '+275760-09-13T00:00:00.000Z',
        },
        {
          berthSeqNo: 4,
          berthName: 'EXXON 1',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: null,
          actualDateDeparture: null,
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: null,
          actualDateDepartureUTC: null,
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'Pending updates',
          isTSBerth: false,
          _id: '6705e85a42754f00481fd973',
          arCargoes: [
            {
              cargoId: 23530,
              cargo: 'XYLENE-P',
              charterer: 'TEREFTALATOS - ALPEK',
              companyFleet: '100008320__SNICS',
              broker_fleet: ['SSY CHEMICALS__SNICS'],
              oblNum: '430',
              func: 'L',
              _id: '6705e85a42754f00481fd974',
            },
          ],
          sortDate: '+275760-09-13T00:00:00.000Z',
        },
      ],
      dtETA: '2024-10-10T13:00:00.000Z',
      status: 'Pending updates',
      vesVoy: 'SSPRA__166__SNICS',
      vslCode: 'SSPRA',
      vslName: 'STOLT SPRAY',
      voyNo: 166,
      seqNo: 200,
      portNo: 187,
      portName: 'HOUSTON',
      dtETD: '2024-10-14T13:00:00.000Z',
      country: 'UNITED STATES',
      hasPendingStatus: true,
    },
    {
      _id: '66e6df088ce3b67b2046f1c5',
      arBerths: [
        {
          berthSeqNo: 1,
          berthName: 'HTC #2',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: null,
          actualDateDeparture: null,
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: null,
          actualDateDepartureUTC: null,
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'Pending updates',
          isTSBerth: false,
          _id: '67079545b2d73f00485a8944',
          arCargoes: [
            {
              cargoId: 33811,
              cargo: 'ANYSOL 150E',
              charterer: 'ASCC ASIA PACIFIC PTE LTD',
              companyFleet: '100007315__SNAPS',
              broker_fleet: [],
              oblNum: '50',
              func: 'L',
              _id: '67079545b2d73f00485a8945',
            },
          ],
          sortDate: '+275760-09-13T00:00:00.000Z',
        },
      ],
      dtETA: '2024-10-10T13:50:00.000Z',
      status: 'Pending updates',
      vesVoy: 'SUMCY__3__SNAPS',
      vslCode: 'SUMCY',
      vslName: 'SUN MERCURY',
      voyNo: 3,
      seqNo: 100,
      portNo: 8141,
      portName: 'DAESAN',
      dtETD: '2024-10-11T14:50:00.000Z',
      country: 'KOREA, REPUBLIC',
      hasPendingStatus: true,
    },
    {
      _id: '66b466483c8ca9005ce2dc31',
      arBerths: [
        {
          berthSeqNo: 1,
          berthName: 'QUAY',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: null,
          actualDateDeparture: null,
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: null,
          actualDateDepartureUTC: null,
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'Pending updates',
          isTSBerth: false,
          _id: '67074ef5ef16b60049943fc7',
          arCargoes: [
            {
              cargoId: 33613,
              cargo: 'DIESEL OIL',
              charterer: 'INTEGRA PETROCHEMICALS',
              companyFleet: '100007144__SNAPS',
              broker_fleet: ['CLARKSON PLATOU ASIA PTE LTD__SNAPS'],
              oblNum: '140',
              func: 'D',
              _id: '67074ef5ef16b60049943fc8',
            },
          ],
          sortDate: '+275760-09-13T00:00:00.000Z',
        },
      ],
      dtETA: '2024-10-10T15:20:00.000Z',
      status: 'Pending updates',
      vesVoy: 'SHAGI__79__SNAPS',
      vslCode: 'SHAGI',
      vslName: 'STOLT HAGI',
      voyNo: 79,
      seqNo: 23200,
      portNo: 3182,
      portName: 'SINGAPORE',
      dtETD: '2024-10-12T03:20:00.000Z',
      country: 'SINGAPORE',
      hasPendingStatus: true,
    },
    {
      _id: '66b466443c8ca9005ce2d07a',
      arBerths: [
        {
          berthSeqNo: 1,
          berthName: 'HTT 14',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: null,
          actualDateDeparture: null,
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: null,
          actualDateDepartureUTC: null,
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'Pending updates',
          isTSBerth: false,
          _id: '6706a634ef16b600498443a7',
          arCargoes: [
            {
              cargoId: 194891,
              cargo: 'OLIVE OIL (REFINED)',
              charterer: 'POMPEIAN OLIVE OIL',
              companyFleet: '100006624__STJS',
              broker_fleet: [],
              oblNum: '730',
              func: 'D',
              _id: '6706a634ef16b600498443a8',
            },
            {
              cargoId: 194892,
              cargo: 'OLIVE OIL (EXT VIRGIN)',
              charterer: 'POMPEIAN OLIVE OIL',
              companyFleet: '100006624__STJS',
              broker_fleet: [],
              oblNum: '735',
              func: 'D',
              _id: '6706a634ef16b600498443a9',
            },
            {
              cargoId: 194893,
              cargo: 'OLIVE OIL (POMACE)',
              charterer: 'POMPEIAN OLIVE OIL',
              companyFleet: '100006624__STJS',
              broker_fleet: [],
              oblNum: '740',
              func: 'D',
              _id: '6706a634ef16b600498443aa',
            },
          ],
          sortDate: '+275760-09-13T00:00:00.000Z',
        },
      ],
      dtETA: '2024-10-10T17:00:00.000Z',
      status: 'Pending updates',
      vesVoy: 'SSUN__149__STJS',
      vslCode: 'SSUN',
      vslName: 'STOLT SUN',
      voyNo: 149,
      seqNo: 400,
      portNo: 278,
      portName: 'NEW YORK',
      dtETD: '2024-10-12T17:00:00.000Z',
      country: 'UNITED STATES',
      hasPendingStatus: true,
    },
    {
      _id: '66fc28444ad8a5889cde0279',
      arBerths: [
        {
          berthSeqNo: 1,
          berthName: 'QUAY',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: null,
          actualDateDeparture: null,
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: null,
          actualDateDepartureUTC: null,
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'Pending updates',
          isTSBerth: false,
          _id: '6705e15242754f00481f1dcc',
          arCargoes: [
            {
              cargoId: 194363,
              cargo: 'ANILINE',
              charterer: 'COVESTRO LLC',
              companyFleet: '100006777__STJS',
              broker_fleet: [],
              oblNum: '525',
              func: 'L',
              _id: '6705e15242754f00481f1dcd',
            },
          ],
          sortDate: '+275760-09-13T00:00:00.000Z',
        },
        {
          berthSeqNo: 2,
          berthName: 'Odfjell Wharf 1',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: null,
          actualDateDeparture: null,
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: null,
          actualDateDepartureUTC: null,
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'Pending updates',
          isTSBerth: false,
          _id: '6705e15242754f00481f1dce',
          arCargoes: [
            {
              cargoId: 195551,
              cargo: 'HMD',
              charterer: 'INV NYLON CHEMICALS AMERICAS LLC',
              companyFleet: '100009356__STJS',
              broker_fleet: ['ODIN MARINE__STJS'],
              oblNum: '530',
              func: 'L',
              _id: '6705e15242754f00481f1dcf',
            },
          ],
          sortDate: '+275760-09-13T00:00:00.000Z',
        },
      ],
      dtETA: '2024-10-10T17:00:00.000Z',
      status: 'Pending updates',
      vesVoy: 'SBTLA__176__STJS',
      vslCode: 'SBTLA',
      vslName: 'STOLT BETULA',
      voyNo: 176,
      seqNo: 1300,
      portNo: 187,
      portName: 'HOUSTON',
      dtETD: '2024-10-15T17:00:00.000Z',
      country: 'UNITED STATES',
      hasPendingStatus: true,
    },
    {
      _id: '66f41c1a04a9dea95609d7e1',
      arBerths: [
        {
          berthSeqNo: 3,
          berthName: 'STANDIC 3',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: null,
          actualDateDeparture: null,
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: null,
          actualDateDepartureUTC: null,
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'Pending updates',
          isTSBerth: false,
          _id: '67069f29ef16b600498373b1',
          arCargoes: [
            {
              cargoId: 194800,
              cargo: 'ETHANOL',
              charterer: 'ALCOTRA S.A.',
              companyFleet: '100000150__STJS',
              broker_fleet: ['INTERNATIONAL TANKER CHARTERING__STJS'],
              oblNum: '155',
              func: 'D',
              _id: '67069f29ef16b600498373b2',
            },
            {
              cargoId: 195028,
              cargo: 'SN 150',
              charterer: 'CHEMLUBE INTERNATIONAL LLC',
              companyFleet: '100009305__STJS',
              broker_fleet: ['SPI MARINE USA INC__STJS'],
              oblNum: '160',
              func: 'D',
              _id: '67069f29ef16b600498373b3',
            },
            {
              cargoId: 195029,
              cargo: 'SN 500',
              charterer: 'CHEMLUBE INTERNATIONAL LLC',
              companyFleet: '100009305__STJS',
              broker_fleet: ['SPI MARINE USA INC__STJS'],
              oblNum: '165',
              func: 'D',
              _id: '67069f29ef16b600498373b4',
            },
            {
              cargoId: 195030,
              cargo: 'BS150 (IRPC)',
              charterer: 'CHEMLUBE INTERNATIONAL LLC',
              companyFleet: '100009305__STJS',
              broker_fleet: ['SPI MARINE USA INC__STJS'],
              oblNum: '170',
              func: 'D',
              _id: '67069f29ef16b600498373b5',
            },
          ],
          sortDate: '+275760-09-13T00:00:00.000Z',
        },
      ],
      dtETA: '2024-10-10T17:50:46.000Z',
      status: 'Pending updates',
      vesVoy: 'SAPAL__53__STJS',
      vslCode: 'SAPAL',
      vslName: 'STOLT APAL',
      voyNo: 53,
      seqNo: 4600,
      portNo: 667,
      portName: 'DORDRECHT',
      dtETD: '2024-10-12T04:00:00.000Z',
      country: 'NETHERLANDS',
      hasPendingStatus: true,
    },
    {
      _id: '66b466443c8ca9005ce2d173',
      arBerths: [
        {
          berthSeqNo: 2,
          berthName: 'YangJing berth#147',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: null,
          actualDateDeparture: null,
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: null,
          actualDateDepartureUTC: null,
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'Pending updates',
          isTSBerth: false,
          _id: '6706128b42754f004824580c',
          arCargoes: [
            {
              cargoId: 192705,
              cargo: 'ADIPONITRILE',
              charterer: 'ASCEND PERFORMANCE MATERIALS HOL',
              companyFleet: '100005886__STJS',
              broker_fleet: ['MIKKELSEN SHIPBROKING AS__STJS'],
              oblNum: '200',
              func: 'D',
              _id: '6706128b42754f004824580d',
            },
          ],
          sortDate: '+275760-09-13T00:00:00.000Z',
        },
      ],
      dtETA: '2024-10-10T18:00:00.000Z',
      status: 'Pending updates',
      vesVoy: 'SCAPA__123__STJS',
      vslCode: 'SCAPA',
      vslName: 'STOLT CAPABILITY',
      voyNo: 123,
      seqNo: 2900,
      portNo: 8285,
      portName: 'LIANYUNGANG',
      dtETD: '2024-10-12T13:12:00.000Z',
      country: 'CHINA',
      hasPendingStatus: true,
    },
    {
      _id: '670627a4c85d31e448c7ef33',
      arBerths: [
        {
          berthSeqNo: 2,
          berthName: 'QVC # 19',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: null,
          actualDateDeparture: null,
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: null,
          actualDateDepartureUTC: null,
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'Pending updates',
          isTSBerth: false,
          _id: '67079542b2d73f00485a88d0',
          arCargoes: [],
          sortDate: '+275760-09-13T00:00:00.000Z',
        },
      ],
      dtETA: '2024-10-10T19:00:00.000Z',
      status: 'Pending updates',
      vesVoy: 'SACER__166__STJS',
      vslCode: 'SACER',
      vslName: 'STOLT ACER',
      voyNo: 166,
      seqNo: 10600,
      portNo: 24139,
      portName: 'MESAIEED',
      dtETD: '2024-10-12T09:59:36.000Z',
      country: 'QATAR',
      hasPendingStatus: true,
    },
    {
      _id: '66b466443c8ca9005ce2d249',
      arBerths: [
        {
          berthSeqNo: 1,
          berthName: 'TAGSA',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: null,
          actualDateDeparture: null,
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: null,
          actualDateDepartureUTC: null,
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'Pending updates',
          isTSBerth: false,
          _id: '67074ef2ef16b60049943f58',
          arCargoes: [
            {
              cargoId: 193766,
              cargo: 'ARCOL POLYOL F3040',
              charterer: 'COVESTRO LLC',
              companyFleet: '100006777__STJS',
              broker_fleet: [],
              oblNum: '611',
              func: 'D',
              _id: '67074ef2ef16b60049943f59',
            },
          ],
          sortDate: '+275760-09-13T00:00:00.000Z',
        },
      ],
      dtETA: '2024-10-11T02:00:00.000Z',
      status: 'Pending updates',
      vesVoy: 'SGLOR__106__STJS',
      vslCode: 'SGLOR',
      vslName: 'STOLT GLORY',
      voyNo: 106,
      seqNo: 1000,
      portNo: 74,
      portName: 'CAMPANA',
      dtETD: '2024-10-12T02:00:00.000Z',
      country: 'ARGENTINA',
      hasPendingStatus: true,
    },
    {
      _id: '670585eb45a71539adaad267',
      arBerths: [
        {
          berthSeqNo: 1,
          berthName: 'BWC Gretna',
          estimatedDateArival: null,
          estimatedDateDeparture: null,
          actualDateArival: null,
          actualDateDeparture: null,
          estimatedDateArivalUTC: null,
          estimatedDateDepartureUTC: null,
          actualDateArivalUTC: null,
          actualDateDepartureUTC: null,
          updatedCount: 0,
          lastUpdatedByPortOps: null,
          isDeleted: false,
          status: 'Pending updates',
          isTSBerth: false,
          _id: '670643c342754f004829a477',
          arCargoes: [
            {
              cargoId: 194402,
              cargo: 'HYGOLD 60',
              charterer: 'ERGON, INC. - NEW ENGLAND',
              companyFleet: '100003051__STJS',
              broker_fleet: ['NETCO__STJS'],
              oblNum: '525',
              func: 'L',
              _id: '670643c342754f004829a478',
            },
            {
              cargoId: 194403,
              cargo: 'HYGOLD 100',
              charterer: 'ERGON, INC. - NEW ENGLAND',
              companyFleet: '100003051__STJS',
              broker_fleet: ['NETCO__STJS'],
              oblNum: '530',
              func: 'L',
              _id: '670643c342754f004829a479',
            },
            {
              cargoId: 194404,
              cargo: 'HYGOLD L600',
              charterer: 'ERGON, INC. - NEW ENGLAND',
              companyFleet: '100003051__STJS',
              broker_fleet: ['NETCO__STJS'],
              oblNum: '535',
              func: 'L',
              _id: '670643c342754f004829a47a',
            },
            {
              cargoId: 194405,
              cargo: 'HYGOLD L2000',
              charterer: 'ERGON, INC. - NEW ENGLAND',
              companyFleet: '100003051__STJS',
              broker_fleet: ['NETCO__STJS'],
              oblNum: '540',
              func: 'L',
              _id: '670643c342754f004829a47b',
            },
            {
              cargoId: 194406,
              cargo: 'ERGON HYGOLD BS 5000',
              charterer: 'ERGON, INC. - NEW ENGLAND',
              companyFleet: '100003051__STJS',
              broker_fleet: ['NETCO__STJS'],
              oblNum: '545',
              func: 'L',
              _id: '670643c342754f004829a47c',
            },
          ],
          sortDate: '+275760-09-13T00:00:00.000Z',
        },
      ],
      dtETA: '2024-10-11T02:49:00.000Z',
      status: 'Pending updates',
      vesVoy: 'SINVE__135__STJS',
      vslCode: 'SINVE',
      vslName: 'STOLT INVENTION',
      voyNo: 135,
      seqNo: 1600,
      portNo: 276,
      portName: 'NEW ORLEANS',
      dtETD: '2024-10-12T14:49:00.000Z',
      country: 'UNITED STATES',
      hasPendingStatus: true,
    },
  ];
  berthDetails: any;
  etbClassName: boolean = false;
  isSameBerthId:any;

  // From variable
  hstep = 1;
  mstep = 15;
  portForm: FormGroup;
  currentTimerType: any;
  hasErrors: boolean;
  hasSameErrors: boolean;
  hasErrorsApiLevel: boolean;
  hasPendingStatus: boolean;
  activeApiRequests: any;
  etaErrorMessage: any;
  etdErrorMessage: any;
  tokenLink: string;
  constructor(private route: ActivatedRoute, private fb: UntypedFormBuilder, private etbInternalService: EtbInternalService,
    private navigationService: NavigationService,
    public otpService: OtpService,
    private router:Router
  ) {
    this.hstep = 1;
  }

  ngOnInit(): void {
    // this.route.queryParams.subscribe((params) => {
    //   console.log('params::', params);
    //   this.tokenLink = params['token'];
    // });

    this.route.params.subscribe({next:(paramsId:any) => {
      console.log("tokenLink::",paramsId.id)
      this.tokenLink = paramsId.id;
    }, error:err => {
      this.navigationService.errorOccure(err);
      this.router.navigate(['/404']);
    }}) 

    // Start a session
    this.startSession();

    // Example of checking session validity
    setInterval(() => {
        if (!this.isSessionValid()) {
            // Session expired logic
            this.isSessionExpired = true;
            this.openModel = false;
            console.log('Redirecting or prompting user to log in again.');
        }
    }, 60000); // Check every 1 minute
  }

  showEtbUpdatePopup(berth: any) {
    if (berth.arBerths.length) {
      this.isSameBerthId = berth._id;
      this.etbClassName = true;
      this.berthDetails = berth;
      this.calledFromMethod();
    }
  }

  // Form code

  calledFromMethod() {
    this.createVesselForm();
    if (this.berthDetails) {
      console.log(this.berthDetails)
      this.hasPendingStatus = this.berthDetails.hasPendingStatus;
      this.initializeForm();
    }
  }

  get arBerths(): FormArray {
    return this.portForm.get('arBerths') as FormArray;
  }

  getCargoes(berth: any): FormArray {
    return berth.get('arCargoes') as FormArray;
  }

  createVesselForm() {
    this.portForm = this.fb.group({
      portNo: [''],
      portName: [''],
      seqNo: [''],
      vesVoy: [''],
      arBerths: this.fb.array([
        this.createBerth()])
    });
  }

  createBerth(): FormGroup {
    return this.fb.group({
      berthSeqNo: [''],
      berthName: [''],
      estimatedDateArival: [null],
      estimatedDateDeparture: [null],
      actualDateArival: [null],
      actualDateDeparture: [null],
      _id: [''],
      actualTimeArival: [null],
      actualTimeDeparture: [null],
      actualTimeArivalDisplay: [null],
      actualTimeDepartureDisplay: [null],
      actualTimeArivalDisable: [null],
      actualTimeDepartureDisable: [null],
      actualDateArivalDisplay: [null],
      actualDateDepartureDisplay: [null],
      etaActualMergedDateTime: [null],
      etdDepartureMergedDateTime: [null],
      etaActualErrorMessage: [null],
      type: [null],
      etdDepartureErrorMessage: [null],
      vslLevelErrorMessage: [null],
      arCargoes: [{ cargo: '', func: '' }],
    });
  }

  checkAndDisableActualDates(): void {
    this.arBerths.controls.forEach((berthGroup: any) => {
      const actualDateArival = berthGroup.get('actualDateArival'); // ATA
      const estimatedDateArival = berthGroup.get('estimatedDateArival'); // ETA

      const actualDateDeparture = berthGroup.get('actualDateDeparture'); // ATD
      const estimatedDateDeparture = berthGroup.get('estimatedDateDeparture'); // ETD

      // For ETA/ATA 
      if ((actualDateArival.value && estimatedDateArival.value) || (actualDateArival.value && !estimatedDateArival.value)) {
        berthGroup.get('actualTimeArivalDisable').setValue(this.fromLocalISOFormat(actualDateArival.value));
        berthGroup.get('actualTimeArivalDisplay').setValue(this.fromLocalISOFormat(actualDateArival.value));
        berthGroup.get('actualDateArivalDisplay').setValue(this.fromLocalISOFormat(actualDateArival.value));
      } else if (!actualDateArival.value && estimatedDateArival.value) {
        berthGroup.get('actualTimeArivalDisplay').setValue(this.fromLocalISOFormat(estimatedDateArival.value));
        berthGroup.get('actualDateArivalDisplay').setValue(this.fromLocalISOFormat(estimatedDateArival.value));
        berthGroup.get('actualDateArivalDisplay').enable();
      } else {
        berthGroup.get('actualDateArivalDisplay').enable();
      }

      // For ETD/ATD 
      if ((actualDateDeparture.value && estimatedDateDeparture.value) || (actualDateDeparture.value && !estimatedDateDeparture.value)) {
        berthGroup.get('actualTimeDepartureDisable').setValue(this.fromLocalISOFormat(actualDateDeparture.value));
        berthGroup.get('actualTimeDepartureDisplay').setValue(this.fromLocalISOFormat(actualDateDeparture.value));
        berthGroup.get('actualDateDepartureDisplay').setValue(this.fromLocalISOFormat(actualDateDeparture.value));
      } else if (!actualDateDeparture.value && estimatedDateDeparture.value) {
        berthGroup.get('actualTimeDepartureDisplay').setValue(this.fromLocalISOFormat(estimatedDateDeparture.value));
        berthGroup.get('actualDateDepartureDisplay').setValue(this.fromLocalISOFormat(estimatedDateDeparture.value));
        berthGroup.get('actualDateDepartureDisplay').enable();
      } else {
        berthGroup.get('actualDateDepartureDisplay').enable();
      }
    });
    console.log(this.arBerths.value)
  }

  fromLocalISOFormat(isoString: any) {
    const date = new Date(isoString);

    const year = date.getUTCFullYear();
    const month = date.getUTCMonth(); // Months are zero-indexed
    const day = date.getUTCDate();
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const seconds = date.getUTCSeconds();
    const milliseconds = date.getUTCMilliseconds();

    return new Date(year, month, day, hours, minutes, seconds, milliseconds);
  }

  initializeForm() {
    this.portForm = this.fb.group({
      portNo: [this.berthDetails.portNo],
      portName: [this.berthDetails.portName],
      seqNo: [this.berthDetails.seqNo],
      vesVoy: [this.berthDetails.vesVoy],
      arBerths: this.fb.array([]),
    });
    // Populate arBerths array
    const arBerthsFormArray = this.portForm.get('arBerths') as FormArray;
    this.berthDetails.arBerths.forEach((berth: any) => {
      arBerthsFormArray.push(this.createBerthFormGroup(berth));
    });
    this.checkAndDisableActualDates();
  }

  createBerthFormGroup(berth: any): FormGroup {
    const defaultTime = moment().hours(12).minutes(0).seconds(0).toDate();
    return this.fb.group({
      berthSeqNo: [berth.berthSeqNo],
      berthName: [berth.berthName],
      estimatedDateArival: [berth.estimatedDateArival],
      estimatedDateDeparture: [berth.estimatedDateDeparture],
      actualDateArival: [berth.actualDateArival],
      actualDateDeparture: [berth.actualDateDeparture],
      actualTimeArival: [this.fromLocalISOFormat(berth.estimatedDateArival) || defaultTime],
      actualTimeDeparture: [this.fromLocalISOFormat(berth.estimatedDateDeparture) || defaultTime],
      _id: [berth._id],
      actualTimeArivalDisplay: [null, Validators.required],
      actualTimeDepartureDisplay: [null, Validators.required],
      actualTimeArivalDisable: [null],
      actualTimeDepartureDisable: [null],
      actualDateArivalDisplay: [null, Validators.required],
      actualDateDepartureDisplay: [null, Validators.required],
      etaActualMergedDateTime: [null],
      etdDepartureMergedDateTime: [null],
      etaActualErrorMessage: [null],
      type: [null],
      etdDepartureErrorMessage: [null],
      vslLevelErrorMessage: [null],
      arCargoes: this.fb.array(
        berth.arCargoes.map((cargo: any) => this.fb.group({ cargo: [cargo.cargo], func: [cargo.func] }))
      )
    });

  }


  currentTimerIndex: any
  displayPicker(index: any, type: any) {
    this.currentTimerIndex = index;
    this.currentTimerType = type;
  }



  applyTimeCustom(index: number, type: any) {
    let timeArival = this.getActualTimeArival(index);
    let timeDeparture = this.getActualTimeDeparture(index);

    // Set actualTimeArival to actualTimeArivalDisplay
    if (type == 'eta') {
      this.arBerths.at(index)?.get('actualTimeArivalDisplay')?.setValue(timeArival);
    }

    // Set actualTimeDeparture to actualTimeDepartureDisplay
    if (type == 'etd') {
      this.arBerths.at(index)?.get('actualTimeDepartureDisplay')?.setValue(timeDeparture);
    }
    this.displayPicker(index, 'close');
    this.handleErrorMessage(index, type);
  }

  getActualTimeArival(index: number): Date | null {
    return this.arBerths.at(index)?.get('actualTimeArival')?.value ?? null;
  }

  getActualTimeDeparture(index: number): Date | null {
    return this.arBerths.at(index)?.get('actualTimeDeparture')?.value ?? null;
  }

  mergeDateAndTime(date: any, time: any): any {
    if (!date || !time) {
      return null; // return current date-time if either date or time is null
    }
    const mergedDate = moment(date).set({
      hour: moment(time).hours(),
      minute: moment(time).minutes(),
      second: moment(time).seconds()
    });
    return mergedDate.toDate();
  }

  onSubmit(): void {
    if (!this.hasErrors && !this.hasErrorsApiLevel) {
      let payload = this.portForm.value;
      const cleanedPayload = this.cleanPayload(payload);
      this.updateEtbDetailsApi(cleanedPayload);
    }
  }

  cleanPayload(payload: any) {
    const cleanedPayload: any = {
      portNo: payload.portNo,
      seqNo: payload.seqNo,
      vesVoy: payload.vesVoy,
      arBerths: payload.arBerths.map((berth: any) => ({
        berthSeqNo: berth.berthSeqNo,
        berthName: berth.berthName,
        etaDateTime: this.toLocalISOFormat(new Date(berth.actualDateArivalDisplay), new Date(berth.actualTimeArivalDisplay)),
        etdDateTime: this.toLocalISOFormat(new Date(berth.actualDateDepartureDisplay), new Date(berth.actualTimeDepartureDisplay))
      }))
    };
    return cleanedPayload;
  }

  toLocalISOFormat(date: Date, time: any): string {
    const pad = (num: number) => num.toString().padStart(2, '0');

    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1); // Months are zero-indexed
    const day = pad(date.getDate());
    const hours = pad(time.getHours());
    const minutes = pad(time.getMinutes());
    const seconds = pad(time.getSeconds());
    const milliseconds = (time.getMilliseconds() / 1000).toFixed(3).slice(2, 5); // Milliseconds in three digits

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
  }

  updateEtbDetailsApi(cleanedPayload: any) {
    this.hasErrors = true;
    this.etbInternalService.updateEtb(cleanedPayload).subscribe({
      next: (res: any) => {
        this.hasErrors = false;
        if (res.status == 200) {
         // this.closePopUp.emit('updated');
        }
      }, error: err => {
        this.hasErrors = false;
      }
    })
  }

  handleErrorMessage(currentIndex: any, type: any) {
    const currentBerth = this.arBerths.at(currentIndex);
    if((currentBerth.value.actualDateArivalDisplay && currentBerth.value.actualTimeArivalDisplay && type == 'eta') || (currentBerth.value.actualDateDepartureDisplay && currentBerth.value.actualTimeDepartureDisplay && type == 'etd')){
      this.hasErrors = false;
    this.hasSameErrors = false;
    this.arBerths.at(currentIndex)?.get('type')?.setValue(type);
    this.arBerths.controls.forEach((berthGroup: any, index:any) => {
      const berthSeqNo = berthGroup.get('berthSeqNo')?.value;
      const berthName = berthGroup.get('berthName')?.value;
      const actualDateArivalDisplay = berthGroup.get('actualDateArivalDisplay')?.value;
      const actualTimeArivalDisplay = berthGroup.get('actualTimeArivalDisplay')?.value;
      const actualDateDepartureDisplay = berthGroup.get('actualDateDepartureDisplay')?.value;
      const actualTimeDepartureDisplay = berthGroup.get('actualTimeDepartureDisplay')?.value;
      const mergedArrival = this.mergeDateAndTime(actualDateArivalDisplay, actualTimeArivalDisplay);
      const mergedDeparture = this.mergeDateAndTime(actualDateDepartureDisplay, actualTimeDepartureDisplay);
      berthGroup.patchValue({
        etaActualMergedDateTime: mergedArrival,
        etdDepartureMergedDateTime: mergedDeparture
      });


      // Initialize error messages
      this.etaErrorMessage = null;
      this.etdErrorMessage = null;

      const actualDateArival = berthGroup.get('actualDateArival')?.value;
      const actualDateDeparture = berthGroup.get('actualDateDeparture')?.value;
      if(!(actualDateArival && actualDateDeparture)){
        this.checkApiErrorMessage(berthSeqNo, berthGroup, berthName, actualDateArivalDisplay, actualTimeArivalDisplay, actualDateDepartureDisplay, actualTimeDepartureDisplay);
      }

      // Set error messages
      berthGroup.patchValue({
        etaActualErrorMessage: this.etaErrorMessage,
        etdDepartureErrorMessage: this.etdErrorMessage
      });
    });

    this.checkPreviousDateTime(currentIndex); // Overlapping
    this.checkFutureDateTime(currentIndex); // Overlapping
    this.checkEtaEtdValidation(currentIndex); // Same berth eta/etd date/time
    this.checkVesselPortWiseValidation(currentIndex); // Vessel port date/time
    this.checkSameBerthEqualValidation(currentIndex); // Same berth confilict date/time
    this.checkAgainstValue(currentIndex); // Another berth confilict date/time
    this.checkSameBerthValidationDisplay(currentIndex, type);
    setTimeout(() => {
      this.checkDateOrder(this.arBerths.value);
    }, 100)
  }
  }

  checkSameBerthValidationDisplay(currentIndex:any, type?:any){
    const currentBerth = this.arBerths.at(currentIndex);
    let etaErrMsgValue = currentBerth.get('etaActualErrorMessage')?.value;
    let etdErrMsgValue = currentBerth.get('etdDepartureErrorMessage')?.value;
      if(type == 'eta' && etdErrMsgValue && etaErrMsgValue){
        this.arBerths.at(currentIndex)?.get('etaActualErrorMessage')?.setValue(etaErrMsgValue);
        this.arBerths.at(currentIndex)?.get('etdDepartureErrorMessage')?.setValue('');
      }else if(type == 'etd' && etdErrMsgValue && etaErrMsgValue){
        this.arBerths.at(currentIndex)?.get('etaActualErrorMessage')?.setValue('');
        this.arBerths.at(currentIndex)?.get('etdDepartureErrorMessage')?.setValue(etdErrMsgValue);
      }
  }

  checkSameBerthEqualValidation(currentIndex:any){
    const currentBerth = this.arBerths.at(currentIndex);
    const mergedArrival = currentBerth?.get('etaActualMergedDateTime')?.value;
    const mergedDeparture = currentBerth?.get('etdDepartureMergedDateTime')?.value;
    const etaEtdtype = currentBerth?.get('type')?.value;
    if (mergedArrival && mergedDeparture && mergedArrival.getTime() === mergedDeparture.getTime()) {
      this.hasSameErrors = true;
      if(this.hasErrors){
        this.arBerths.at(currentIndex)?.get('etaActualErrorMessage')?.setValue('');
        this.arBerths.at(currentIndex)?.get('etdDepartureErrorMessage')?.setValue('');
      }
      if (etaEtdtype == 'eta') {
        this.etaErrorMessage = 'This date/time conflicts with another field on this form. Please check and confirm the correct date/time.';
        this.arBerths.at(currentIndex)?.get('etaActualErrorMessage')?.setValue(this.etaErrorMessage);
      } else {
        this.etdErrorMessage = 'This date/time conflicts with another field on this form. Please check and confirm the correct date/time.';
        this.arBerths.at(currentIndex)?.get('etdDepartureErrorMessage')?.setValue(this.etdErrorMessage);
      }
    }
  }

  checkVesselPortWiseValidation(currentIndex:any){
    const currentBerth = this.arBerths.at(currentIndex);
    const actualDateArivalDisplay = currentBerth?.get('actualDateArivalDisplay')?.value;
    const actualTimeArivalDisplay = currentBerth?.get('actualTimeArivalDisplay')?.value;
    const actualDateDepartureDisplay = currentBerth?.get('actualDateDepartureDisplay')?.value;
    const actualTimeDepartureDisplay = currentBerth?.get('actualTimeDepartureDisplay')?.value;
    let etaDate = this.toLocalISOFormat(new Date(actualDateArivalDisplay), new Date(actualTimeArivalDisplay));
    let etdDate = this.toLocalISOFormat(new Date(actualDateDepartureDisplay), new Date(actualTimeDepartureDisplay));
    if (actualDateArivalDisplay && actualTimeArivalDisplay && new Date(etaDate) < new Date(this.berthDetails.dtETA)) {
      this.hasSameErrors = true;
      this.etaErrorMessage = 'This date/time should not be before the vessel’s port arrival date. Please check and confirm the correct date/time.';
      this.arBerths.at(currentIndex)?.get('etaActualErrorMessage')?.setValue(this.etaErrorMessage);
    } else if (actualDateArivalDisplay && actualTimeArivalDisplay && new Date(etaDate) > new Date(this.berthDetails.dtETD)) {
      this.hasSameErrors = true;
      this.etaErrorMessage = 'This date/time should not be after the vessel’s port departure date. Please check and confirm the correct date/time.';
      this.arBerths.at(currentIndex)?.get('etaActualErrorMessage')?.setValue(this.etaErrorMessage);
    }
    if (actualDateDepartureDisplay && actualTimeDepartureDisplay && new Date(etdDate) > new Date(this.berthDetails.dtETD)) {
      this.hasSameErrors = true;
      this.etdErrorMessage = 'This date/time should not be after the vessel’s port departure date. Please check and confirm the correct date/time.';
      this.arBerths.at(currentIndex)?.get('etdDepartureErrorMessage')?.setValue(this.etdErrorMessage);
    } else if (actualDateDepartureDisplay && actualTimeDepartureDisplay && new Date(etdDate) < new Date(this.berthDetails.dtETA)) {
      this.hasSameErrors = true;
      this.etdErrorMessage = 'This date/time should not be before the vessel’s port arrival date. Please check and confirm the correct date/time.';
      this.arBerths.at(currentIndex)?.get('etdDepartureErrorMessage')?.setValue(this.etdErrorMessage);
    }
  }


  checkDateOrder(array: any) {
    let previousEtaDate: any = null;
    let previousEtdDate: any = null;
    if (!this.hasErrors && !this.hasSameErrors) {
     array.forEach((item: any, index: any) => {
      const currentEtaDate = item.etaActualMergedDateTime ? new Date(item.etaActualMergedDateTime) : null;
      const currentEtdDate = item.etdDepartureMergedDateTime ? new Date(item.etdDepartureMergedDateTime) : null;
         this.checkOverlapValidationOrder(previousEtaDate, previousEtdDate, currentEtaDate, currentEtdDate, index);
         this.checkEtaEtdValidation(index); // Same berth eta/etd date/time
         this.checkVesselPortWiseValidation(index); // Vessel port date/time
         this.checkSameBerthEqualValidation(index); // Same berth confilict date/time
         this.checkMatchDateTime(previousEtaDate, previousEtdDate, currentEtaDate, currentEtdDate, index); // Another berth confilict date/time
       previousEtaDate = currentEtaDate;
       previousEtdDate = currentEtdDate;
     });
    }
   }

   checkOverlapValidationOrder(previousEtaDate:any, previousEtdDate:any, currentEtaDate:any, currentEtdDate:any, index:any){
    let errorMessage = 'This date/time overlaps with another berth’s stay. Please check and confirm the correct date/time.';
    if (previousEtaDate && currentEtaDate && new Date(currentEtaDate) < new Date(previousEtaDate)) {
      this.arBerths.at(index)?.get('etaActualErrorMessage')?.setValue(errorMessage);
      this.hasErrors = true;
    }

    if (previousEtaDate && currentEtaDate && new Date(currentEtaDate) < new Date(previousEtdDate)) {
      this.arBerths.at(index)?.get('etaActualErrorMessage')?.setValue(errorMessage);
      this.hasErrors = true;
    }

    if (previousEtdDate && currentEtdDate && new Date(currentEtdDate) < new Date(previousEtdDate)) {
      this.arBerths.at(index)?.get('etdDepartureErrorMessage')?.setValue(errorMessage);
      this.hasErrors = true;
    }
    if (previousEtdDate && currentEtdDate && new Date(currentEtdDate) < new Date(previousEtaDate)) {
      this.arBerths.at(index)?.get('etdDepartureErrorMessage')?.setValue(errorMessage);
      this.hasErrors = true;
    }
   }
 
   checkMatchDateTime(previousEtaDate: any, previousEtdDate: any, currentEtaDate: any, currentEtdDate: any, index: any) {
     let errorMessage = 'This date/time conflicts with another field on this form. Please check and confirm the correct date/time.';
     if (previousEtaDate && currentEtaDate && currentEtaDate.getTime() == previousEtaDate.getTime()) {
       this.arBerths.at(index)?.get('etaActualErrorMessage')?.setValue(errorMessage);
       this.hasErrors = true;
     }
 
     if (previousEtaDate && currentEtaDate && currentEtaDate.getTime() == previousEtdDate.getTime()) {
       this.arBerths.at(index)?.get('etaActualErrorMessage')?.setValue(errorMessage);
       this.hasErrors = true;
     }
 
     if (previousEtdDate && currentEtdDate && currentEtdDate.getTime() == previousEtdDate.getTime()) {
       this.arBerths.at(index)?.get('etdDepartureErrorMessage')?.setValue(errorMessage);
       this.hasErrors = true;
     }
 
     if (previousEtdDate && currentEtdDate && currentEtdDate.getTime() == previousEtaDate.getTime()) {
       this.arBerths.at(index)?.get('etdDepartureErrorMessage')?.setValue(errorMessage);
       this.hasErrors = true;
     }
   }

  checkFutureDateTime(currentIndex: any) {
    const currentBerth = this.arBerths.at(currentIndex);
    const currentEtaActualMergedDateTime = currentBerth?.get('etaActualMergedDateTime')?.value;
    const currentEtdDepartureMergedDateTime = currentBerth?.get('etdDepartureMergedDateTime')?.value;
    for (let i = 0; i < this.arBerths.length; i++) {
      if (i > currentIndex) {
        const berth = this.arBerths.at(i);
        const etaActualMergedDateTime = berth?.get('etaActualMergedDateTime')?.value;
        const etdDepartureMergedDateTime = berth?.get('etdDepartureMergedDateTime')?.value;
        let errorMessage = 'This date/time overlaps with another berth’s stay. Please check and confirm the correct date/time.';
        if (currentEtaActualMergedDateTime && etaActualMergedDateTime && currentEtaActualMergedDateTime.getTime() > etaActualMergedDateTime.getTime()) {
          this.arBerths.at(currentIndex)?.get('etaActualErrorMessage')?.setValue(errorMessage);
          this.hasErrors = true;
        }
        if (currentEtaActualMergedDateTime && etdDepartureMergedDateTime && currentEtaActualMergedDateTime.getTime() > etdDepartureMergedDateTime.getTime()) {
          this.arBerths.at(currentIndex)?.get('etaActualErrorMessage')?.setValue(errorMessage);
          this.hasErrors = true;
        }
        this.refactorCheckFutureDateTime(currentIndex, currentEtdDepartureMergedDateTime, errorMessage, etaActualMergedDateTime, etdDepartureMergedDateTime);
      }
    }
  }

  refactorCheckFutureDateTime(currentIndex: any, currentEtdDepartureMergedDateTime: any, errorMessage: any, etaActualMergedDateTime: any, etdDepartureMergedDateTime: any) {
    if (currentEtdDepartureMergedDateTime && etaActualMergedDateTime && currentEtdDepartureMergedDateTime.getTime() > etaActualMergedDateTime.getTime()) {
      this.arBerths.at(currentIndex)?.get('etdDepartureErrorMessage')?.setValue(errorMessage);
      this.hasErrors = true;
    }

    if (currentEtdDepartureMergedDateTime && etdDepartureMergedDateTime && currentEtdDepartureMergedDateTime.getTime() > etdDepartureMergedDateTime.getTime()) {
      this.arBerths.at(currentIndex)?.get('etdDepartureErrorMessage')?.setValue(errorMessage);
      this.hasErrors = true;
    }
  }

  checkEtaEtdValidation(currentIndex: any) {
    const currentBerth = this.arBerths.at(currentIndex);
    const currentEtaActualMergedDateTime = currentBerth?.get('etaActualMergedDateTime')?.value;
    const currentEtdDepartureMergedDateTime = currentBerth?.get('etdDepartureMergedDateTime')?.value;
    const etaEtdtype = currentBerth.get('type')?.value;
    if (currentEtaActualMergedDateTime && currentEtdDepartureMergedDateTime && currentEtaActualMergedDateTime.getTime() > currentEtdDepartureMergedDateTime.getTime()) {
      this.hasErrors = true;
      if (etaEtdtype == 'eta') {
        let errorMessage = 'This date/time should not be after the berth’s departure date/time. Please check and confirm the correct date/time.';
        this.arBerths.at(currentIndex)?.get('etaActualErrorMessage')?.setValue(errorMessage);
      } else {
        let errorMessage = 'This date/time should not be before the berth’s arrival date/time. Please check and confirm the correct date/time.';
        this.arBerths.at(currentIndex)?.get('etdDepartureErrorMessage')?.setValue(errorMessage);
      }
    }
  }

  checkPreviousDateTime(currentIndex: any) {
    const currentBerth = this.arBerths.at(currentIndex);
    const currentEtaActualMergedDateTime = currentBerth?.get('etaActualMergedDateTime')?.value;
    const currentEtdDepartureMergedDateTime = currentBerth?.get('etdDepartureMergedDateTime')?.value;

    for (let i = 0; i < currentIndex; i++) {
      const berth = this.arBerths.at(i);
      const etaActualMergedDateTime = berth?.get('etaActualMergedDateTime')?.value;
      const etdDepartureMergedDateTime = berth?.get('etdDepartureMergedDateTime')?.value;
      let errorMessage = 'This date/time overlaps with another berth’s stay. Please check and confirm the correct date/time.';
      if (currentEtaActualMergedDateTime && etaActualMergedDateTime && currentEtaActualMergedDateTime.getTime() < etaActualMergedDateTime.getTime()) {
        this.arBerths.at(currentIndex)?.get('etaActualErrorMessage')?.setValue(errorMessage);
        this.hasErrors = true;
      }
      if (currentEtaActualMergedDateTime && etdDepartureMergedDateTime && currentEtaActualMergedDateTime.getTime() < etdDepartureMergedDateTime.getTime()) {
        this.arBerths.at(currentIndex)?.get('etaActualErrorMessage')?.setValue(errorMessage);
        this.hasErrors = true;
      }
      this.checkEtdDateTime(currentIndex, currentEtdDepartureMergedDateTime, errorMessage, etaActualMergedDateTime, etdDepartureMergedDateTime);
    }
  }

  checkEtdDateTime(currentIndex: any, currentEtdDepartureMergedDateTime: any, errorMessage: any, etaActualMergedDateTime: any, etdDepartureMergedDateTime: any) {
    if (currentEtdDepartureMergedDateTime && etaActualMergedDateTime && currentEtdDepartureMergedDateTime.getTime() < etaActualMergedDateTime.getTime()) {
      this.arBerths.at(currentIndex)?.get('etdDepartureErrorMessage')?.setValue(errorMessage);
      this.hasErrors = true;
    }

    if (currentEtdDepartureMergedDateTime && etdDepartureMergedDateTime && currentEtdDepartureMergedDateTime.getTime() < etdDepartureMergedDateTime.getTime()) {
      this.arBerths.at(currentIndex)?.get('etdDepartureErrorMessage')?.setValue(errorMessage);
      this.hasErrors = true;
    }
  }

  checkAgainstValue(currentIndex: any) {
    const arBerths = this.portForm.get('arBerths')?.value;
    const etaActualMergedDateTime = this.arBerths.at(currentIndex)?.get('etaActualMergedDateTime')?.value;
    const etdDepartureMergedDateTime = this.arBerths.at(currentIndex)?.get('etdDepartureMergedDateTime')?.value;
    const berthSeqNo = this.arBerths.at(currentIndex)?.get('berthSeqNo')?.value;
    const berthName = this.arBerths.at(currentIndex)?.get('berthName')?.value;

    for (const berth of arBerths) {
      if ((berth.berthSeqNo !== berthSeqNo && berth.berthName !== berthName) || (berth.berthSeqNo !== berthSeqNo && berth.berthName === berthName) || (berth.berthSeqNo === berthSeqNo && berth.berthName !== berthName)) {
        let errorMessage = 'This date/time conflicts with another field on this form. Please check and confirm the correct date/time.';
        // Same ETA-ETA
        if (etaActualMergedDateTime && berth.etaActualMergedDateTime && etaActualMergedDateTime.getTime() == berth.etaActualMergedDateTime.getTime()) {
          this.arBerths.at(currentIndex)?.get('etaActualErrorMessage')?.setValue(errorMessage);
          this.hasErrors = true;
        }

        this.refactorCheckAgainstValueETD(etdDepartureMergedDateTime, currentIndex, errorMessage, berth, etaActualMergedDateTime);

      }
    }
  }

  refactorCheckAgainstValueETD(etdDepartureMergedDateTime: any, currentIndex: any, errorMessage: any, berth: any, etaActualMergedDateTime: any) {

    // Same ETA-ETD
    if (etaActualMergedDateTime && berth.etdDepartureMergedDateTime && etaActualMergedDateTime.getTime() == berth.etdDepartureMergedDateTime.getTime()) {
      this.arBerths.at(currentIndex)?.get('etaActualErrorMessage')?.setValue(errorMessage);
      this.hasErrors = true;
    }

    // Same ETD-ETD
    if (etdDepartureMergedDateTime && berth.etdDepartureMergedDateTime && etdDepartureMergedDateTime.getTime() == berth.etdDepartureMergedDateTime.getTime()) {
      this.arBerths.at(currentIndex)?.get('etdDepartureErrorMessage')?.setValue(errorMessage);
      this.hasErrors = true;
    }

    // Same ETD-ETA
    if (etdDepartureMergedDateTime && berth.etaActualMergedDateTime && etdDepartureMergedDateTime.getTime() == berth.etaActualMergedDateTime.getTime()) {
      this.arBerths.at(currentIndex)?.get('etdDepartureErrorMessage')?.setValue(errorMessage);
      this.hasErrors = true;
    }
  }

  getBerthGroupEtaType(type:any): boolean {
    return type == 'eta';
  }

  getBerthGroupEtdType(type:any): boolean {
    return type == 'etd';
  }

  checkApiErrorMessage(berthSeqNo: any, berthGroup: any, berthName: any, actualDateArivalDisplay: any, actualTimeArivalDisplay: any, actualDateDepartureDisplay: any, actualTimeDepartureDisplay: any) {
    if (actualDateArivalDisplay && actualTimeArivalDisplay && actualDateDepartureDisplay && actualTimeDepartureDisplay && !this.hasErrors && !this.hasSameErrors) {
      let payload = {
        "vesVoy": this.berthDetails.vesVoy,
        "portNum": this.berthDetails.portNo,
        "seqNum": this.berthDetails.seqNo,
        "berthSeqNum": berthSeqNo,
        "berthName": berthName,
        etaDate: this.toLocalISOFormat(new Date(actualDateArivalDisplay), new Date(actualTimeArivalDisplay)),
        etdDate: this.toLocalISOFormat(new Date(actualDateDepartureDisplay), new Date(actualTimeDepartureDisplay)),
        "portEtaDate": this.berthDetails.dtETA,
        "portEtdDate": this.berthDetails.dtETD,
        isEtaDate: this.getBerthGroupEtaType(berthGroup.value.type),
        isEtdDate: this.getBerthGroupEtdType(berthGroup.value.type),
      }
      this.activeApiRequests = (this.activeApiRequests || 0) + 1;
      this.hasErrorsApiLevel = true;
      this.etbInternalService.validateBerthETdates(payload).subscribe({
        next: (res: any) => {
          // Decrement the counter when a request completes
          this.activeApiRequests -= 1;
          if (this.activeApiRequests === 0) {
            this.hasErrorsApiLevel = false;
          }
          if (!this.hasErrors && !this.hasSameErrors) {
            berthGroup.patchValue({
              etaActualErrorMessage: "",
              etdDepartureErrorMessage: "",
              vslLevelErrorMessage: "",
            });
          }
        }, error: err => {
          let errorType = err.error.error.field;
          // Set error messages
          // Decrement the counter when a request completes
          this.activeApiRequests -= 1;
          this.hasErrorsApiLevel = true;
          if (!this.hasErrors && !this.hasSameErrors) {
            berthGroup.patchValue({
              etaActualErrorMessage: errorType?.etaDate,
              etdDepartureErrorMessage: errorType?.etdDate,
              vslLevelErrorMessage: errorType?.vslLevel,
            });
          }
        }
      })
    }
  }
  // End form code

  // Function to start a session and set expiration time
  startSession() {
    const now = new Date().getTime();
    const expirationTime = now + 20 * 60 * 1000; // 20 minutes in milliseconds
    localStorage.setItem('sessionExpiration', expirationTime.toString());
    console.log('Session started, will expire in 20 minutes.');
  }

  isSessionValid() {
    const now = Date.now();
    const expirationTimeStr = localStorage.getItem('sessionExpiration');
    const expirationTime = expirationTimeStr ? parseInt(expirationTimeStr, 10) : null;

    if (expirationTime && now < expirationTime) {
      const remainingTime = expirationTime - now;
      if (remainingTime <= 120000 && this.openModel != true) { // 2 minutes in milliseconds
        console.log('Your session will expire in 2 minutes due to inactivity. Please select \'Extend\' to continue your session');
        if(this.isSessionInactivityModalCloseButtonClicked == false) this.openModel = true;
      }
      const remainingMinutes = Math.ceil(remainingTime / 60000); 
      console.log(`Session is still valid. Remaining time: ${remainingMinutes} minutes (${remainingTime} milliseconds)`);
      return true;
    } else {
        console.log('Session has expired');
        if(this.isSessionExpired == false) this.closeSession();
        return false;
    }
  }

  getRemainingTime() {
    const now = Date.now();
    const expirationTimeStr = localStorage.getItem('sessionExpiration');
    const expirationTime = expirationTimeStr ? parseInt(expirationTimeStr, 10) : null;

    if (expirationTime && now < expirationTime) {
      return expirationTime - now;
    } else {
      return 0;
    }
  }

  // Function to reset the session expiration time on user interaction
  @HostListener('window:click')
  @HostListener('window:keydown')
  @HostListener('window:scroll')
  // @HostListener('window:mousemove')
  resetSessionExpiration() {
      const remainingTime = this.getRemainingTime();
      if (remainingTime > 120000)  {
        const now = new Date().getTime();
        const expirationTime = now + 20 * 60 * 1000; // 20 minutes in milliseconds
        console.log("expirationTime::",expirationTime);
        localStorage.setItem('sessionExpiration', expirationTime.toString());
        console.log('Session expiration time reset.');
        this.isSessionExpired = false;
      }
  }

  extendSession() {
    this.openModel = false;
    this.startSession();
  }

  closeSession() {
    console.log('Session closed');
    this.openModel = false;
    this.isSessionExpired = true;
    this.updateTokenInfo(this.tokenLink);
    localStorage.removeItem('sessionExpiration');
  }

  updateTokenInfo(token:any){
    const tokenData = { 
      token:token, 
      data: { 
        isLinkOpen:false, 
        sessionEndDateTime: new Date().toISOString(),
        otp: null
      } 
    };
    // console.log("tokenData::", tokenData);
    const requestBody = this.objectToRequestBody(tokenData);
    // console.log("requestBody::", requestBody);
    this.otpService.updateTokenInfo(requestBody).subscribe({next:(res:any) => {
    }, error:err => {
      this.navigationService.errorOccure(err);
      this.router.navigate(['/404']);
    }})
  }

  objectToRequestBody(obj: { [key: string]: any }): string {
    const params = new URLSearchParams();
  
    function recursiveAppend(obj: { [key: string]: any }, prefix = '') {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          const value = obj[key];
          if (value instanceof Date) {
            params.append(`${prefix}${key}`, value.toISOString());
          } else if (typeof value === 'object' && value !== null) {
            recursiveAppend(value, `${prefix}${key}.`);
          } else {
            params.append(`${prefix}${key}`, value === null ? 'null' : value === undefined ? 'undefined' : value.toString());
          }
        }
      }
    }
  
    recursiveAppend(obj);
    return params.toString();
  }

  closeSessionInactivityModal() {
    this.openModel = false;
    this.isSessionInactivityModalCloseButtonClicked = true;
  }

}
