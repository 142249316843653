export enum  InProgressFilter {
    delays = 'delays',
    vessel = 'vessel',
    port = 'port',
    dport= 'dport',
    charterer = 'charterer',
    tradelane = 'tradelane',
    broker = 'broker',
    bookingid = 'bookingid',
    bookingVariance = "bookingVariance",
    dateRange="dateRange",
    timezone = "timezone",
    company = 'company',
    fleet = 'fleet',
    daterange ='daterange',
    organization = 'organization',
    module = 'module',
    active = 'active',
    status = 'status',
    charterers = 'charterers',
    userRole = 'userRole',
    category = 'category',
    voyage = 'voyage',
    berth = 'berth',
    alert = 'alert'
   }

   export enum  ShareAndDownload {
    download = 'download',
    scc ='scc',
    share= 'share',
    agmShare= 'agmShare',
    agmDownload = 'agmDownload',
    pocDownload = 'pocDownload',
    pocShare = 'pocShare',
    agmShareMbl= 'agmShareMbl',
  }

  export enum  EtaUsed {
    booking = 'booking',
    bookingDetail = 'bookingDetail',
  }

  export enum CpBlCheck{
    enRoute= 'En-route to load',
    loading= 'In port-load',
  }

  export enum EmailSharing{
    toObj= 'to',
    ccObj= 'cc',
    bccObj = 'bcc'
  }