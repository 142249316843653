import { Component, ElementRef, OnDestroy, OnInit, ViewChild ,ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { interval, Subscription, take } from 'rxjs';
import { otpValidator } from 'src/app/helpers/multipleEmail';
import { NavigationService } from 'src/app/services/navigation.service';
import { OtpService } from 'src/app/services/otp.service';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-etb-otp-verification',
  templateUrl: './etb-otp-verification.component.html',
  styleUrls: ['./etb-otp-verification.component.scss']
})
export class EtbOtpVerificationComponent implements OnInit, OnDestroy {
  OtpVerificationForm: UntypedFormGroup;
  openModel: boolean = true
  timeLeft: number = 600; // 10 minutes in seconds
  timerSubscription!: Subscription;
  timeExpired: boolean = false;
  isLinkEnabledAfterMin: boolean;
  isrequestNewOtpLink: boolean;
  userEmail: any;
  attemptsCount: number = 5;
  invalidOTP: boolean = false;
  maxAttemptsExceeded: boolean = false;
  snlModalSettings = { showCloseIcon: false, backdropClick: false }
  isthreeAttempts:boolean = false;
  isNewLink:boolean = false;
  getToken:any
  wrongOTPCount: number;
  SECRETKEY= environment.secretKeyOtp
  portAgentEmail:any=null;
  isLinkOpen = false;
  isLinkExpFor30min:boolean=false;
  constructor(
    private fb: UntypedFormBuilder,
    private navigationService: NavigationService,
    public otpService: OtpService,
    private router:Router,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef
  ) {
    this.navigationService.setShowNav(false);
    this.navigationService.setPageTitle('Port-agent');
  }

  ngOnInit(): void {
    // this.getModalState();
    this.route.params.subscribe({next:(paramsId:any) => {
      console.log("paramsId::",paramsId.id)
      this.getToken = paramsId.id;
      this.refctorOtpVeificationWithToken(this.getToken)
    }, error:err => {
      this.navigationService.errorOccure(err);
      this.router.navigate(['/404']);
    }})    
  }

  refctorOtpVeificationWithToken(token:any){
    let tokenData = {token:token,isNewLink:this.isNewLink}
    this.otpService.portagentOtpVeificationWithToken(tokenData).subscribe({next:(res:any) => {
      console.log("res::::::",res)
      this.isLinkOpen = res.data.isLinkOpen;
      this.openModel = this.isLinkOpen ? false : true;

      console.log(" this.openModel>>>>>>>>.", this.openModel);
      
      if (res && res.data) {
       
        if(!res.data.isSuccess){
          console.log("111")
          this.isLinkExpFor30min = res.data.isLinkExpFor30min;
          this.getModalState();
        }
        else{
          console.log("2222")
          console.log(res.data);
          this.attemptsCount = res.data.attemptCount
          this.portAgentEmail = res.data.portAgentEmail
          this.startTimer();
          this.createOtpForm()
          this.enabledRequestLinkAfterMin();
          this.getModalState();
        }
        
       
      }else{
        this.router.navigate(['/404']);
      }
    }, error:err => {
      this.navigationService.errorOccure(err);
      this.router.navigate(['/404']);
    }})
  }

  updateTokenInfo(token:any){
    const tokenData = { 
      token:token, 
      data: { isLinkOpen:true, sessionStartDateTime: new Date().toISOString() } 
    };
    const requestBody = this.objectToRequestBody(tokenData);
    this.otpService.updateTokenInfo(requestBody).subscribe({next:(res:any) => {
    }, error:err => {
      this.navigationService.errorOccure(err);
      this.router.navigate(['/404']);
    }})
  }

  objectToRequestBody(obj: { [key: string]: any }): string {
    const params = new URLSearchParams();
  
    function recursiveAppend(obj: { [key: string]: any }, prefix = '') {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          const value = obj[key];
          if (value instanceof Date) {
            params.append(`${prefix}${key}`, value.toISOString());
          } else if (typeof value === 'object' && value !== null) {
            recursiveAppend(value, `${prefix}${key}.`);
          } else {
            params.append(`${prefix}${key}`, value === null ? 'null' : value === undefined ? 'undefined' : value.toString());
          }
        }
      }
    }
  
    recursiveAppend(obj);
    return params.toString();
  }


  createOtpForm(){
    this.OtpVerificationForm = this.fb.group({
      verifyotp: ['', [Validators.required, otpValidator()]],
    });
  }


  enabledRequestLinkAfterMin() {
    setTimeout(() => {
      this.isLinkEnabledAfterMin = true; // Enable request new OTP link after 1 minute
    }, 60000);
  }

  startTimer() {
    this.timeLeft = 300; // Reset to 5 minutes
    this.timeExpired = false;
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe(); // Clear previous timer if exists
      console.log("timerSubscription:: stotp",this.timerSubscription)
    }
    this.timerSubscription = interval(1000)
      .pipe(take(this.timeLeft))
      .subscribe(() => {
        this.timeLeft--;
        if (this.timeLeft === 0) {
          this.timeExpired = true;
          this.getModalState();
        }
      });
  }

  // Convert timeLeft to minutes and seconds
  get formattedTime(): string {
    const minutes: number = Math.floor(this.timeLeft / 60);
    const seconds: number = this.timeLeft % 60;
    return `${minutes > 9 ? minutes : '0' + minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  }


  get f() {
    return this.OtpVerificationForm.controls;
  }


  resendOtpByLink() {
    this.isNewLink = true
    this.invalidOTP = false
    this.isrequestNewOtpLink = true
    this.isLinkEnabledAfterMin = false
    this.enabledRequestLinkAfterMin();
    this.startTimer()
    this.OtpVerificationForm.reset(this.OtpVerificationForm.value);
    let tokenData = {token:this.getToken,isNewLink:this.isNewLink}
    this.otpService.portagentOtpVeificationWithToken(tokenData).subscribe({next:res => {
      if (res && res.data) {
        if(!res.data.isSuccess){
          this.isLinkExpFor30min = res.data.isLinkExpFor30min;
          console.log("this.isLinkExpFor30min>>>>>>..",this.isLinkExpFor30min);
          this.getModalState();
        }else{
          this.attemptsCount = res.data.attemptCount;
          this.getModalState();
        }
       
        console.log(res);
      }
  }, error:err => {
    this.navigationService.errorOccure(err);
  }
})
  }

  onSubmitOtp() {
    this.invalidOTP = false
    console.log(this.OtpVerificationForm.value)
    if (this.OtpVerificationForm.invalid) {
      return;
    } else {
      this.isNewLink = false
      console.log(this.OtpVerificationForm.value)
      
      let otp = this.OtpVerificationForm.value.verifyotp;
      // const hash = CryptoJS.HmacSHA256(otp, 'secret');
// Convert the hash to Base64 and make it URL-safe
// let token = CryptoJS.enc.Base64.stringify(hash)
      const encryptedOtp = CryptoJS.AES.encrypt(otp, this.SECRETKEY).toString();
      let drypt = CryptoJS.AES.decrypt(encryptedOtp, this.SECRETKEY).toString(CryptoJS.enc.Utf8);
      // .replace(/\+/g, '-')
      // .replace(/\//g, '_')drypt
      ;
      console.log('Encrypted:', encryptedOtp);
      console.log('drypt:', drypt);
      let tokenData = {token:this.getToken,OTP:encryptedOtp,isNewLink:this.isNewLink}
      this.otpService.verifyPortAgentOTP(tokenData).subscribe({next:res => {
        console.log(res);
        if(res && res.data){
          if(res.data?.isSuccess){
          this.updateTokenInfo(this.getToken);
          this.router.navigate(['/port-agent-etb-detail-update']);
          // , { queryParams: {token: this.getToken } }
        }else{
          this.invalidOTP = true
          this.wrongOTPCount = res.data?.wrongOTPCount
          this.getModalState();
        }
      }
    }, error:err => {
      this.navigationService.errorOccure(err);
    }})
    }
  }


  requestforNewOtp() {
   
    // if(obj == "isIncorrectAttempt"){

    // }else{
      this.invalidOTP = false;
      this.isNewLink = false
      this.timeExpired = false;
      this.startTimer()
      this.isLinkEnabledAfterMin = false
      this.enabledRequestLinkAfterMin()
      this.createOtpForm()
      let tokenData = { token: this.getToken, isNewLink: this.isNewLink }
      this.otpService.portagentOtpVeificationWithToken(tokenData).subscribe({
        next: res => {
          if (res && res.data) {
            this.attemptsCount = res.data.attemptCount
            console.log(res);
            this.getModalState();
          }
        }, error: err => {
          this.navigationService.errorOccure(err);
        }
      })
      this.getModalState();
    // }
    
   
  }

  refacrOtpVeificationWithToken(){
    this.otpService.portagentOtpVeificationWithToken(this.getToken).subscribe({next:res => {
      if (res && res.data) {
        this.attemptsCount = res.data.attemptCount
        console.log(res);
      }
  }, error:err => {
    this.navigationService.errorOccure(err);
  }})
  }

  onKeyUp(eve: any) {
    this.isrequestNewOtpLink =  false
    this.invalidOTP= false
  }

  ngOnDestroy(): void {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }


  onInput(event: any): void {


    // const inputElement = event.target as HTMLInputElement;
    // if (!/^\d+$/.test(inputElement.value)) {
    //   console.log("inputElement.value::",inputElement.value)
    //   // If not all characters are digits
    //   inputElement.value = inputElement.value.replace(/[^0-9]/g, '').slice(0, 6);
    //   this.OtpVerificationForm.get('verifyotp')?.setErrors({ notNumeric: true });
    // }else{
    //   inputElement.value = inputElement.value.replace(/[^0-9]/g, '').slice(0, 6);
    //   this.OtpVerificationForm.get('verifyotp')?.setValue(inputElement.value);
    // }
    const input = event.target as HTMLInputElement;
    const value = input.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
    // Limit input to 6 characters
    if (value.length > 6) {
      input.value = value.slice(0, 6);
  }
  this.OtpVerificationForm.get('verifyotp')?.setValue(input.value, { emitEvent: false });

}

isActive:boolean = false;
otpExpired:boolean = false;
isShowSection = "";
getModalState() {
  console.log("getModalState hit");
  if (this.timeExpired && this.attemptsCount > 5) {
    console.log("otpExpiredAndMaxAttempts")
    this.isShowSection = 'otpExpiredAndMaxAttempts';
    // return 'otpExpiredAndMaxAttempts';
  } else if (this.timeExpired) {
    console.log("otpExpired")
    this.isActive = false;
    console.log("this.isActive>>>>>>>",this.isActive);
    this.otpExpired = true;
    this.isShowSection = 'otpExpired';
  //  this.getModalState();
    // return 'otpExpired';
    
    
  } else if ((!this.timeExpired && this.attemptsCount > 5) || this.isLinkExpFor30min) {
    console.log("isLinkExpFor30min")
    this.isShowSection = 'isLinkExpFor30min'
    // this.getModalState()
    this.isActive = false;
    // return 'isLinkExpFor30min';
  }else if (this.wrongOTPCount >= 3 || this.attemptsCount > 5) {
    console.log("maxAttemptsReached")
    this.isActive = false;
    this.isShowSection = 'maxAttemptsReached';
    // return 'maxAttemptsReached';
  } else {
    this.isActive = true
    this.isShowSection = 'active';
    console.log("active")
    // return 'active';
  }
  
}

}
