export const environment = {
    production: true,
    API_BASE_URL: 'https://api.stoltdev.com/stdigital',
    B2C_CLIENTID:'38b93384-ea01-437d-9652-c90eb0de00c8',
    AUTHORITY:'https://stoltdevb2c.b2clogin.com/stoltdevb2c.onmicrosoft.com/B2C_1A_STDIGITAL_SIGNUP_SIGNIN',
    KNOWNAUTHORITIES:['stoltdevb2c.b2clogin.com'],
    USERSCOPE:'https://stoltdevb2c.onmicrosoft.com/fcd8c4d3-9fef-4157-9d01-bc75081125f1/read',
    GRAPH_ENDPONT:'https://graph.microsoft.com/v1.0/me',
    REDIRECT_URI:'https://stdigital.stoltdev.com/booking',
    POST_LOGOUT_REDIRECT_URI:'https://stdigital.stoltdev.com',
    FRONTEND_GOOGLE_MAPS:'AIzaSyD-JpB5eMpvA___hJrmGB0I95DzpI8w37w',
    AUTH_SERVICE_BASE_NAME:'/auth',
    BOOKING_SERVICE_BASE_NAME:'/booking',
    MASTER_SERVICE_BASE_NAME:'/master',
    SUSTAINABILITY_SERVICE_BASE_NAME:'/sustainability',
    VESSELTRACKING_SERVICE_BASE_NAME:'/vessel-tracking',
    CAPTCHAKEY:'6LfE1qIkAAAAAGGQcSHBz0elWhu8WbTnxcFI9dOX',
    appInsights: {
      instrumentationKey: 'cc52ace8-e8df-486f-b6f1-9e70dd631958'
    },
    navType:'dev',
    googleAnalytic:'dev',
    googleAnalyticKey :'G-GZ7V4GX57J',
    googleTagManager:'GTM-K4FR8LH',
    googleAnalyticUAKey:'UA-252381227-1',
    DOCUMENTATION_BASE_URL: 'https://app.laytimedesk.com/pdms-api/external/download',
    DOCUMENTATION_POC_KEY:"ebef5516-2505-4229-ae4b-c11aacc380e0",
    POOLING_PORTAL_URL: "https://stpoolingportal.stoltdev.com/dashboard",
    apiurl:'https://api.stoltdev.com',
    DIMENSIONS:{'dimension1': 'userLogin',
    'dimension2': 'userCompany',
    'dimension4': 'userName',
    'dimension5': 'userType'},
    GLOBALCOMPANY : 5943082716,
    IP_URL:"https://ipwhois.app/json/",
    USERSEMAIL : ['HNP@stolt.com','ahg@stolt.com','ish@stolt.com','GAI@STOTL.COM','HML@stolt.com'],
    bookingFilterRegex:/ *\([^)]*\) */g,
    googleVerificationKey:"JqNZszfQpHymZEB0sg6VuBeWh42XXWHoZC2L83sa2EY"
  };