import { Component, ElementRef } from '@angular/core';
import { CalendarOptions, EventApi } from '@fullcalendar/core';
import { BehaviorSubject } from 'rxjs';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import { ActivatedRoute, Router } from '@angular/router';
import { PublicPagesService } from 'src/app/services/public-pages.service';
import { NavigationService } from 'src/app/services/navigation.service';
import moment from 'moment';
@Component({
  selector: 'app-calendar-preview',
  templateUrl: './calendar-preview.component.html',
  styleUrls: ['./calendar-preview.component.scss']
})
export class CalendarPreviewComponent {
isCurrentTime:boolean;
customEvent: any = [];
customeResouces: any = [];
cursorX = 0;
cursorY = 0;
tooltipTitle: any;
tooltipData:any;
isScroll: boolean = true;
isFilterSkeleton: boolean = true;
todayDate = new Date();
  private calendarVisibleSubject = new BehaviorSubject(true);
  private calendarOptionsSubject = new BehaviorSubject<CalendarOptions>({
    schedulerLicenseKey: '0443312849-fcs-1707325548',
    plugins: [
      resourceTimelinePlugin,
    ],
    initialView: 'resourceTimeline',
    visibleRange: this.getVisibleRange(),
    slotMinWidth: 16,
    resourceAreaWidth: 240,
    height: 'auto',
    eventMinWidth: 4,
    slotLabelFormat: [
      { month: 'short', weekday: 'short', day: 'numeric' },
      { hour: 'numeric', hour12: false },
    ],
    slotDuration: '02:00',
    nowIndicator: true,
    editable: false,
    selectable: true,
    selectMirror: true,
    dayMaxEvents: true,
    weekends: true,
    scrollTime: '24:00:00',
    resourceAreaColumns: [
      { field: 'vesselVoyNo', headerContent: 'Vessel / Voyage' },
    ],
    resources: [],
    resourceLabelContent: (arg) => {
      return {
        html: `
          <div class='resource-label'>
            <label>
              <div class='title'>${this.toTitleCase(arg.resource.extendedProps['vesselVoyNo'])}</div>
              <div class='subtitle'>${this.toTitleCase(arg.resource.extendedProps['portName'])}</div>
              <div class='port'>
              <span class="PortStayTxt">Port ETA-ETD:</span>
              ${arg.resource.extendedProps['portStay']}
              </div>
            </label>
          </div>
        `,
      };
    },

    events: [],
    eventClassNames: this.getEventClassNames.bind(this), // Apply custom classes
    eventDidMount: this.applyEventStyles.bind(this), // Apply dynamic styles
    viewDidMount: (view) => {
      this.addCustomClassToDate();
    },
    nowIndicatorDidMount: this.nowIndicatorDidMount.bind(this),
  });
  currentEvents = new BehaviorSubject<EventApi[]>([]);
  constructor( private navigationService: NavigationService, private el: ElementRef,  private publicPagesService: PublicPagesService, private route: ActivatedRoute, private router: Router) {
    this.navigationService.setPageTitle('Berth Schedule');
  }
  private scrollableElement!: any;
  isShow: boolean = false;
  ngOnInit() {
      this.addCustomBodyClass();
      this.route.params.subscribe(paramsId => {
        const token = paramsId.id;
        this.publicPagesService.getShareEtbCustomer(token).subscribe({
          next: (res: any) => {
            this.isFilterSkeleton = false;
            if (res.status == 200) {
              this.customEvent = res.data.etbList.events;
              this.customeResouces = res.data.etbList.resources;
              setTimeout(()=>{
                this.updateResources(this.customeResouces);
                this.updateEvents(this.customEvent);
                this.updateVisibleRangeDynamically(res.data.dateRange);
                this.isScroll = true;
                setTimeout(() => {
                  this.scrollOnCurrentDate(res.data.filterDateRange, res.data.dateRange);
                }, 100);
              },100)
            }
          }, error: err => {
            this.isFilterSkeleton = false;
            console.log(err);
            this.router.navigateByUrl('/404');
          }
        })

      });
  }

  // Function to update visibleRange dynamically after a delay
 updateVisibleRangeDynamically(dateRange:any) {
    const updatedVisibleRange = this.getVisibleRangeDynamically(dateRange);

    // Update the calendar options with the new visibleRange
    const updatedOptions = {
      ...this.calendarOptionsSubject.value, // Get the current options
      visibleRange: updatedVisibleRange,    // Set the new visibleRange
    };

    // Emit the updated options to the BehaviorSubject
    this.calendarOptionsSubject.next(updatedOptions);
}

  addCustomBodyClass(){
    let ele: any = document.querySelector("body");
    if (ele) {
      ele.classList.add('calendar-tooltip');
    }
  }

  addCustomClassToDate() {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}T00:00:00`;
    setTimeout(() => {
      const dateElements = document.querySelectorAll(`[data-date="${formattedDate}"]`);
      dateElements.forEach((element: any) => {
        element.classList.add('my-custom-class');
      });
    }, 0);
  }

  scrollOnCurrentDate(filterDateRange:any, dateRange:any){
    let startDate = moment(dateRange.fromDate, 'YYYY-MM-DD');
    let fromDate = moment(filterDateRange.fromDate, 'YYYY-MM-DD');
    let dayDifference = fromDate.diff(startDate, 'days');
    setTimeout(() => {
      let x = dayDifference == 0 ? 2 : dayDifference - 1;
      this.scrollableElement = this.el.nativeElement.querySelectorAll('.fc-scroller');
      if (this.scrollableElement.length) {
        this.scrollableElement[5].scrollBy({
        left: 192 * x,
        });
        this.isShow = true;
      } else {
        this.isShow = true;
      }
    }, 600)
  }

  toTitleCase(str: any){
    return str
      .toLowerCase()
      .split(' ')
      .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  updateResources(resources:any) {
    const newOptions = {
      ...this.calendarOptionsSubject.getValue(),
      resources: resources,
      resourceOrder: '' // This prevents FullCalendar from sorting the resources.
    };
    this.calendarOptionsSubject.next(newOptions);
  }

  updateEvents(events:any) {
    const newOptions = {
      ...this.calendarOptionsSubject.getValue(),
      events: events,
    };
    this.calendarOptionsSubject.next(newOptions);
  }

  get calendarVisible() {
    return this.calendarVisibleSubject.value;
  }

  get calendarOptions() {
    return this.calendarOptionsSubject.value;
  }

  getVisibleRange() {
    const today = new Date();
    const start = new Date(today);
    start.setDate(today.getDate() - 4);

    const end = new Date(start);
    end.setDate(start.getDate() + 15);
    return {
      start: start.toISOString().split('T')[0],
      end: end.toISOString().split('T')[0]
    };
  }

  getVisibleRangeDynamically(dateRange:any) {
    const start = new Date(dateRange.fromDate);
    const end = new Date(dateRange.toDate);
    end.setDate(end.getDate() + 1);
    return {
      start: start.toISOString().split('T')[0],
      end: end.toISOString().split('T')[0]
    };
  }

  getEventClassNames(eventContent: any) {
    return [eventContent.event.extendedProps.className];
  }

  eventData:any;
  applyEventStyles(eventContent: any) {
    const el = eventContent.el;
    el.style.backgroundColor = eventContent.event.extendedProps.backgroundColor;
    el.style.height = eventContent.event.extendedProps.height;
    el.style.width = eventContent.event.extendedProps.width;
    // Handle mouseover event
    el.addEventListener('mouseover', () => {
      this.tooltipTitle = eventContent.event.title;
      if(this.tooltipTitle){
      let vesselVoyNo = eventContent.event.extendedProps.vesselVoyNo;
      let actualDateArival = eventContent.event.extendedProps.actualDateArival;
      let estimatedDateArival = eventContent.event.extendedProps.estimatedDateArival;
      this.tooltipData = this.customEvent.find((event: any) => {
        return this.tooltipTitle === event.title && vesselVoyNo === event.vesselVoyNo && (actualDateArival == event.actualDateArival || estimatedDateArival == event.estimatedDateArival) ;
      });
    }
    });

    // Handle mouseleave event
    el.addEventListener('mouseleave', () => {
      this.tooltipTitle = null;
      this.tooltipData = null;
    });
  }

  nowIndicatorDidMount(info:any) {
    const nowIndicatorElement = info.el;
    nowIndicatorElement.addEventListener('mouseenter', () => {
    this.todayDate = new Date();
    this.isCurrentTime = true;
    });
    nowIndicatorElement.addEventListener('mouseleave', () => {
    this.isCurrentTime = false;
    });
  
    // current time line move
    const computedStyle = window.getComputedStyle(nowIndicatorElement);
    const leftValue = computedStyle.getPropertyValue('left');
    const currentLeftValue = parseFloat(leftValue); 
    let customPixelValue: any;
    let newLeftValue: any
    const now = new Date();
    const currentMinute = now.getMinutes(); // Extract the current minute
   if(currentMinute < 5){
    customPixelValue = 1;
    newLeftValue = currentLeftValue + customPixelValue;
    info.el.style.left = `${newLeftValue}px`;
   }else if(currentMinute < 10){
    customPixelValue = 2;
    newLeftValue = currentLeftValue + customPixelValue;
    info.el.style.left = `${newLeftValue}px`;
   }
   else if (currentMinute < 15) {
      customPixelValue = 3;
      newLeftValue = currentLeftValue + customPixelValue;
      info.el.style.left = `${newLeftValue}px`;
  }else if (currentMinute < 30) {
      customPixelValue = 4;
      newLeftValue = currentLeftValue + customPixelValue;
      info.el.style.left = `${newLeftValue}px`;
  }else if (currentMinute < 45) {
      customPixelValue = 5;
      newLeftValue = currentLeftValue + customPixelValue;
      info.el.style.left = `${newLeftValue}px`;
  }else if (currentMinute < 60) {
      customPixelValue = 6;
      newLeftValue = currentLeftValue + customPixelValue;
      info.el.style.left = `${newLeftValue}px`;
  }
  }
  
  // Helper method to format date
  formatDate(date: Date): string {
  return new Intl.DateTimeFormat('en-US', {
      day: '2-digit',
      month: 'short',
      year: 'numeric'
  }).format(date);
  }
  
  // Helper method to format time
  formatTime(date: Date): string {
  return new Intl.DateTimeFormat('en-GB', { // Use 'en-GB' for 24-hour format
      hour: '2-digit',
      minute: '2-digit'
  }).format(date);
  }

  onMouseMove(event: MouseEvent): void {
  this.cursorX = event.pageX;  // X position from the left of the page
  this.cursorY = event.pageY;  // Y position from the top of the page
  const screenWidth = window.innerWidth;
  if (this.cursorX > screenWidth * 0.8) {
    this.cursorX = this.cursorX - 250;
  }
  }

}