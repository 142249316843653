import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Sidenavdirection } from './enums/sidenavdirection';
import { NavigationService } from './services/navigation.service';
import { AuthService } from './services/auth.service';
import {MyMonitoringService} from './services/my-monitoring.service';
import  {environment}  from '../environments/environment';
import { Router, NavigationEnd } from '@angular/router';
import { StorageService } from './services/storage.service';
import moment from 'moment';
import { Meta } from '@angular/platform-browser';

declare const gtag: Function;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent  implements OnInit {
  googleTagManagerId= environment.googleTagManager;
  dirVal=Sidenavdirection.Left;
  isSideBarActive = false;
  showSideNav: Observable<boolean> ;
  showMobileNav: Observable<boolean> ;

  isCookies: boolean;
  subSectionCookie= false;
  isCookieActive : boolean;
  onlineOffline: boolean;
  isPopup: boolean = false;
  isCollaps:boolean = false
  public navigationUrl:any = '';
  isThankYouPage:any;
 constructor(public authService: AuthService,public navigationService:NavigationService,private myMonitoringService:MyMonitoringService,private router: Router, private _storageService: StorageService){
  this.showSideNav = this.navigationService.getShowNav();
  this.showMobileNav =this.navigationService.isMobileNavOpen();
  this.addGAScript();
 

  this.checkPopupWithDate();
    this.router.events.subscribe(event => {
      if(event instanceof NavigationEnd){
        let routeUrl = event.urlAfterRedirects.split('/');
        this.isThankYouPage = event.urlAfterRedirects.split('?')[0];
        this.getCookies();
        if(routeUrl[1] == 'documentation')
          this.navigationUrl =  routeUrl[1]+'/list';
        else{
          this.navigationUrl =  routeUrl[1];
        }
          if(!event.urlAfterRedirects.includes('booking')){
            this._storageService.removeDataFromStorage('isMapActive');
        }
        gtag('event', 'page_view', 
            {
                page_path: event.urlAfterRedirects
            }
        );
    }
    })

    this.navigationService.isNavigationCollapseGet().subscribe({
      next: res => {
        this.isCollaps = res
      }, error: err => {
        this.isCollaps = false
      }
    })
 }

 ngDoCheck(){
   this.onlineOffline = navigator.onLine;
}
ngOnInit() {
  console.log("........")
}

 /** Add Google Analytics Script Dynamically */
 addGAScript() {
  let gmap: HTMLScriptElement = document.createElement('script');
  gmap.async = true;
  gmap.src = `https://maps.googleapis.com/maps/api/js?key=${environment.FRONTEND_GOOGLE_MAPS}`;
  document.head.prepend(gmap);
  if(environment.googleAnalytic ==='production' || environment.googleAnalytic ==='uat' || environment.googleAnalytic ==='local' || environment.googleAnalytic ==='dev'){
  (function(w:any,d:any,s:any,l:any,i:any){w[l]=w[l]||[];
    w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
    let f:any=d.getElementsByTagName(s)[0];
    let j:any=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
    j.async=true;
    j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer',`${environment.googleTagManager}`);
  let gtagScript: HTMLScriptElement = document.createElement('script');
  gtagScript.async = true;
 
  gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.googleAnalyticKey;
  document.head.prepend(gtagScript);

  let gUAtagScript: HTMLScriptElement = document.createElement('script');
  gUAtagScript.async = true;
  gUAtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=' +  environment.googleAnalyticUAKey;
  document.head.prepend(gUAtagScript);
  gtag('config', environment.googleAnalytic, { send_page_view: false });
  gtag('config', environment.googleAnalyticUAKey,{
    'custom_map': environment.DIMENSIONS
 });

 
 let noscript = document.createElement('noscript')
  let iframe = document.createElement('iframe');
  iframe.setAttribute('src',`https://www.googletagmanager.com/ns.html?id=${environment.googleTagManager}`)
  iframe.setAttribute("height", "0")
  iframe.setAttribute("width", "0")
  noscript.appendChild(iframe)
  document.body.appendChild(noscript);
  }
}

addCookies(){
  localStorage.setItem('isCookies', JSON.stringify(true));
  this.getCookies();
}

showSubSectionCookie(){
  this.subSectionCookie = !this.subSectionCookie;       
}

getCookies(){
  if(this.isThankYouPage !== '/thank-you'){
    setTimeout(()=> this.isCookieActive =true,5000);
    const isCookiesCheck = localStorage.getItem('isCookies');
      if (isCookiesCheck) {
        this.isCookies= JSON.parse(isCookiesCheck);
      }else{
        this.isCookies= false;
      }
  }
}

 sideBarToggle(val:any){
  console.log("sideBarToggle")
  this.isSideBarActive = val
 }

 logout() {
  this.authService.logout();
}

checkPopupWithDate(){
  const currentDate = moment(new Date()).format("DD/MM/YYYY");
  if(currentDate == '12/12/2023' || currentDate == '13/12/2023' || currentDate == '14/12/2023'){
   this.openPopUp();
  }
}

openPopUp(){
  let getTimer:any = localStorage.getItem('timerAdded2Hr');
  if(getTimer){
    const convertToDate = new Date(getTimer);
    let currentTime = new Date();
    if(currentTime < convertToDate){
      this.isPopup = false;
    }else {
      this.isPopup = true;
    }
  }else {
    this.isPopup = true;
  }
}

closePopup(){
  let timerAdded2Hr:any = new Date()
  timerAdded2Hr.setHours(timerAdded2Hr.getHours() + 2);
  localStorage.setItem('timerAdded2Hr',timerAdded2Hr);
  this.isPopup = false;
}

addGoogleVerification(){
  console.log("........")
}
}
