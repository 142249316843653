import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormArray, FormGroup, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ReCaptcha2Component } from 'ngx-captcha';
import { ToastrService } from 'ngx-toastr';
import { NavigationService } from 'src/app/services/navigation.service';
import { PublicPagesService } from 'src/app/services/public-pages.service';
import { environment } from 'src/environments/environment';
import _moment from 'moment';
import { MAT_DATE_FORMATS } from '@angular/material/core';

const moment = _moment;
export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD-MM-YYYY',
  },
  display: {
    dateInput: 'DD MMM YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};
@Component({
  selector: 'app-enquiry-form',
  templateUrl: './enquiry-form.component.html',
  styleUrls: ['./enquiry-form.component.scss', '../../../../styles/common/date-picker-front.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [DatePipe,{ provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }]
})
export class EnquiryFormComponent implements OnInit {
  snlModalSettings = { showCloseIcon: true, backdropClick: false }
  enquiryForm: UntypedFormGroup;
  submitted = false;
  cargoesOptions: any = [];
  loadPortOptions: any[] = [];
  loadPortTerminalOptions: any[] = [];
  dischargePortOptions: any = [];
  dischargePortTerminalOptions: any = []
  todayDate = new Date();
  minDate = new Date();
  maxDate = new Date();
  cargoSelectModel: any;
  lPortSelectModel: number;
  dPortSelectModel: number;
  aFormGroup!: UntypedFormGroup;
  siteKey = environment.CAPTCHAKEY;
  enquiryData: any = [];
  cargoDataMini: any[];
  loadPortDataMini: any[];
  dischargePortDataMini: any;
  fileName: any;
  @ViewChild('uploadFile')
  myInputVariable: ElementRef;
  submittedss: boolean = false;
  fileExtenMessg: string;
  checkFileExt: RegExpExecArray | null;
  files: any;
  selectedCode: any = []
  createOther: boolean = false
  panelOpenState = true;
  collepsArr: number[];
  fileNameEnb: boolean = false
  Icons: string;
  countryCodeOptions: any = [];
  countryCodeMini: any = [];
  formIndex: number = 0;
  cargoLimitexcd: boolean;
  WarningError: boolean = true;
  cargoIndex: any;
  isLoading: boolean = false
  datePic: boolean = false;
  datePickerClass: boolean;
  Iswelcome: boolean = false
  refrenceNumber: any;
  closeDrOther: boolean = true;
  closeDrOtherPort: boolean = true;
  closeDrOtherDischarge: boolean = true;
  portTermEnb: boolean = false
  @ViewChild('captchaElem') captchaElem: ReCaptcha2Component;
  otherEnable: boolean = false;
  otherEnablePort: boolean = false;

  otherEnableDischarge: boolean;
  dischargeTermEnb: boolean = false
  CountryCodeError: boolean;
  cargoEnable: boolean = true;
  portEnable: boolean = true;
  dischargeEnable: boolean = true;
  first_name_val: any;
  blurCargoName: any;
  blurDischargeName: any
  blurPortName: any
  numLenght: any;
  counCode: any;
  OtherPortText: boolean = false
  OtherCargoText: boolean = false
  OtherDischargeText: boolean = false
  loadPortTerminalDataMini: any[];
  dischargePortTerminalDataMini: any;
  isSetPortTranshipment: boolean;
  tempLoadPortTerminalOptions: any[] = [];
  tempDischargePortTerminal: any[] = [];
  crgoInfoRemoveModel:boolean = false;
  constructor(public navigationService: NavigationService, private formBuilder: UntypedFormBuilder, public publicPagesService: PublicPagesService, public toastr: ToastrService) {
    this.navigationService.setShowNav(false);
    this.navigationService.setMobileNav(false);
    this.navigationService.setPageTitle('Stolt Tankers Fleet information');


  }

  ngOnInit(): void {
    this.formIndex = 0;
    this.collepsArr = [1]
    this.enquiryForm = this.formBuilder.group({
      recaptcha: ['', Validators.required],
      name: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(100)]],
      file: [''],
      organizationName: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(100)]],
      email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      phone: ['', [Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      countryCode: [null],
      cargoInfo: this.formBuilder.array([
        this.initSonderurlaub()
      ])
    });
    this.maxDate.setDate(this.todayDate.getDate() + 180);
    this.getEnquiryFormData()
    this.getCountryCode()

  }

  getCountryCode() {
    this.publicPagesService.getCountryCode().subscribe({next:res => {
      this.countryCodeOptions = res.data.map((it: any) => ({ 'szCountryName': '+' + it.szDialCode + ' ' + '(' + it.szCountryName + ')', 'szDialCode': it.szDialCode }));
      this.countryCodeMini = this.countryCodeOptions;
    }})
  }

  getEnquiryFormData() {
    this.publicPagesService.getEnquiryDropDownObj().subscribe({next:(res: any) => {
      if (res.data) {
        this.enquiryData = res.data;
        this.cargoesOptions = this.enquiryData.cargo_name
        this.loadPortOptions = this.enquiryData.load_port;
        this.dischargePortOptions = this.enquiryData.discharge_port;
        this.loadPortTerminalOptions = this.enquiryData.portTerminals;
        this.dischargePortTerminalOptions = this.enquiryData.portTerminals;

        this.loadPortTerminalDataMini = this.loadPortTerminalOptions;
        this.dischargePortTerminalDataMini = this.dischargePortTerminalOptions
        this.cargoDataMini = this.cargoesOptions;
        this.loadPortDataMini = this.loadPortOptions;
        this.dischargePortDataMini = this.dischargePortOptions
      }
    }, error:err => {
      this.cargoesOptions = []
      this.loadPortOptions = []
      this.dischargePortOptions = []
      this.navigationService.errorOccure(err);
    }})
  }

  get enquiryFormsArr() {
    return (this.enquiryForm.get('cargoInfo') as any)

  }

  getReferralsFormArr(index: any) {
    return (this.enquiryFormsArr.controls[index])
  }

  addCargoFields() {
    this.cargoesOptions = this.cargoDataMini;
    if (this.enquiryForm.controls['cargoInfo'].status == 'VALID') {
      if (this.enquiryFormsArr.controls.length < 3) {
        this.cargoLimitexcd = false;
        this.formIndex = this.enquiryFormsArr.controls.length;
        this.panelOpenState = false
        this.collepsArr = [this.enquiryFormsArr.controls.length]
        const control = (<UntypedFormArray>this.enquiryForm.controls['cargoInfo']);
        const sonderurlaub = this.formBuilder.group({
          vesselType: ['regularParcel'],
          cargoName: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(50)]],
          loadPort: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(50)]],
          loadPortTerminal: [null],
          dischargePort: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(50)]],
          dischargePortTerminal: [null],
          volume: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
          laycanDateFrom: ['', Validators.required],
          laycanDateTo: ['', Validators.required],
          requirements: ['', [Validators.minLength(3), Validators.maxLength(500)]],
          lastCargoType: ['no'],
          lastCargoRestrictions: [null,[Validators.minLength(3), Validators.maxLength(200)]],
          isLoadTranshiment:['no'],
          loadTransshipmentVesselName: [null,[Validators.minLength(3), Validators.maxLength(100)]],
          isDischargeTranshiment:['no'],
          dischargeTransshipmentVesselName: [null,[Validators.minLength(3), Validators.maxLength(100)]]
        });
        control.push(sonderurlaub);
      } else {
        this.cargoLimitexcd = true
      }
    } else {
      this.submitted = true;
    }

  }

  initSonderurlaub() {
    return this.formBuilder.group({
      vesselType: ['regularParcel'],
      cargoName: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(50)]],
      loadPort: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(50)]],
      loadPortTerminal: [null],
      dischargePort: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(50)]],
      dischargePortTerminal: [null],
      volume: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      laycanDateFrom: ['', Validators.required],
      laycanDateTo: ['', Validators.required],
      requirements: ['', [Validators.minLength(3), Validators.maxLength(500)]],
      lastCargoType: ['no'],
      lastCargoRestrictions: [null,[Validators.minLength(3), Validators.maxLength(200)]],
      isLoadTranshiment:['no'],
      loadTransshipmentVesselName: [null,[Validators.minLength(3), Validators.maxLength(100)]],
      isDischargeTranshiment:['no'],
      dischargeTransshipmentVesselName: [null,[Validators.minLength(3), Validators.maxLength(100)]]
    });
  }

  get f() {
    return this.enquiryForm.controls;
  }

  onClose(index: any) {
    const cargoInfoArray = this.enquiryForm.get('cargoInfo') as FormArray;
    const cargoInfoGroup = cargoInfoArray.at(index)! as FormGroup;
    
    const laycanDateFromControl = cargoInfoGroup.get('laycanDateFrom');
    const laycanDateToControl = cargoInfoGroup.get('laycanDateTo');
    
    if (laycanDateFromControl && laycanDateToControl) {
      const laycanDateFromValue = laycanDateFromControl.value;
      const laycanDateToValue = laycanDateToControl.value;
      
      let fromDate = this.convertToNextMidnight(new Date(laycanDateFromValue));
      let toDate = this.convertToNextMidnight(new Date(laycanDateToValue));
      // Update the form controls with the new date values
      laycanDateFromControl.setValue(fromDate);
      laycanDateToControl.setValue(toDate);
    }
  }
  


  convertToNextMidnight(dateStr: any): string {
    // Parse the date string to a Date object
    const originalDate = new Date(dateStr);

    // Add one day
    originalDate.setUTCDate(originalDate.getUTCDate() + 1);

    // Set time to midnight (00:00:00)
    originalDate.setUTCHours(0, 0, 0, 0);

    // Convert back to ISO 8601 string format
    return originalDate.toISOString();
  }

  submitHandler() {
    this.enquiryForm.markAllAsTouched();
    this.submitted = true;
    // stop here if form is invalid
    if (this.enquiryForm.invalid) {
      return;
    } else {
      if (this.enquiryForm.get('phone')?.value && !this.enquiryForm.get('countryCode')?.value) {
        this.CountryCodeError = true;
      } else {
        this.CountryCodeError = false
        let formData: any = new FormData();
        formData.append('name', this.enquiryForm.get('name')?.value.trim());
        formData.append('email', this.enquiryForm.get('email')?.value.trim());
        formData.append('organizationName', this.enquiryForm.get('organizationName')?.value.trim());
        formData.append('phone', this.enquiryForm.get('phone')?.value);
        formData.append('countryCode', this.enquiryForm.get('countryCode')?.value);
        formData.append('cargoInfo', JSON.stringify(this.enquiryForm.controls['cargoInfo'].value));
        if (this.enquiryForm.get('file')?.value) {
          formData.append('file', this.enquiryForm.get('file')?.value);
        }
        this.isLoading = true;
        this.publicPagesService.saveEnquiryForm(formData).subscribe({next:res => {
          if (res.status == 200) {
            this.navigationService.showSuccess("Enquiry form saved successfully", '');
            this.refrenceNumber = res.data
            this.isLoading = false;
            this.Iswelcome = true
          } else {
            this.isLoading = false;
            this.navigationService.errorOccure(res.message);
          }
          this.fileNameEnb = false;
          this.fileExtenMessg = '';
          this.myInputVariable.nativeElement.value = "";
        }, error:err => {
          this.isLoading = false;
          this.navigationService.errorOccure(err);
        }})

      }

    }
  }


  customSearch(type: any, event: any) {
    switch (type) {
      case "Cargo":
        let cargo = (event.target as HTMLInputElement).value.trim();
        this.refactorCargoSearch(cargo);
        break;
      case "LoadPort":
        let loadPort = (event.target as HTMLInputElement).value.trim();
        this.refactorLoadPortSearch(loadPort);
        break;
      case "DischargePort":
        let dischargePort = (event.target as HTMLInputElement).value.trim();
        this.refactorDischargePortSearch(dischargePort);
        break;
      case "countrycode":
        let countrycode = (event.target as HTMLInputElement).value.trim();
        this.refactorCountryCodeSearch(countrycode);
        break;
      case "loadPortTerminal":
        let loadPortTerminal = (event.target as HTMLInputElement).value.trim();
        this.refactorLoadPortTerminalSearch(loadPortTerminal);
        break;
      case "dischargePortTerminal":
        let dischargePortTerminal = (event.target as HTMLInputElement).value.trim();
        this.refactorDischargePortTerminalSearch(dischargePortTerminal);
        break;
      default:
        break;
    }
  }
  refactorCargoSearch(cargo:any){
    if (cargo.length > 2) {
      this.otherEnable = false;
      this.cargoesOptions = this.cargoDataMini.filter((it: any) => it.cargoName.toLowerCase().includes(cargo.toLowerCase()));
    } else {
      this.cargoesOptions = this.cargoDataMini;
      this.otherEnable = false;
    }
  }
  refactorLoadPortSearch(loadPort:any){
    if (loadPort.length > 2) {
      this.otherEnablePort = false
      this.loadPortOptions = this.loadPortOptions.filter((it: any) => it.loadPort.toLowerCase().includes(loadPort.toLowerCase()));
    } else {
      this.otherEnablePort = false
      this.loadPortOptions = this.loadPortDataMini;
    }
  }
  refactorDischargePortSearch(dischargePort:any){
    if (dischargePort.length > 2) {
      this.otherEnableDischarge = false;
      this.dischargePortOptions = this.dischargePortOptions.filter((it: any) => it.dischargePort.toLowerCase().includes(dischargePort.toLowerCase()));
    } else {
      this.otherEnableDischarge = false
      this.dischargePortOptions = this.dischargePortDataMini;
    }
  }
  refactorCountryCodeSearch(countrycode:any){
    if (countrycode.length > 1) {
      this.countryCodeOptions = this.countryCodeOptions.filter((it: any) => it.szCountryName.toLowerCase().includes(countrycode.toLowerCase()) || it.szDialCode.includes(countrycode.toLowerCase()));
    } else {
      this.countryCodeOptions = this.countryCodeMini;
    }
  }
  refactorLoadPortTerminalSearch(loadPortTerminal:any){
    if (loadPortTerminal.length > 0) {
      this.loadPortTerminalOptions = this.tempLoadPortTerminalOptions.filter((it: any) => it.terminal.toLowerCase().includes(loadPortTerminal.toLowerCase()));
    } else {
      this.loadPortTerminalOptions = this.tempLoadPortTerminalOptions;
    }
  }
  refactorDischargePortTerminalSearch(dischargePortTerminal:any){
    if (dischargePortTerminal.length > 0) {
      this.otherEnableDischarge = false;
      this.dischargePortTerminalOptions = this.tempDischargePortTerminal.filter((it: any) => it.terminal.toLowerCase().includes(dischargePortTerminal.toLowerCase()));
    } else {
      this.dischargePortTerminalOptions = this.tempDischargePortTerminal;
    }
  }
  onFileChange(event: any, type: any) {
    if (type == 'upload') {
      this.files = event.target.files;
      let filesData = this.files[0];
      this.fileName = filesData.name
    } else {
      this.files = event[0]
      this.fileName = this.files.name
    }
    let allowedExtensions = /(\.csv|\.xlsx|\.pdf|\.doc)$/i;
    this.checkFileExt = allowedExtensions.exec(this.fileName)
    const file = (type == 'upload') ? this.files[0] : this.files;
    if (!this.checkFileExt) {
      this.myInputVariable.nativeElement.value = "";
      this.fileExtenMessg = "Invalid file format. Only .xlsx, .csv, .pdf and .doc files are allowed.";
    } else if (Math.round(file.size / 1024 / 1024) > 25) {
      this.fileExtenMessg = "The file size is too large. Allowed maximum size is 25 MB.";
    }
    else {
      this.fileNameEnb = true
      this.Icons = 'trash-icon'
      this.fileExtenMessg = '';
      const files = (type == 'upload') ? this.files[0] : this.files;
      this.enquiryForm.patchValue({
        file: files,
      });
      this.enquiryForm.get('file')?.updateValueAndValidity();
    }

  }

  deleteReffrel(i: any) {
    if (i == "yes") {
      this.cargoLimitexcd = false;
      this.crgoInfoRemoveModel = false
      this.removebodyClass()
      this.enquiryFormsArr.removeAt(this.cargoIndex)
      this.formIndex = this.enquiryFormsArr.controls.length - 1
    } else {
      this.cargoIndex = i
      this.crgoInfoRemoveModel = true
    }
  }
  removebodyClass()
  {
    let ele:any = document.querySelector("body");
    if(ele && ele.style){
      ele.style.overflow = 'auto';
    }
  }
  removeFile() {
    this.fileNameEnb = false;
    this.myInputVariable.nativeElement.value = "";
    this.enquiryForm.get('file')?.reset();
  }

  isHover(a: any, type: any) {
    if (type == 'cargo') {
      if (a == 'Other') {
        this.closeDrOther = false;
        this.cargoEnable = true
      } else {
        this.closeDrOther = true;
      }
    } else if (type == 'port') {
      if (a == 'Other') {
        this.closeDrOtherPort = false;
      } else {
        this.closeDrOtherPort = true;
      }
    } else {
      if (a == 'Other') {
        this.closeDrOtherDischarge = false;
      } else {
        this.closeDrOtherDischarge = true;
      }
    }

  }


  isOtherCargoIndex: any[] = [];
  isOtherportIndex: any[] = [];
  isOtherDischargeIndex: any[] = [];
  onOtherChange(event: any, type: any, index?: any) {
    switch (type) {
      case 'cargo':
        this.refactorCargo(event, index)
        return;
      case 'loadport':
        this.refactorLoadport(event, index)
        return;
      case 'discharge':
        this.refactorDischarge(event, index)
        return;
      default:
        break;
    }
  }


  enquiryTerminal(event: any, type: any, index: any) {
    if (type == 'loadport' && event.loadPort != 'Other') {
      this.portTermEnb = true;
      this.loadPortTerminalOptions = this.loadPortTerminalDataMini.filter((it: any) => ((it.terminal && it.port) && (it.port == event.loadPort)))
      this.loadPortTerminalOptions.sort((a, b) => a.terminal.localeCompare(b.terminal));
      let newFirstElement:any = {port:event.loadPort,terminal:"To Be Nominated"}
      this.loadPortTerminalOptions = [newFirstElement].concat(this.loadPortTerminalOptions);

      this.tempLoadPortTerminalOptions = this.loadPortTerminalOptions;
    } else if (type == 'loadport' && event.loadPort == 'Other') {
      this.portTermEnb = false;
      this.enquiryFormsArr.controls[index].controls['loadPortTerminal'].reset()
    } else if (type == 'discharge' && event.dischargePort == 'Other') {
      this.dischargeTermEnb = false;
      this.enquiryFormsArr.controls[index].controls['dischargePortTerminal'].reset()
    } else {
      if (type == 'discharge' && event.dischargePort != 'Other') {
        this.dischargeTermEnb = true
        this.dischargePortTerminalOptions = this.dischargePortTerminalDataMini.filter((it: any) => ((it.terminal && it.port) && (it.port == event.dischargePort)))
        this.dischargePortTerminalOptions.sort((a: any, b: any) => a.terminal.localeCompare(b.terminal));
        let newFirstElement:any = {port:event.dischargePort,terminal:"To Be Nominated"}
        this.dischargePortTerminalOptions = [newFirstElement].concat(this.dischargePortTerminalOptions);
        this.tempDischargePortTerminal = this.dischargePortTerminalOptions;
      }
    }
  }


  refactorCargo(event: any, index: any) {
    this.OtherCargoText = this.cargoVal[index] ? true : false;
    if (event.cargoName == "Other") {
      this.isOtherCargoIndex.push(index);
      this.closeDrOther = false;
      this.cargoesOptions = [];
      this.otherEnable = true


      let scrolposi = window.scrollY - 1;
      window.scrollTo(0, scrolposi);

    } else {
      const indexss = this.isOtherCargoIndex.indexOf(index);
      if (indexss > -1) {
        this.isOtherCargoIndex.splice(indexss, 1);
      }
      this.blurCargoName = null
      this.otherEnable = event.isTrusted ? true : false;
      this.closeDrOther = false;
    }
  }

  refactorLoadport(event: any, index: any) {
    this.OtherPortText = this.loadPortVal[index] ? true : false;
    if (event.loadPort == "Other") {
      this.isOtherportIndex.push(index)
      this.closeDrOtherPort = false
      this.loadPortOptions = [];
      this.otherEnablePort = true;
      let scrolposi = window.scrollY - 1;
      window.scrollTo(0, scrolposi);

    } else {
      const indexport = this.isOtherportIndex.indexOf(index);
      if (indexport > -1) {
        this.isOtherportIndex.splice(indexport, 1);
      }
      this.blurPortName = null
      this.otherEnablePort = event.isTrusted ? true : false;
      this.closeDrOtherPort = false;
    }
  }

  refactorDischarge(event: any, index: any) {
    this.OtherDischargeText = this.discahrgeVal[index] ? true : false;
    if (event.dischargePort == "Other") {
      this.isOtherDischargeIndex.push(index)
      this.closeDrOtherDischarge = false
      this.dischargePortOptions = [];
      this.otherEnableDischarge = true

      let scrolposi = window.scrollY - 1;
      window.scrollTo(0, scrolposi);

    } else {
      const indexDischarge = this.isOtherDischargeIndex.indexOf(index);
      if (indexDischarge > -1) {
        this.isOtherDischargeIndex.splice(indexDischarge, 1);
      }
      this.blurDischargeName = null
      this.otherEnableDischarge = event.isTrusted ? true : false;
      this.closeDrOtherDischarge = false
    }
    this.blurCargoName = null
    this.otherEnable = event.isTrusted ? true : false;
    this.closeDrOther = false;
  }

  onKey(event: any, type: any) {
    if (type == 'cargo') {
      this.blurCargoName = event.target.value;
      this.cargoesOptions = [];
    } else if (type == 'loadport') {
      this.blurPortName = event.target.value;
      this.loadPortOptions = []
    } else {
      this.blurDischargeName = event.target.value;
      this.dischargePortOptions = []
    }
  }


  cargoTabSwitch(index: number) {
    this.formIndex = index;
    this.OtherCargoText = this.cargoVal[index] ? true : false;
    if (this.otherCargo[index]) {
      this.otherEnable = true;
      this.cargoesOptions = [];

    } else {
      this.cargoesOptions = [];
      this.otherEnable = false;
      this.cargoesOptions = this.cargoDataMini;
    }

    this.OtherPortText = this.loadPortVal[index] ? true : false;
    this.portTermEnb = (this.otherPort[index] === null) ? true : false
    if (this.otherPort[index]) {
      this.otherEnablePort = true;
      this.loadPortOptions = [];
    } else {
      this.otherEnablePort = false;
      this.loadPortOptions = this.loadPortDataMini;
    }


    this.OtherDischargeText = this.discahrgeVal[index] ? true : false;
    this.dischargeTermEnb = (this.otherDischarge[index] === null) ? true : false
    if (this.otherDischarge[index]) {
      this.otherEnableDischarge = true;
      this.dischargePortOptions = [];
    } else {
      this.otherEnableDischarge = false;
      this.dischargePortOptions = this.dischargePortDataMini;
    }
  }



  onOpen(index: any, type: any) {
    switch (type) {
      case 'cargo':
        if (this.otherCargo[index]) {
          this.blurCargoName = this.f.cargoInfo.value[index].cargoName
          this.cargoesOptions = this.f.cargoInfo.value[index].cargoName
        }
        return
      case 'port':
        if (this.otherPort[index]) {
          this.blurPortName = this.f.cargoInfo.value[index].loadPort
          this.loadPortOptions = this.f.cargoInfo.value[index].loadPort
        }
        return
      case 'discharge':
        if (this.otherDischarge[index]) {
          this.blurDischargeName = this.f.cargoInfo.value[index].dischargePort
          this.dischargePortOptions = this.f.cargoInfo.value[index].dischargePort
        }
        return
      default:
        break;
    }
  }


  otherCargo: any = [];
  otherPort: any = [];
  otherDischarge: any = [];
  cargoVal: any = [];
  loadPortVal: any = [];
  discahrgeVal: any = []
  closeEvent(type: any, index: any = null) {
    switch (type) {
      case 'cargo':
        this.refactorCargoClose(index)
        return
      case 'port':
        this.refactorPortClose(index)
        return
      case 'discharge':
        this.refactorDischargeClose(index)
        return
      default:
        break;
    }
  }

  refactorCargoClose(index: any) {
    this.cargoVal[index] = this.blurCargoName;
    this.OtherCargoText = this.cargoVal[index] ? true : false

    if (index != null && this.blurCargoName) {
      this.OtherCargoText = true;
      this.otherCargo[index] = this.blurCargoName;

    }

    if (this.f.cargoInfo.value[index].cargoName && !this.blurCargoName) {
      this.otherCargo[index] = null;
      this.OtherCargoText = false;
    }

    this.cargoEnable = false;
    this.enquiryForm.patchValue({
      cargoName: this.blurCargoName
    });

    setTimeout(() => {
      this.cargoEnable = true
      this.blurCargoName = null
      let scrolposi = window.scrollY - 1;
      window.scrollTo(0, scrolposi);
    }, 0);
  }

  refactorPortClose(index: any) {
    this.loadPortVal[index] = this.blurPortName;
    this.OtherPortText = this.loadPortVal[index] ? true : false;
    if (index != null && this.blurPortName) {
      this.OtherPortText = true
      this.otherPort[index] = this.blurPortName
    }
    if (this.f.cargoInfo.value[index].loadPort && !this.blurPortName) {
      this.otherPort[index] = null
      this.OtherPortText = false
    }
    this.portEnable = false;
    this.enquiryForm.patchValue({
      loadPort: this.blurPortName
    });
    setTimeout(() => {
      this.portEnable = true;
      this.blurPortName = null
      let scrolposi = window.scrollY - 1;
      window.scrollTo(0, scrolposi);
    }, 0);
  }

  refactorDischargeClose(index: any) {
    this.discahrgeVal[index] = this.blurDischargeName;
    this.OtherDischargeText = this.discahrgeVal[index] ? true : false;

    if (index != null && this.blurDischargeName) {
      this.OtherDischargeText = true
      this.otherDischarge[index] = this.blurDischargeName;
    }
    if (this.f.cargoInfo.value[index].dischargePort && !this.blurDischargeName) {
      this.otherDischarge[index] = null;
      this.OtherDischargeText = false
    }
    this.dischargeEnable = false;
    this.enquiryForm.patchValue({
      dischargePort: this.blurDischargeName
    });
    setTimeout(() => {
      this.dischargeEnable = true;
      this.blurDischargeName = null;
      let scrolposi = window.scrollY - 1;
      window.scrollTo(0, scrolposi);
    }, 0);
  }

  reSettall() {
    this.otherEnable = false
    this.otherEnablePort = false
    this.otherEnableDischarge = false
    this.cargoesOptions = this.cargoDataMini;
    this.loadPortOptions = this.loadPortDataMini;
    this.dischargePortOptions = this.dischargePortDataMini;
    this.loadPortTerminalOptions = this.loadPortTerminalDataMini;
    this.dischargePortTerminalOptions = this.dischargePortDataMini;
    this.portTermEnb = false;
    this.dischargeTermEnb = false
  }

  OnblurEvent(event: any) {
    this.numLenght = event.target.value
    if (this.numLenght && this.numLenght.length == 10 && !this.counCode) {
      this.CountryCodeError = true
    } else {
      this.CountryCodeError = false
    }
  }

  onBlurCode(event: any) {
    this.counCode = event.szCountryName
    if (event.szCountryName && this.numLenght.length == 10) {
      this.CountryCodeError = false
    }
  }

  backListing(index: any, type: any) {
    switch (type) {
      case 'cargo':
        this.cargoesOptions = this.cargoDataMini;
        this.otherEnable = false;
        this.otherCargo[index] = ''
        this.enquiryFormsArr.controls[index].controls['cargoName'].reset()
        return;
      case 'port':
        this.loadPortOptions = this.loadPortDataMini;
        this.otherEnablePort = false;
        this.otherPort[index] = ''
        this.enquiryFormsArr.controls[index].controls['loadPort'].reset()
        return;
      case 'discharge':
        this.dischargePortOptions = this.dischargePortDataMini;
        this.otherEnableDischarge = false;
        this.otherDischarge[index] = ''
        this.enquiryFormsArr.controls[index].controls['dischargePort'].reset()
        return;
      default:
        break;
    }
  }

  onChangeTranship(event:any,index:any)
  {
   
      if(this.f.cargoInfo.value[index].isDischargeTranshiment == 'yes'){
        this.enquiryFormsArr.controls[index].controls['dischargeTransshipmentVesselName'].reset()
      }
      if(this.f.cargoInfo.value[index].isLoadTranshiment == 'yes'){
        this.enquiryFormsArr.controls[index].controls['loadTransshipmentVesselName'].reset()
      }
  }
  closeModel(){
    this.removebodyClass()
    this.Iswelcome = false
  }
}
