import { ChangeDetectorRef, Component, ElementRef, Renderer2 } from '@angular/core';
import { NavigationService } from 'src/app/services/navigation.service';
import { BehaviorSubject } from 'rxjs';
import {
  CalendarOptions,
  EventApi,
} from '@fullcalendar/core';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import { InProgressFilter } from 'src/app/enums/inProgressFilter';

@Component({
  selector: 'app-etb-display',
  templateUrl: './etb-display.component.html',
  styleUrls: ['./etb-display.component.scss']
})
export class EtbDisplayComponent {
  ResetFildisable = true;
  resetAllCalled = false;
  isFilterSkeleton: boolean = true;
  pageNo = 1;

  vesselFilter: any[] = [
    {
        "_id": "SAJIS",
        "vslCode": "SAJIS",
        "vslName": "STOLT AJISAI"
    },
    {
        "_id": "SCOND",
        "vslCode": "SCOND",
        "vslName": "STOLT CONDOR"
    },
    {
        "_id": "SAGUI",
        "vslCode": "SAGUI",
        "vslName": "STOLT AGUILA"
    },
    {
        "_id": "GALTR",
        "vslCode": "GALTR",
        "vslName": "GOLDEN ALTAIR"
    },
    {
        "_id": "STUCA",
        "vslCode": "STUCA",
        "vslName": "STOLT TUCAN"
    },
    {
        "_id": "SSKUA",
        "vslCode": "SSKUA",
        "vslName": "STOLT SKUA"
    },
    {
        "_id": "SFLAM",
        "vslCode": "SFLAM",
        "vslName": "STOLT FLAMENCO"
    },
];
  portFilter: any[] = [
    {
        "_id": 8141,
        "portNumber": 8141,
        "portName": "DAESAN"
    },
    {
        "_id": 187,
        "portNumber": 187,
        "portName": "HOUSTON"
    },
    {
        "_id": 22329,
        "portNumber": 22329,
        "portName": "BONTANG"
    },
    {
        "_id": 8119,
        "portNumber": 8119,
        "portName": "POINT COMFORT"
    },
    {
        "_id": 830,
        "portNumber": 830,
        "portName": "TERNEUZEN"
    },
    {
        "_id": 5051,
        "portNumber": 5051,
        "portName": "DALIAN"
    },
];
  voyageFilter: any[] = [
    {
        "_id": 109,
        "voyageNumber": 109
    },
    {
        "_id": 21,
        "voyageNumber": 21
    },
    {
        "_id": 390,
        "voyageNumber": 390
    },
    {
        "_id": 13,
        "voyageNumber": 13
    },
    {
        "_id": 23,
        "voyageNumber": 23
    },
    {
        "_id": 306,
        "voyageNumber": 306
    },
    {
        "_id": 391,
        "voyageNumber": 391
    },
];

  savedVesselFilterData: any[] = [];
  savedLoadPortsFilterData: any[] = [];
  savedVoyageFilterData: any[] = [];

  vesselFilterValue: any = [];
  portFilterValue: any = [];
  voyageFilterValue: any = [];

  customEvent: any = [
    {
      id: '1',
      resourceId: 'a',
      title: 'TC 67',
      start: '2024-08-14T12:00:00',
      end: '2024-08-14T22:00:00',
      backgroundColor: '#b0eff5', 
    },
    {
      id: '2',
      resourceId: 'b',
      title: 'Vopak A22 Fpt',
      start: '2024-08-14T08:30:00',
      end: '2024-08-14T24:00:00',
      backgroundColor: '#c7f5e3',
    },
    {
      id: '3',
      resourceId: 'a',
      title: '',
      start: '2024-08-14T02:00:00',
      end: '2024-08-14T02:00:00',
      backgroundColor: '#da2012',
      height: '66px', // Custom height
      width: '3px', // Custom width
      className: 'custom-red',
    },
    {
      id: '4',
      resourceId: 'b',
      title: '',
      start: '2024-08-14T08:00:00',
      end: '2024-08-14T08:00:00',
      backgroundColor: '#da2012',
      height: '66px', // Custom height
      width: '3px', // Custom width,
      className: 'custom-red',
    },
    {
      id: '5',
      resourceId: 'b',
      title: '',
      start: '2024-08-16T20:00:00',
      end: '2024-08-16T20:00:00',
      backgroundColor: '#da2012',
      height: '66px', // Custom height
      width: '3px', // Custom width,
      className: 'custom-red',
    },
    {
      id: '6',
      resourceId: 'b',
      title: 'Vopak B23 Azz',
      start: '2024-08-15T19:00:00',
      end: '2024-08-16T17:00:00',
      backgroundColor: '#ffadad',
    },
    {
      id: '7',
      resourceId: 'c',
      title: 'BerthName_122',
      start: '2024-08-13T15:00:00',
      end: '2024-08-14T07:00:00',
      backgroundColor: '#e5dbd4',
    },
  ];
  customeResouces:any = [
    { id: 'a', fname: 'Stolt Quetzal / 20', lname: 'Houston', port: 'Port stay: 09 Jul - 19 Jul' },
    { id: 'b', fname: 'Stolt Integrity', lname: 'Houston', port: 'Port stay: 09 Jul - 19 Jul' },
    { id: 'c', fname: 'Stolt Acer / 78', lname: 'Houston', port: 'Port stay: 09 Jul - 19 Jul' },
  ]

  private calendarVisibleSubject = new BehaviorSubject(true);
  private calendarOptionsSubject = new BehaviorSubject<CalendarOptions>({
    schedulerLicenseKey: '0443312849-fcs-1707325548',
    plugins: [
      resourceTimelinePlugin,
    ],
    initialView: 'resourceTimeline',
    visibleRange: this.getVisibleRange(),
    slotMinWidth: 15,
    resourceAreaWidth: 200,
    height: 'auto',
    slotLabelFormat: [
      { month: 'short', weekday: 'short', day: 'numeric' },
      { hour: 'numeric', hour12: false },
    ],
    slotDuration: '02:00',
    nowIndicator: true,
    editable: false,
    selectable: true,
    selectMirror: true,
    dayMaxEvents: true,
    weekends: true,
    scrollTime: '24:00:00',
    resourceAreaColumns: [
      { field: 'fname', headerContent: 'Vessel / Voyage' },
    ],
    resources: [],
    resourceLabelContent: (arg) => ({
      html: `<div class='title'>${arg.resource.extendedProps['fname']}</div><div class='subtitle'>${arg.resource.extendedProps['lname']}</div><div class='port'>${arg.resource.extendedProps['port']}</div>`,
    }),
    events: [],
    eventClassNames: this.getEventClassNames.bind(this), // Apply custom classes
    eventDidMount: this.applyEventStyles.bind(this), // Apply dynamic styles
  });

  currentEvents = new BehaviorSubject<EventApi[]>([]);
  constructor(private navigationService: NavigationService, private changeDetector: ChangeDetectorRef, private el: ElementRef, private renderer: Renderer2) {
    this.navigationService.setShowNav(true);
    this.navigationService.setMobileNav(false);
    this.navigationService.setPageTitle('ETB Display');
  }
  private scrollableElement!: any;
  isShow: boolean = false;
  ngOnInit() {
      this.updateResources(this.customeResouces);
      this.updateEvents(this.customEvent);
      this.scrollOnCurrentDate();
  }

  scrollOnCurrentDate(){
    setTimeout(()=>{
      this.scrollableElement = this.el.nativeElement.querySelectorAll('.fc-scroller');
      if(this.scrollableElement.length){
        this.scrollableElement[5].scrollBy({
          left: 360,
        });
        this.isShow = true;
        this.isFilterSkeleton = false;
      }else{
        this.isShow = true;
        this.isFilterSkeleton = false;
      }
     },600)
  }

  updateResources(resources:any) {
    const newOptions = {
      ...this.calendarOptionsSubject.getValue(),
      resources: resources,
    };
    this.calendarOptionsSubject.next(newOptions);
  }

  updateEvents(events:any) {
    const newOptions = {
      ...this.calendarOptionsSubject.getValue(),
      events: events,
    };
    this.calendarOptionsSubject.next(newOptions);
  }

  get calendarVisible() {
    return this.calendarVisibleSubject.value;
  }

  get calendarOptions() {
    return this.calendarOptionsSubject.value;
  }

  getVisibleRange() {
    const today = new Date();
    const start = new Date(today);
    start.setDate(today.getDate() - 3);

    const end = new Date(start);
    end.setDate(start.getDate() + 15);

    return {
      start: start.toISOString().split('T')[0],
      end: end.toISOString().split('T')[0]
    };
  }

  getEventClassNames(eventContent: any) {
    return [eventContent.event.extendedProps.className];
  }

  eventData:any;
  applyEventStyles(eventContent: any) {
    const el = eventContent.el;
    el.style.backgroundColor = eventContent.event.extendedProps.backgroundColor;
    el.style.height = eventContent.event.extendedProps.height;
    el.style.width = eventContent.event.extendedProps.width;
    // Handle mouseover event
  el.addEventListener('mouseover', () => {
    this.eventData = eventContent.event.title;
  });

  // Handle mouseleave event
  el.addEventListener('mouseleave', () => {
    this.eventData = null;
  });
  }

  submitSelectFilter(eventFilter: any) {
    this.pageNo = 1;
    switch (eventFilter.type) {
        case InProgressFilter.vessel:
            this.vesselFilterValue = eventFilter.data
            this.ResetFildisable = this.vesselFilterValue.length > 0 ? false : true;
            // call api
            return;

        case InProgressFilter.port:
            this.portFilterValue = eventFilter.data
            this.ResetFildisable = this.portFilterValue.length > 0 ? false : true;
           // call api
            return;
        case 'voyage':
            this.voyageFilterValue = eventFilter.data
            this.ResetFildisable = this.voyageFilterValue.length > 0 ? false : true;
            // call api
            return;
        default:
            return;
    }

}
ResetFilter() {
 // this.isFilterSkeleton = true
  this.resetAllCalled = true
  this.pageNo = 1;
  this.vesselFilterValue = [];
  this.portFilterValue = [];
  this.voyageFilterValue = [];
  this.ResetFildisable = true;
  // api call
}
}
